<template>
  <div>
    <b-container fluid>
      <h3>
        Users
        <span v-if="selected_group !== null">in <strong>{{ selected_group.name }}</strong></span>
      </h3>
      <b-button-toolbar v-if="selected_group !== null">
        <b-button-group class="mx-1">
          <b-pagination v-model="current_page" :total-rows="infos.length" :per-page="per_page"
            :disabled="fetching"></b-pagination>
        </b-button-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-input type="text" placeholder="Search by email..." autocomplete="off" v-model="search"
            @input="filterInfos"></b-input>
        </b-input-group>
        <b-button-group class="mx-1">
          <b-button @click="fetchInfos()" class="border"><b-icon icon="arrow-clockwise"
              class="mr-1"></b-icon>Reload</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="success" @click="showEdit(true)" class="border"><b-icon icon="plus"
              class="mr-1"></b-icon>Add</b-button>
          <b-button variant="success" class="border" @click="showEdit(true, true)" v-if="selected != null"><b-icon
              icon="files" class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-button-group class="mx-1" v-if="selected != null">
          <b-button variant="primary" @click="showEdit(false)" class="border"><b-icon icon="pen"
              class="mr-1"></b-icon>Edit</b-button>
          <b-button variant="danger" @click="show_delete = true" class="border"><b-icon icon="trash"
              class="mr-1"></b-icon>Delete</b-button>
          <b-button @click="getUserToken()" title="Get the authentication token" class="border"><b-icon icon="clipboard"
              class="mr-1"></b-icon>Token</b-button>
          <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success"
            dismissible @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Token copied to
            clipboard -
            <strong>{{ user_token }}</strong>
          </b-alert>
        </b-button-group>
      </b-button-toolbar>
      <p class="text-secondary" v-if="token !== undefined && token !== null">
        <b-icon icon="info-circle" class="mr-1"></b-icon>If the email is not
        received, you can give this
        <a :href="'/user/password?token=' + token" target="_blank">link</a> to
        change the password.
      </p>
      <b-table :items="infos" striped hover bordered :busy="fetching" :fields="fields.filter((ele) => !ele.notdisplay)"
        class="text-nowrap" show-empty sort-by="email" sticky-header="500px" :per-page="per_page"
        :current-page="current_page" selectable select-mode="single" @row-selected="onRowSelected">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell()="data">
          <span v-if="data.value == '*'"><i>Everything</i></span>
          <span v-else-if="data.field.bool">{{
          data.value ? "Yes" : "No"
        }}</span>
          <span v-else-if="data.field.key == 'profile_role'">{{
          roles.find((ele) => ele.value == data.value).text
        }}</span>
          <span v-else>{{ data.value }}</span>
        </template>
      </b-table>
    </b-container>
    <b-modal v-model="show_edit" title="User editor" hide-footer size="lg">
      <b-overlay :show="saving">
        <div v-if="edit !== null">
          <b-form-group v-for="field in fields.filter((ele) => !ele.notedit)" :key="field.key" :label="field.label"
            :label-for="field.key" label-cols-xl="3">
            <span v-if="field.key == 'group'">
              <b-form-select v-model="edit[field.key]" :id="field.key" :options="group_names"></b-form-select>
            </span>
            <span v-else-if="field.key == 'profile_role'">
              <b-form-select v-model="edit[field.key]" :id="field.key" :options="roles"></b-form-select>
              <p class="text-secondary">
                {{
          roles.find((ele) => ele.value == edit[field.key]).description
        }}
              </p>
            </span>
            <span v-else-if="field.key == 'new_password'">
              <b-form-input :id="field.key" type="password" v-model="edit[field.key]" v-show="!edit.random_password"
                placeholder="Let it empty to set no password" :state="notEmpty(field)"></b-form-input>
              <div class="text-secondary" v-show="!edit.random_password">
                A password must respect these rules:
                <ul>
                  <li v-for="rule of password_compliant" :key="rule">
                    {{ rule }}
                  </li>
                </ul>
              </div>
              <b-form-checkbox v-model="edit.random_password" id="random_password" switch
                @input="edit['new_password'] = ''">Generate a strong random password</b-form-checkbox>
            </span>
            <span v-else>
              <b-form-checkbox v-model="edit[field.key]" v-if="field.bool" :id="field.key" switch
                class="mt-2"></b-form-checkbox>
              <b-form-input :id="field.key" v-else :type="field.key == 'email' ? 'email' : 'text'"
                :disabled="field.key == 'email' && edit_form" :placeholder="field.desc" v-model="edit[field.key]"
                :state="notEmpty(field)"></b-form-input>
              <p v-if="field.key == 'email' && dup_email" class="text-danger mb-0">
                This user already exists in this group.
              </p>
            </span>
          </b-form-group>
          <hr />
          <b-row class="text-center text-danger" v-if="error != null"><b-col cols="12"><strong>{{ error
                }}</strong></b-col></b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <b-button @click="show_edit = false" variant="danger" class="mx-1"><b-icon icon="x"
                  class="mr-1"></b-icon>Cancel</b-button>
              <b-button variant="primary" class="mx-1" :disabled="checkState()" @click="saveItem"><b-icon icon="check"
                  class="mr-1"></b-icon>Save</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal v-model="show_delete" title="Delete user" hide-footer>
      <b-overlay :show="saving">
        <p class="text-center">
          Are you sure to delete this user? This action is irreversible.
        </p>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="show_delete = false" variant="danger" class="mx-1"><b-icon icon="x"
                class="mr-1"></b-icon>Cancel</b-button>
            <b-button variant="primary" class="mx-1" @click="deleteItem"><b-icon icon="check"
                class="mr-1"></b-icon>OK</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { runAllInBatches } from "../../utils/async";
const CancelToken = axios.CancelToken;

export default {
  name: "UsersTable",
  props: [
    "selected_group",
    "group_names",
    "password_compliant",
    "password_compliant_regex",
  ],
  data: function () {
    return {
      infos_base: [],
      infos: [],
      fetching: false,
      selected: null,
      fields: [
        {
          key: "group",
          label: "Group name",
          notnull: true,
          notdisplay: true,
        },
        {
          key: "email",
          label: "Email",
          notnull: true,
          desc: "Enter the email address",
        },
        {
          key: "connect_date",
          label: "Connected since",
          notedit: true,
        },
        {
          key: "last_connection",
          label: "Last connection",
          notedit: true,
        },
        {
          key: "has_password",
          label: "Has a password",
          notedit: true,
          bool: true,
        },
        {
          key: "new_password",
          label: "Password",
          notdisplay: true,
        },
        {
          key: "profile_locked",
          label: "Locked",
          bool: true,
        },
        {
          key: "profile_role",
          label: "Role",
          notnull: true,
        },
      ],
      source: CancelToken.source(),
      per_page: 50,
      current_page: 1,
      show_edit: false,
      edit_form: false,
      edit: null,
      error: null,
      saving: false,
      show_delete: false,
      roles: null,
      search: "",
      valid_email:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      dup_email: false,
      token: undefined,
      user_token: undefined,
      dismiss_countdown: 0,
      total_secs: 5
    };
  },
  watch: {
    selected_group: function () {
      this.search = "";
      if (this.selected_group !== null) {
        this.fetchInfos();
      } else {
        this.infos = [];
      }
    },
    edit: {
      deep: true,
      handler: function () {
        this.dup_email = this.duplicateEmail(
          this.fields.find((ele) => ele.key == "email")
        );
      },
    },
  },
  created: async function () {
    await this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      try {
        this.roles = (await axios
          .get("/api/v1/user/roles")).data.map((ele) => {
            return {
              value: ele.key,
              text: ele.label,
              description: ele.description,
            };
          })
      }
      catch (error) {
        console.error("Could not get roles")
      }
    },
    async fetchInfo(id) {
      try {
        return (await axios.get("/api/v1/user/info", {
          params: {
            email: id
          }
        })).data
      }
      catch (error) {
        console.error("Could not get item info")
      }
    },
    async fetchInfos() {
      this.fetching = true;
      this.infos_base = this.infos = []
      const item_ids = (await axios
        .get("/api/v1/group/users", {
          params: { group_name: this.selected_group.name },
        })).data
      var fetches = []
      for (let id of item_ids) {
        fetches.push(() => this.fetchInfo(id))
      }
      this.infos_base = this.infos = (await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })).map((ele) => {
        var new_item = {};
        for (let key in ele) {
          if (typeof ele[key] !== "object") {
            new_item[key] = ele[key];
          } else {
            for (let key2 in ele[key]) {
              let new_key = key + "_" + key2;
              new_item[new_key] = ele[key][key2];
            }
          }
        }
        return new_item;
      });
      this.fetching = false
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.selected = items[0];
      } else {
        this.selected = null;
      }
    },
    showEdit(newItem, copy = false) {
      this.token = undefined;
      this.edit_form = false;
      var edit = {};
      var fields = this.fields.filter(
        (ele) =>
          !ele.notedit && ele.key !== "new_password" && ele.key !== "group"
      );
      edit.group = this.selected_group.name;
      edit.new_password = "";
      edit.random_password = true;
      if (newItem) {
        for (let field of fields) {
          if (copy) {
            edit[field.key] =
              field.key == "email" ? "" : this.selected[field.key];
            edit.random_password = this.selected.has_password;
          } else {
            edit[field.key] = field.key == "profile_role" ? "client" : "";
          }
        }
      } else {
        for (let field of fields) {
          edit[field.key] = this.selected[field.key];
        }
        edit.random_password = this.selected.has_password;
        this.edit_form = true;
      }
      this.edit = edit;
      this.error = null;
      this.show_edit = true;
    },
    duplicateEmail(field) {
      return (
        !this.edit_form &&
        this.infos_base.map((ele) => ele.email).includes(this.edit[field.key])
      );
    },
    notEmpty(field) {
      var valid = true;
      if (field.notnull) {
        valid = this.edit[field.key] != null;
        if (this.edit[field.key] != null) {
          valid = this.edit[field.key].length > 0;
        }
        if (field.key == "email" && valid) {
          valid = this.valid_email.test(this.edit[field.key]);
          if (valid) {
            valid = !this.duplicateEmail(field);
          }
        }
      }
      if (field.key == "new_password" && this.edit[field.key].length > 0) {
        valid = this.password_compliant_regex.test(this.edit[field.key]);
      }
      return valid;
    },
    checkState() {
      var valid = true;
      for (let field of this.fields) {
        if (field.notnull) {
          if (this.edit[field.key] == null) {
            valid = false;
          }
          if (this.edit[field.key] != null) {
            if (this.edit[field.key].length == 0) {
              valid = false;
            }
            if (field.key == "email" && valid) {
              valid = this.valid_email.test(this.edit[field.key]);
              if (valid) {
                valid = !this.duplicateEmail(field);
              }
            }
          }
        }
        if (field.key == "new_password" && this.edit[field.key].length > 0) {
          valid = this.password_compliant_regex.test(this.edit[field.key]);
        }
      }
      return !valid;
    },
    async saveItem() {
      this.saving = true;
      this.error = null;
      var item_edit = {
        group: this.edit.group,
        email: this.edit.email,
        password: null,
        profile: {
          role: this.edit.profile_role,
          locked: this.edit.profile_locked,
        },
      };
      if (!this.edit.random_password) {
        item_edit.password = this.edit.new_password;
      }
      try {
        this.token = (await axios
          .post("/api/v1/user/add", item_edit)).data.token
        this.show_edit = false;
        this.fetchInfos();
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail.replace(/:.+/, "");
        } else {
          this.error = "Internal server error.";
        }
      }
      this.saving = false;
    },
    async deleteItem() {
      this.saving = true;
      await axios
        .delete("/api/v1/user/delete", {
          params: { email: this.selected.email },
        })
      this.saving = false;
      this.show_delete = false;
      this.fetchInfos();
    },
    filterInfos() {
      if (this.search.length > 0) {
        this.infos = this.infos_base.filter((ele) =>
          ele.email.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.infos = this.infos_base;
      }
    },
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
    async getUserToken() {
      try {
        this.user_token = (await axios
          .get("/api/v1/user/token", {
            params: { email: this.selected.email }
          })).data
        navigator.clipboard.writeText(this.user_token).then(
          () => (this.dismiss_countdown = this.total_secs),
          () => (this.dismiss_countdown = this.total_secs)
        );
      }
      catch (error) {
        console.error("Could not get user token")
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>