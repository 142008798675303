import { mean, median, standardDeviation, linearRegression } from "simple-statistics";
import { last, push } from "./array";
import { formatTimestamp, formatTimeDiff, isDateString } from "./date";

export function computeStatsPlotData(data) {
  const { xValues, yValues } = sortCoordinates(
    data.flatMap((datum) => datum.x),
    data.flatMap((datum) => datum.y)
  );

  const areYValuesDates = yValues.every(y => isDateString(y))

  const numericYValues = yValues
    .map(y => areYValuesDates ? (new Date(y)).getTime() : y)
    .filter((y) => typeof y === "number");

  if (numericYValues.length > 0) {
    const minX = xValues[0];
    const maxX = last(xValues);

    const coordinatesPairs = xValues.reduce((pairs, xValue, index) => {
      const x = isDateString(xValue) ? (new Date(xValue)).getTime() : xValue
      const y = isDateString(yValues[index]) ? (new Date(yValues[index])).getTime() : yValues[index]

      if (typeof y === "number" && typeof x === "number") {
        pairs.push([x, y]);
      }

      return pairs;
    }, []);

    const DECIMALS = 2;
    const linRegXValues = []
    const linRegYValues = []

    if (coordinatesPairs.length > 1) {
      const { m: slope, b: yIntercept } = linearRegression(coordinatesPairs);
      const linRegMinX = coordinatesPairs[0][0]
      const linRegMaxX = last(coordinatesPairs)[0]

      linRegXValues.push(minX, maxX)

      linRegYValues.push(
        slope * linRegMinX + yIntercept,
        slope * linRegMaxX + yIntercept
      )
    }

    const [meanValue, medianValue] = [mean, median].map(
      areYValuesDates
        ? statFn => formatTimestamp(statFn(numericYValues))
        : statFn => statFn(numericYValues).toFixed(DECIMALS)
    )

    const stdValue = areYValuesDates
      ? formatTimeDiff(standardDeviation(numericYValues))
      : standardDeviation(numericYValues).toFixed(DECIMALS)

    return {
      name: "Lin. Reg.",
      type: "scatter",
      mode: "lines",
      x: linRegXValues,
      y: linRegYValues,
      line: { width: 2 },

      meta: {
        type: 'stat',

        extraStats: `Mean: ${meanValue}
 |  Median: ${medianValue}
 |  Std.: ${stdValue}`
      }
    }
  }
}

export function removeSelection(plotlyData) {
  let filteredOutDots

  const filteredData = plotlyData.map((datum) => {
    if (datum.selectedpoints) {
      filteredOutDots ??= [];
      filteredOutDots.push(...datum.selectedpoints);
    }

    return {
      ...datum,
      selectedpoints: undefined,

      x: datum.x.filter(
        (coord, index) => !datum.selectedpoints?.includes(index)
      ),

      y: datum.y.filter(
        (coord, index) => !datum.selectedpoints?.includes(index)
      ),
    };
  })

  return { filteredOutDots, filteredData };
}

function sortCoordinates(xValues, yValues) {
  const indexXPairs = Object.entries(xValues)
    .map(([index, value]) => [parseInt(index, 10), value]);

  indexXPairs.sort(([, xA], [, xB]) => xA > xB ? 1 : xA < xB ? -1 : 0);

  return indexXPairs.reduce((sortedCoordinates, [index, x]) => ({
    xValues: push(sortedCoordinates.xValues, x),
    yValues: push(sortedCoordinates.yValues, yValues[index]),
  }), { xValues: [], yValues: [] });
}
