<template>
  <div>
    <h2>
      Your profile<small class="p-1">
        <help-view>This page allows to see your profile and accesses, and to change your
          password.</help-view>
      </small>
    </h2>
    <div v-if="user_info !== null">
      <hr />
      <b-container fluid>
        <h3>Global information</h3>
        <ul>
          <li><strong>Email:</strong> {{ user_info.email }}</li>
          <li><strong>Group:</strong> {{ user_info.group }}</li>
          <li>
            <strong>Connected since:</strong> {{ user_info.connect_date }}
          </li>
          <li>
            <strong>Last connection:</strong> {{ user_info.last_connection }}
          </li>
          <li>
            <strong>You have a password:</strong>&nbsp;
            <span v-if="user_info.has_password">Yes</span><span v-else>No</span>
          </li>
        </ul>
        <b-button @click="getUserToken()" title="Get your authentification token" variant="secondary"><b-icon
            icon="clipboard"></b-icon> Get your authentication token</b-button>
        <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success"
          dismissible @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Token copied to
          clipboard -
          <strong>{{ user_token }}</strong>
        </b-alert>
      </b-container>
      <hr />
      <b-container fluid>
        <h3>Password change</h3>
        <div v-if="user_info.has_password">
          <b-alert :show="changing_error !== undefined" class="mb-2" variant="danger" dismissible>{{ changing_error
            }}</b-alert>
          <b-alert :show="password_changed" class="mb-2" variant="success">Your password has been changed. You are being
            redirected to the
            home page.
          </b-alert>
          <b-form-group label="Current password" label-for="current_password" label-cols-xl="3">
            <b-form-input id="current_password" type="password" v-model="current_password"
              placeholder="Type your current password" :state="current_password.length > 0">
            </b-form-input>
          </b-form-group>
          <b-form-group label="New password" label-for="new_password" label-cols-xl="3">
            <b-form-input id="new_password" type="password" v-model="new_password" placeholder="Type your new password"
              :state="new_password.length > 0 &&
      password_compliant_regex.test(new_password) &&
      new_password !== current_password
      ">
            </b-form-input>
            <p v-if="new_password.length > 0 && new_password === current_password
      " class="text-danger mb-0">
              Your new password cannot be the same as your current one.
            </p>
          </b-form-group>
          <b-form-group label="Confirm new password" label-for="new_password2" label-cols-xl="3">
            <b-form-input id="new_password2" type="password" v-model="new_password2"
              placeholder="Confirm your new password"
              :state="new_password2.length > 0 && new_password == new_password2"></b-form-input>
          </b-form-group>
          <div class="text-secondary">
            Your new password must respect these rules:
            <ul>
              <li v-for="rule of password_compliant" :key="rule">{{ rule }}</li>
            </ul>
          </div>
          <b-button block variant="primary" :disabled="checkPasswordState() || changing_password"
            @click="changePassword">Change password</b-button>
          <p class="text-secondary text-center">
            <b-icon icon="info-circle" class="mr-1"></b-icon>Once you change
            your password, you will need to login again.
          </p>
        </div>
        <div v-else>
          <b-alert :show="email_error" class="mb-2" variant="danger" dismissible>There was an error when sending an
            email.
            Please retry.</b-alert>
          <b-alert :show="email_sent" class="mb-2" variant="success">An email has been sent to your address with a link
            to
            create a new
            password.</b-alert>
          <b-button variant="primary" @click="askPassword" :disabled="sending_email">Ask for a password </b-button><span
            class="text-secondary ml-1">
            <b-icon icon="info-circle" class="mr-1"></b-icon>If you click on
            this button, all your sessions will expire.
          </span>
        </div>
      </b-container>
      <hr />
      <b-container fluid>
        <h3>Machines accesses</h3>
        <div v-if="locked_machines.length > 0">
          <hr />
          <b-alert :show="locked_machines.length > 0" variant="danger">
            <span v-if="locked_machines.length > 1">Accesses for {{ locked_machines.length }} machines are still
              locked.</span>
            <span v-else>Access for 1 machine is still locked.</span>
          </b-alert>
          <h4>
            Unlock access<span v-if="locked_machines.length > 1">es</span>:
          </h4>
          <b-alert :show="error_unlock !== undefined" class="mb-2" variant="danger" dismissible>{{ error_unlock
            }}</b-alert>
          <b-row v-for="locked_machine of locked_machines" :key="locked_machine.name" class="m-2 border align-middle">
            <b-col xl="10">
              <b-form-group :label="locked_machine.name" :label-for="locked_machine.name" label-cols-xl="2"
                class="mt-2 mb-2">
                <b-form-input :id="locked_machine.name" type="text" v-model="locked_machine.token"
                  placeholder="Enter the machine token" :state="locked_machine.token.length > 0"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col xl="2"><b-button variant="primary" class="mt-2 mb-2" :disabled="locked_machine.token.length == 0"
                @click="unlockMachine(locked_machine)"><b-icon icon="unlock"
                  class="mr-1"></b-icon>Unlock</b-button></b-col>
          </b-row>
          <hr />
        </div>
        <b-table :items="user_info.accesses" striped bordered :fields="access_fields" class="text-nowrap" show-empty
          sort-by="machine_name" sticky-header="500px">
          <template #cell()="data">
            <span v-if="data.value.length == 0"><i>Everything</i></span>
            <span v-else-if="data.field.bool">{{
      data.value ? "Yes" : "No"
    }}</span>
            <span v-else>{{ data.value }}</span>
          </template>
        </b-table>
      </b-container>
      <hr />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./components/HelpView.vue";

export default {
  name: "UserProfile",
  props: ["password_compliant", "password_compliant_regex"],
  data: function () {
    return {
      user_info: null,
      access_fields: [
        {
          key: "machine_name",
          label: "Machine name",
        },
        {
          key: "from",
          label: "From",
        },
        {
          key: "to",
          label: "To",
        },
        {
          key: "locked",
          label: "Locked",
          bool: true,
        },
      ],
      sending_email: false,
      email_sent: false,
      email_error: false,
      current_password: "",
      new_password: "",
      new_password2: "",
      changing_password: false,
      password_changed: false,
      changing_error: undefined,
      locked_machines: [],
      error_unlock: undefined,
      user_token: undefined,
      dismiss_countdown: 0,
      total_secs: 5
    };
  },
  methods: {
    async getUserInfo() {
      try {
        this.user_info = (await axios
          .get("/api/v1/user/info")).data
        var locked_machines = [
          ...new Set(
            this.user_info.accesses
              .filter((access) => access.locked)
              .map((access) => access.machine_name)
          ),
        ];
        locked_machines.sort();
        this.locked_machines = [];
        locked_machines.forEach((locked_machine) => {
          this.locked_machines.push({
            name: locked_machine,
            token: "",
          });
        });
      } catch (error) {
        console.warn("The user is not authenticated.")
      }
    },
    async askPassword() {
      this.sending_email = true;
      this.email_sent = false;
      this.email_error = false;
      try {
        await axios
          .post("/api/v1/user/reset_password", null, {
            params: { email: this.user_info.email }
          })
        this.email_sent = true
      }
      catch (error) {
        this.email_error = true
      }
      this.sending_email = false
    },
    checkPasswordState() {
      return !(
        this.current_password.length > 0 &&
        this.new_password.length > 0 &&
        this.password_compliant_regex.test(this.new_password) &&
        this.new_password2.length > 0 &&
        this.new_password == this.new_password2
      );
    },
    async changePassword() {
      this.changing_password = true;
      this.password_changed = false;
      this.changing_error = undefined;
      try {
        await axios
          .post("/api/v1/user/change_password", {
            email: this.user_info.email,
            token: null,
            password: this.current_password,
            new_password: this.new_password,
            new_password2: this.new_password2,
          })
        this.password_changed = true;
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.changing_error = error.response.data.detail;
        } else {
          this.changing_error = "Internal server error";
        }
      }
      this.changing_password = false
    },
    async unlockMachine(locked_machine) {
      this.error_unlock = undefined;
      try {
        const response = await axios
          .post("/api/v1/access/unlock", {
            group_name: this.user_info.group,
            token: locked_machine["token"],
          })
        if (response.data.length == 0) {
          this.error_unlock =
            "Error for machine " + locked_machine["name"] + ": wrong token";
        } else {
          this.getUserInfo();
        }
      }
      catch (error) {
        var error_unlock =
          "Error for machine " + locked_machine["name"] + ": ";
        if (error.response.data.detail != undefined) {
          error_unlock += error.response.data.detail;
        } else {
          error_unlock += "Internal server error";
        }
        this.error_unlock = error_unlock
      }
    },
    async getUserToken() {
      try {
        this.user_token = (await axios
          .get("/api/v1/user/token")).data
        navigator.clipboard.writeText(this.user_token).then(
          () => (this.dismiss_countdown = this.total_secs),
          () => (this.dismiss_countdown = this.total_secs)
        );
      }
      catch (error) {
        console.error("Could not get user token")
      }
    },
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
  },
  created: function () {
    this.getUserInfo();
  },
  components: {
    HelpView,
  },
};
</script>