export function last(array) {
  return array?.length > 0 ? array[array.length - 1] : undefined
}

export function lastIndex(array) {
  return array?.length - 1;
}

export function repeat(length, value) {
  return new Array(length).fill(value)
}

export function compact(array) {
  return array?.filter(item => ![null, undefined].includes(item))
}

export function push(array, ...values) {
  array.push(...values);
  return array;
}

export function dedup(array) {
  return array?.length > 0 ? Array.from(new Set(array)) : array
}

export function diff(arrayA, arrayB) {
  let soustractor = arrayA;
  let soustractee = arrayB;

  if (soustractor?.length > soustractee?.length) {
    [soustractee, soustractor] = [soustractor, soustractee]
  }

  return soustractee?.filter(item => !soustractor?.includes(item))
}
