<template>
  <div class="log-plot">
    <b-form-group label="Log type" label-for="log_type" label-cols-xl="3">
      <b-form-select
        id="log_type"
        :options="log_types"
        :value="selectedLogType"
        @input="notifyLogTypeChange"
      ></b-form-select>
    </b-form-group>
    <plot-config
      :xAxisOptions="runLogsProps"
      :yAxisOptions="runLogsProps"
      :multiple-y-axis="hasOneRun"
      :y-axis-limit="yAxisLimit"
      v-model="plotConfig"
    />
    <b-form>
      <b-form-group label="Label by" label-for="label_by" label-cols-xl="3">
        <b-form-select
          id="label_by"
          :options="runLogsProps"
          v-model="label_by"
          @input="notifyReplotNeeded"
        ></b-form-select>
      </b-form-group>
    </b-form>
    <div class="plot-options">
      <transition name="fade">
        <b-form-checkbox
          v-if="stats"
          v-model="areStatsShown"
          switch
          >Show stats</b-form-checkbox
        >
      </transition>

      <transition name="fade">
        <b-button
          v-if="hasDeletedDots"
          size="sm"
          variant="primary"
          @click="retoreDots"
          >Restore dots</b-button
        >
      </transition>

      <template v-if="hasMultipleRuns">
        <transition name="fade">
          <b-form-checkbox v-model="isMozaic" class="mozaic-switch" switch
            >Mozaic</b-form-checkbox
          >
        </transition>
        <transition name="fade">
          <b-form-radio-group
            v-if="isMozaic && hasMoreThanTwoRuns"
            v-model="mozaicColumns"
            :options="mozaicColumnsChoices"
            size="sm"
            button-variant="outline-primary"
            buttons
            class="mozaic-columns-switch"
          ></b-form-radio-group>
        </transition>
      </template>
    </div>
    <div class="border plots" :class="isTwoColumnsLayout && 'two-columns'">
      <template v-if="canPlotAsMozaic">
        <div
          v-for="run_log of run_logs"
          :key="run_log.run_id"
          ref="plot"
          class="group-plot"
        ></div>
      </template>
      <div v-else ref="plot" class="single-plot"></div>
    </div>
  </div>
</template>

<style scoped>
@import url("../../style/animation.css");

.plots {
  display: grid;
  grid-gap: 0.5em;
  overflow-x: hidden;

  grid-template-columns: repeat(
    auto-fit,
    minmax(min(max(var(--column-width, 25%), 380px), 100%), 1fr)
  );
}

.plots.two-columns {
  --column-width: 37.5%;
}

.single-plot,
.group-plot {
  width: 100%;
}

.single-plot {
  height: 750px;
}

.group-plot {
  height: 405px;
}

.plot-options {
  /* making sure that children z-index are scoped to this container (e.g. switch checkboxe element) */
  z-index: 0;
  position: relative;

  font-weight: bold;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 2.5em;
  padding: 0 0.2em;
}

.plot-options > * + * {
  margin-left: 1em;
}

.plot-options > .mozaic-switch:not(:first-child) {
  margin-left: 2em;
}

@media (max-width: 1829px) {
  .mozaic-columns-switch {
    display: none;
  }
}
</style>

<script>
import Plotly from "plotly.js-dist";

import {
  commonPlotTypes,
  BOX_PLOT_TYPE,
  MARKED_RUNS_OPACITY,
} from "../../constants/plot";

import PlotConfig from "./PlotConfig";
import { computeStatsPlotData, removeSelection } from "../../utils/plot";
import { push, dedup } from "../../utils/array";

function getRunLogAxisValues({ run_log, metaDataKeys, metaData, axis }) {
  return metaDataKeys.includes(axis)
    ? run_log.map(() => metaData[axis])
    : run_log.map((log) => log[axis]);
}

const mozaicPlotsTitleStyle = {
  yanchor: "top",
  font: { size: 12 },
};

const fullOpacityValues = [undefined, 1];

export default {
  name: "LogPlot",

  props: {
    run_logs: {
      type: Array,
      required: true,
    },

    log_types: {
      type: Array,
      required: true,
    },

    selectedLogType: {
      type: String,
      required: true,
    },

    metaDataKeys: {
      type: Array,
      required: true,
    },
  },

  components: { PlotConfig },

  data() {
    return {
      plotConfig: {
        plotType: "lines+markers",
        xAxis: "time",
        yAxis: ["chip_temperature_1"],
      },

      label_by: "sequence_side",
      isMozaic: false,
      mozaicColumns: 3,
      stats: undefined,
      areStatsShown: false,
      deletedDots: undefined,
    };
  },

  computed: {
    runLogsProps() {
      return [...Object.keys(this.run_logs[0]?.data[0]), ...this.metaDataKeys];
    },

    mozaicColumnsChoices() {
      return [
        { text: "2 columns", value: 2 },
        { text: "3 columns", value: 3 },
      ];
    },

    isTwoColumnsLayout() {
      return this.mozaicColumns === 2;
    },

    hasOneRun() {
      return this.run_logs.length === 1;
    },

    hasMultipleRuns() {
      return this.run_logs.length > 1;
    },

    hasMoreThanTwoRuns() {
      return this.run_logs.length > 2;
    },

    canPlotAsMozaic() {
      return this.isMozaic && this.hasMultipleRuns;
    },

    hasDeletedDots() {
      return this.deletedDots?.length > 0;
    },

    runLogsIds() {
      return this.run_logs.map((run_log) => run_log.run_id);
    },
  },

  watch: {
    run_logs: {
      handler() {
        this.markedRuns = this.markedRuns?.filter((markedRun) =>
          this.runLogsIds.includes(markedRun)
        );

        this.deletedDots = undefined;
        delete this.filteredData;

        this.plotConfig.xAxis = this.runLogsProps.includes(this.plotConfig.xAxis)
          ? this.plotConfig.xAxis
          : this.runLogsProps[0];

        if (this.hasOneRun) {
          const yAxis = Array.isArray(this.plotConfig.yAxis) ? this.plotConfig.yAxis : [this.plotConfig.yAxis];

          this.plotConfig.yAxis = dedup(yAxis.map(
            axis => this.runLogsProps.includes(axis) ? axis : this.runLogsProps[1]
          ));
        } else {
          const yAxis = Array.isArray(this.plotConfig.yAxis) ? this.plotConfig.yAxis[0] : this.plotConfig.yAxis;
          this.plotConfig.yAxis = this.runLogsProps.includes(yAxis) ? yAxis : this.runLogsProps[1];
        }
      },

      immediate: true,
    },

    'plotConfig.yAxis': {
      handler() {
        this.label_by = this.runLogsProps.includes(this.label_by)
          ? this.label_by
          : this.runLogsProps[0];
      },

      immediate: true,
    },

    isMozaic() {
      this.notifyReplotNeeded();
    },

    hasMoreThanTwoRuns() {
      this.notifyResizeNeeded();
    },

    mozaicColumns() {
      this.notifyResizeNeeded();
    },

    plotConfig() {
      this.deletedDots = undefined;
      delete this.filteredData;
      this.notifyReplotNeeded();
    },

    areStatsShown(areStatsShown) {
      if (areStatsShown) {
        this.showStats();
      } else {
        this.hideStats();
      }
    },

    stats(stats) {
      if (stats && this.areStatsShown) {
        this.showStats();
      }
    },
  },

  methods: {
    notifyLogTypeChange(log_type) {
      this.$emit("log-type-change", {
        log_type,
        run_ids: this.run_logs.map((run_log) => run_log.run_id),
      });
    },

    prepareplotData ({ type, mode }) {
      if (Array.isArray(this.plotConfig.yAxis)) {
        return this.plotConfig.yAxis.flatMap(
          yAxis => this.run_logs.map(run_log => this.runLog2PlotData({
            ...run_log, type, mode, yAxis, legend: yAxis
          }))
        )
      } else {
        return this.run_logs.map(
          run_log => this.runLog2PlotData({ ...run_log, type, mode, yAxis: this.plotConfig.yAxis })
        )
      }
    },

    runLog2PlotData({ run_id, data: run_log, metaData, legend, yAxis, type, mode }) {
      return {
        x: getRunLogAxisValues({
          run_log,
          metaDataKeys: this.metaDataKeys,
          metaData,
          axis: this.plotConfig.xAxis,
        }),

        y: getRunLogAxisValues({
          run_log,
          metaDataKeys: this.metaDataKeys,
          metaData,
          axis: yAxis,
        }),

        mode: mode,
        type: type,
        text: run_log.map((ele) => this.label_by + ": " + ele[this.label_by]),
        name: legend,

        ...(this.plotConfig.plotType === BOX_PLOT_TYPE && {
          boxpoints: this.plotConfig.boxPoints && "all",
          notched: this.plotConfig.boxNotches,
        }),

        meta: { run_id },
        opacity: this.computeTraceOpacity({ meta: { run_id } }),
      }
    },

    async plot() {
      const vm = this;

      const type = commonPlotTypes.includes(vm.plotConfig.plotType)
        ? vm.plotConfig.plotType
        : "scatter";

      const mode = commonPlotTypes.includes(vm.plotConfig.plotType)
        ? ""
        : vm.plotConfig.plotType;

      const data = this.filteredData || this.prepareplotData({ type, mode })

      const config = {
        responsive: true,
        displaylogo: false,
      };

      const layout = {
        margin: { t: 25, r: 0, b: 20, l: 20 },
        yaxis: { automargin: true },
        dragmode: this.hasDeletedDots && "lasso",
      };

      let graphDivs;

      if (this.canPlotAsMozaic) {
        const plotInputs = [];
        let stats;

        for (const datum of data) {
          const plotInput = {
            data: [datum],

            layout: {
              ...layout,

              title: {
                text: datum.name,
                ...mozaicPlotsTitleStyle,
              },

              showlegend: false,
            },
          };

          plotInputs.push(plotInput);

          stats ??= [];
          stats.push(computeStatsPlotData(plotInput.data));
        }

        this.stats = stats;
        await vm.groupPlot({ plotInputs, config });
        graphDivs = this.$refs.plot;
      } else {
        if (data?.length > 0) {
          await Plotly.react(vm.$refs.plot, data, layout, config);
          this.stats = this.plotConfig.yAxis.length === 1 && !this.hasMultipleRuns && computeStatsPlotData([data[0]]);
          graphDivs = [this.$refs.plot];
        } else {
          this.stats = undefined
          await Plotly.purge(this.$refs.plot);
          graphDivs = [];
        }
      }

      for (const graphDiv of graphDivs) {
        graphDiv.removeListener("plotly_click", this.notifyGraphSelection);
        graphDiv.on("plotly_click", this.notifyGraphSelection);
      }
    },

    groupPlot({ plotInputs, config }) {
      return Promise.all(
        plotInputs.map(({ data, layout }, index) =>
          Plotly.react(this.$refs.plot[index], data, layout, config)
        )
      );
    },

    notifyGraphSelection({
      points: [
        {
          data: {
            meta: { run_id },
          },
        },
      ],
    }) {
      this.latestMarkedRun = run_id;

      if (this.isMarkedRun(run_id)) {
        this.markedRuns = this.markedRuns.filter(
          (markedRun) => markedRun !== run_id
        );
      } else {
        this.markedRuns = this.markedRuns
          ? push(this.markedRuns, run_id)
          : [run_id];
      }

      this.$emit("select-graph", { run_ids: this.markedRuns });

      if (this.hasMultipleRuns) {
        this.$emit("restyle-needed");
      }
    },

    isMarkedRun(run_id) {
      return this.markedRuns?.includes(run_id);
    },

    notifyReplotNeeded() {
      this.$emit("replot-needed");
    },

    notifyResizeNeeded() {
      this.$emit("resize-needed");
    },

    resizePlot() {
      const plots = this.canPlotAsMozaic ? this.$refs.plot : [this.$refs.plot];
      return Promise.all(plots.map((plot) => Plotly.Plots.resize(plot)));
    },

    restylePlot() {
      const { plot } = this.$refs;
      const latestMarkedRun = this.latestMarkedRun;

      delete this.latestMarkedRun;

      if (this.canPlotAsMozaic) {
        const restyleCandidate = plot.find(
          (graphDiv) => graphDiv.data[0].meta.run_id === latestMarkedRun
        );

        const opacity = this.computeTraceOpacity(restyleCandidate.data[0]);
        return Plotly.restyle(restyleCandidate, { opacity }, 0);
      } else {
        const restyleCandidate = plot.data.findIndex(
          (traceData) => traceData.meta.run_id === latestMarkedRun
        );

        const opacity = this.computeTraceOpacity(plot.data[restyleCandidate]);
        return Plotly.restyle(plot, { opacity }, restyleCandidate);
      }
    },

    computeTraceOpacity({ meta: { run_id }, opacity }) {
      return this.isMarkedRun(run_id) && fullOpacityValues.includes(opacity) && this.hasMultipleRuns
        ? MARKED_RUNS_OPACITY
        : 1;
    },

    showStats() {
      const [graphDivs, groupStats] = this.getPlotStatsPairs();

      graphDivs.forEach(async (graphDiv, index) => {
        let title;
        const traceName = graphDiv.data[0].name;

        if (groupStats?.[index]) {
          await Plotly.addTraces(graphDiv, groupStats[index]);

          title = {
            text: traceName + "  |  %{data[1].meta.extraStats}",
            ...mozaicPlotsTitleStyle,
          };
        } else {
          title = this.getResetTraceTitle(graphDiv);
        }

        Plotly.relayout(graphDiv, { title });
      });
    },

    hideStats() {
      const [graphDivs, groupStats] = this.getPlotStatsPairs();
      const LAST_TRACE = 1;

      graphDivs.forEach(async (graphDiv, index) => {
        if (groupStats?.[index]) {
          await Plotly.deleteTraces(graphDiv, LAST_TRACE);
          Plotly.relayout(graphDiv, {
            title: this.getResetTraceTitle(graphDiv),
          });
        }
      });
    },

    getResetTraceTitle(graphDiv) {
      return this.canPlotAsMozaic
        ? {
            text: graphDiv.data[0].name,
            ...mozaicPlotsTitleStyle,
          }
        : null;
    },

    getPlotStatsPairs() {
      const {
        $refs: { plot },
      } = this;

      return Array.isArray(plot) ? [plot, this.stats] : [[plot], [this.stats]];
    },

    async removeSelection({ key }) {
      const {
        $refs: { plot },
      } = this;

      const plotData = Array.isArray(plot)
        ? plot.flatMap(({ data }) => data)
        : plot.data;

      const hasSelection = plotData?.some(
        (datum) => datum?.selectedpoints?.length > 0
      );

      if (["Delete", "Backspace"].includes(key) && hasSelection) {
        ({
          filteredOutDots: this.deletedDots,
          filteredData: this.filteredData,
        } = removeSelection(plotData));

        this.notifyReplotNeeded();
      }
    },

    retoreDots() {
      this.deletedDots = this.filteredData = undefined;
      this.notifyReplotNeeded();
    },
  },

  created() {
    this.yAxisLimit = this.$root.$options.config.LOG_PLOT_TRACES_LIMIT;
  },

  mounted() {
    document.addEventListener("keyup", this.removeSelection);
  },

  destroyed() {
    document.removeEventListener("keyup", this.removeSelection);
  },
};
</script>