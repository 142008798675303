<template>
  <div :class="$options.name">
    <h2>Metadata utility</h2>
    <hr />
    <b-alert :show="error" variant="danger">{{ error }}</b-alert>

    <div v-if="!fetching" class="free-text">
      <b-form-input v-model="newKey" placeholder="key"></b-form-input>
      <b-form-input v-model="newValue" placeholder="value"></b-form-input>
      <b-button :disabled="!canAddFreeText" variant="primary" @click="addKeyValuePair">Add</b-button>
    </div>

    <b-overlay :show="fetching">
      <b-table-lite class="metadata" striped :items="metadata">
        <template #cell(qr_code)="data">
          <img :src="data.value" />
        </template>
      </b-table-lite>
    </b-overlay>
  </div>
</template>

<style scoped>
.metadata {
  min-height: 2em;
}

.free-text {
  display: flex;
  margin: 1em 0;
}

.free-text> :not(:first-child) {
  margin-left: 1em;
}
</style>

<script>
import axios from "axios";
import qrcode from "qrcode";
import { runAllInBatches } from "./utils/async";

export default {
  name: "MetadataUtility",

  data() {
    return {
      metadata: [],
      error: undefined,
      fetching: true,
      newKey: "",
      newValue: "",
    };
  },

  computed: {
    canAddFreeText() {
      return Boolean(this.newKey && this.newValue);
    },
  },

  methods: {
    async addKeyValuePair() {
      const keyValuePair = `${this.newKey}=${this.newValue}`;

      this.metadata.unshift({
        key_value_pair: keyValuePair,
        qr_code: await qrcode.toDataURL(keyValuePair),
      });

      this.newKey = this.newValue = "";
    },

    async fetchMetaValues(meta_key) {
      return (await axios.get("/api/v1/meta/values", {
        params: { key: meta_key }
      })).data.map((value) => {
        return {
          key: meta_key,
          value: value
        }
      })
    }
  },

  async beforeMount() {
    try {
      const metaKeys = (await axios.get("/api/v1/meta/keys")).data
      var fetches = []
      for (let meta_key of metaKeys) {
        fetches.push(() => this.fetchMetaValues(meta_key))
      }
      const metaPairs = (await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })).flat()
      this.metadata = await Promise.all(
        metaPairs.map(async (ele) => {
          var key_value_pair = `${ele.key}=${ele.value}`;
          var qr_code = await qrcode.toDataURL(key_value_pair);
          return {
            key_value_pair: key_value_pair,
            qr_code: qr_code,
          };
        })
      );
    } catch (error) {
      console.error(error);
      this.error = error.message;
    } finally {
      this.fetching = false;
    }
  },
};
</script>