import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './App.vue'
import * as config from './app.config'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

if (location.hostname != "localhost" && location.protocol != "https:") {
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}
if (location.pathname.startsWith("/v2/") && location.protocol == "https:") {
  var splitted_path = location.href.split("/");
  var url = new URL(location.origin + "/v2/");
  url.search = new URLSearchParams({
    dest: splitted_path.slice(4).join("/"),
  });
  location = url.toString();
}

new Vue({
  config,
  render: h => h(App),
}).$mount('#app')
