<template>
  <div>
    <h3>
      Runs list
      <small v-if="hasData" class="mr-1">({{ runDataList.length }})</small>
      <small><help-view>Choose which runs to display.</help-view></small>
    </h3>
    <b-form-checkbox v-model="multiSelect" switch :disabled="disabled">Display multiple runs</b-form-checkbox>
    <div class="mt-1" v-if="analysisVersions.length > 0">
      <b-form-group label="Version" label-for="analysis-version" label-cols-xl="3" class="mt-1 mb-0">
        <b-form-select id="analysis-version" v-model="selectedVersion" :options="analysisVersions"></b-form-select>
      </b-form-group>
    </div>
    <div class="action-buttons mt-1" v-if="multiSelect && hasData">
      <b-button-group class="full-width">
        <b-button v-if="anySelected && !fetching" :disabled="disabled" @click="clearSelected" size="sm">Unselect
          all</b-button>
        <b-button v-if="canSelectAll && !fetching" @click="selectAllRows" :disabled="disabled" size="sm">Select
          all</b-button>
      </b-button-group>
      <transition name="fade">
        <small class="header-hint" v-if="isRunSelectionLimitReached">Maximum run selection reached</small>
      </transition>
    </div>
    <b-table v-if="fetching" ref="runsListTable" :items="runDataList" show-empty :fields="fields" small striped bordered
      sort-by="data.info.ananke_id" hover sortable :busy="disabled" :select-mode="selectMode"
      class="data text-nowrap mt-1" :class="isRunSelectionLimitReached && 'selection-limit-reached'"
      sticky-header="350px" @row-selected="onRowSelected" thead-class="bfc-adv-runs-list-thead"
      :tbody-tr-class="getRowCssClass" :title="isRunSelectionLimitReached && 'Maximum run selection reached'">
      <template v-slot:[`cell(data.info.ananke_id)`]="data"><b-button size="sm"
          :href="`/run/${data.item.data.info.machine_name}/${data.item.data.info.ananke_id}`" target="_blank"
          title="Permalink" class="p-0 mr-1" variant="dark"><b-icon icon="link"></b-icon></b-button>{{
        data.item.data.info.ananke_id }}</template>
    </b-table>
    <b-table v-else ref="runsListTable" :items="runDataList" show-empty :fields="fields" small striped bordered
      sort-by="data.info.ananke_id" hover sortable selectable :busy="disabled" :select-mode="selectMode"
      class="data text-nowrap mt-1" :class="isRunSelectionLimitReached && 'selection-limit-reached'"
      sticky-header="350px" @row-selected="onRowSelected" thead-class="bfc-adv-runs-list-thead"
      :tbody-tr-class="getRowCssClass" :tbody-tr-attr="rowAttr"
      :title="isRunSelectionLimitReached && 'Maximum run selection reached'">
      <template v-slot:[`cell(data.info.ananke_id)`]="data"><b-button size="sm"
          :href="`/run/${data.item.data.info.machine_name}/${data.item.data.info.ananke_id}`" target="_blank"
          title="Permalink" class="p-0 mr-1" variant="dark"><b-icon icon="link"></b-icon></b-button>{{
        data.item.data.info.ananke_id }}</template>
    </b-table>
  </div>
</template>

<style scoped>
@import url("../../style/animation.css");

.data {
  --scrollbar-height: 0.6em;
  overflow-y: auto;
  margin-bottom: 0;
}

.data::-webkit-scrollbar {
  background-color: rgba(50, 50, 50, 12.5%);
  height: var(--scrollbar-height);
}

.data::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 75%);
  border-radius: calc(var(--scrollbar-height) / 2);
}

.action-buttons {
  display: flex;
  align-items: center;
}

.action-buttons> :not(:last-child) {
  margin-right: 1em;
}

.header-hint {
  margin-left: auto;
}

.selection-limit-reached {
  cursor: not-allowed;
}
</style>

<!-- ⚠️ unscoped style -->
<style>
.bfc-adv-runs-list-disabled-row {
  pointer-events: none;
  position: relative;
}

.bfc-adv-runs-list-disabled-row::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(228, 231, 235, 75%);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.bfc-adv-runs-list-disabled-row:not(:last-child):after {
  border-bottom: 1px solid white;
}

.bfc-adv-runs-list-marked-row {
  opacity: 0.6;
}
</style>

<script>
import HelpView from "../HelpView.vue";
import { formatHealthMessage } from "../../utils/message";
import { LOG_VIEW } from "../../constants/plot";
import { diff } from "../../utils/array";

export default {
  name: "RunsList",
  components: { HelpView },

  props: {
    runDataList: {
      type: Array,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    runSelectionLimit: {
      type: Number,
      default: Infinity,
    },

    selectedPlotView: String,
    markedRuns: Array,
    fetching: Boolean,
    analysisVersions: Array,
    analysisVersion: String
  },

  data() {
    return {
      multiSelect: false,
      selectedRunIds: [],
      selectedVersion: ""
    };
  },

  watch: {
    runDataList() {
      this.clearSelected();
    },

    analysisVersion() {
      this.selectedVersion = this.analysisVersion
    },

    selectedVersion() {
      this.$emit("version", this.selectedVersion)
    },

    async watchableMarkedRuns(markedRuns, previousValue) {
      await this.$nextTick();
      const currentMarkedRun = diff(previousValue, markedRuns)?.[0];

      if (currentMarkedRun) {
        const runsList = this.$refs.runsListTable.$el;

        const runsListTHead = document.querySelector(
          ".bfc-adv-runs-list-thead"
        );

        const markedRow = document.querySelector(
          ".bfc-adv-runs-list-row-" + currentMarkedRun
        );

        const { y: runListClientY } = runsList.getBoundingClientRect();
        const { y: rowClientY } = markedRow.getBoundingClientRect();

        runsList.scrollTop +=
          rowClientY - runListClientY - runsListTHead.offsetHeight;
      }
    },
  },

  methods: {
    onRowSelected: function (items) {
      this.selectedRunIds = items.map((item) => item.run_id);
      this.$emit("select", items);
    },

    selectAllRows() {
      this.$refs.runsListTable.selectAllRows();
    },

    clearSelected() {
      this.$refs.runsListTable.clearSelected();
      this.selectedRunIds = [];
    },

    isRowDisabled(item) {
      return (
        this.isRunSelectionLimitReached &&
        !this.selectedRunIds.includes(item?.run_id)
      );
    },

    isRowMarked(item) {
      return this.markedRuns?.includes(item?.run_id);
    },

    getRowCssClass(item) {
      const cssClasses = ["bfc-adv-runs-list-row-" + item?.run_id];

      if (item) {
        if (item.data.info.health_status === "CRITICAL" || item.data.info.health_status === "BLOCK_MACHINE") {
          cssClasses.push("row-error font-weight-bold");
        }
        if (item.data.info.run_status === "INVALID") {
          cssClasses.push("row-error font-weight-bold");
        }
        if (item.data.info.wrong_chip) {
          cssClasses.push("row-error font-weight-bold");
        }
        if (item.data.info.chip_id?.length > 0 && (!item.data.info.batch_id || item.data.info.batch_id.length == 0)) {
          cssClasses.push("row-error font-weight-bold");
        }
        if (item.data.info.health_status === "WARNING" && cssClasses.includes("row-error")) {
          cssClasses.push("row-warning font-weight-bold");
        }
      }

      if (this.isRowDisabled(item)) {
        cssClasses.push("bfc-adv-runs-list-disabled-row");
      }

      if (this.isRowMarked(item)) {
        cssClasses.push("bfc-adv-runs-list-marked-row");
      }

      return cssClasses;
    },

    rowAttr(item) {
      if (item) {
        if (
          (item.data.info.health_status === "CRITICAL" ||
            item.data.info.health_status === "BLOCK_MACHINE") &&
          item.data.info.last_message !== null
        ) {
          return {
            title: "Last message: " + formatHealthMessage(item.data.info.last_message),
          };
        }
        if (item.data.info.run_status === "INVALID") {
          return {
            title: "Invalid run status",
          };
        }
        if (item.data.info.wrong_chip) {
          return {
            title: "Wrong chip",
          };
        }
        if (item.data.info.chip_id?.length > 0 && (!item.data.info.batch_id || item.data.info.batch_id.length == 0)) {
          return {
            title: "Chip ID without batch"
          };
        }
        if (item.data.info.health_status === "WARNING" && item.data.info.last_message !== null) {
          return {
            title: "Last message: " + formatHealthMessage(item.data.info.last_message),
          };
        }
      }
    },
  },

  computed: {
    hasData() {
      return this.runDataList.length > 0;
    },

    selectMode() {
      return this.multiSelect ? "multi" : "single";
    },

    allSelected() {
      return this.runDataList.length === this.selectedRunIds.length;
    },

    anySelected() {
      return this.selectedRunIds.length > 0;
    },

    canSelectAll() {
      return this.selectedPlotView !== LOG_VIEW && !this.allSelected;
    },

    isRunSelectionLimitReached() {
      return this.selectedRunIds.length >= this.runSelectionLimit;
    },

    watchableMarkedRuns() {
      return this.markedRuns && [...this.markedRuns];
    },

    fields() {
      return [
        {
          key: "data.info.machine_name",
          label: "Machine name",
          sortable: true,
        },
        {
          key: "data.info.ananke_id",
          label: "Chronos ID",
          sortable: true,
        },
        {
          key: "data.info.description",
          label: "Description",
          sortable: true,
        },
        {
          key: "data.info.experiment_name",
          label: "Name",
          sortable: true,
        },
        {
          key: "data.info.start",
          label: "Date",
          sortable: true,
        },
      ];
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%
}
</style>
