<template>
  <b-overlay :show="changing_password">
    <b-card>
      <p class="text-center d-none d-xl-block">
        <b-img src="/logo.png" style="max-height: 150px" fluid></b-img>
      </p>
      <p class="text-center d-xl-none">
        <b-img src="/logo_small.png" style="max-height: 150px" fluid></b-img>
      </p>
      <h2 class="text-center">Welcome to GAIA Browser</h2>
      <p class="text-center">Version {{ app_version }}</p>
      <h3 class="text-center">Password change</h3>
      <b-alert :show="changing_error !== undefined" class="mb-2 text-center" variant="danger">{{ changing_error
      }}<br /><router-link to="/user/reset" class="text-danger">Request a password reset</router-link></b-alert>
      <b-alert :show="password_changed" class="mb-2" variant="success">Your password has been changed
      </b-alert>
      <p class="text-center">
        <router-link to="/" class="text-dark">Go back to home page</router-link>
      </p>
      <b-form-group label="New password" label-for="new_password" label-cols-xl="3">
        <b-form-input id="new_password" type="password" v-model="new_password" placeholder="Type your new password"
          :state="
            new_password.length > 0 &&
            password_compliant_regex.test(new_password)
          ">
        </b-form-input>
      </b-form-group>
      <b-form-group label="New password" label-for="new_password2" label-cols-xl="3">
        <b-form-input id="new_password2" type="password" v-model="new_password2" placeholder="Confirm your new password"
          :state="new_password2.length > 0 && new_password == new_password2"></b-form-input>
      </b-form-group>
      <div class="text-secondary">
        A password must respect these rules:
        <ul>
          <li v-for="rule of password_compliant" :key="rule">{{ rule }}</li>
        </ul>
      </div>
      <b-button block variant="dark" :disabled="checkPasswordState()" @click="changePassword">Change password</b-button>
      <p class="text-secondary text-center">
        <b-icon icon="info-circle" class="mr-1"></b-icon>Once you change your
        password, you will need to login again.
      </p>
      <hr />
      <p class="m-0 text-center">
        <a href="https://bforcure.atlassian.net/wiki/x/N4BRBg" target="_blank" class="text-dark">Need more help?</a>
      </p>
    </b-card>
  </b-overlay>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangePassword",
  props: [
    "token",
    "password_compliant",
    "password_compliant_regex",
    "app_version",
  ],
  data: function () {
    return {
      new_password: "",
      new_password2: "",
      changing_password: false,
      password_changed: false,
      changing_error: undefined,
    };
  },
  methods: {
    async checkToken() {
      try {
        await axios
          .get("/api/v1/user/check_token", {
            params: {
              token: this.token,
            },
          })
      } catch {
        this.changing_error =
          "The token is invalid. Please ask again for a password reset email.";
      }
    },
    checkPasswordState() {
      return !(
        this.new_password.length > 0 &&
        this.password_compliant_regex.test(this.new_password) &&
        this.new_password2.length > 0 &&
        this.new_password == this.new_password2
      );
    },
    async changePassword() {
      this.changing_password = true;
      this.password_changed = false;
      this.changing_error = undefined;
      try {
        await axios
          .post("/api/v1/user/change_password", {
            email: null,
            token: this.token,
            password: null,
            new_password: this.new_password,
            new_password2: this.new_password2,
          })
        this.password_changed = true
      }
      catch (error) {
        if (error.response.data.detail !== undefined) {
          this.changing_error = "Could not change the password";
        } else {
          this.changing_error = "Internal server error";
        }
      }
      this.changing_password = false;
    },
  },
  created: function () {
    this.checkToken();
  },
};
</script>