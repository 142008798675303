<template>
  <div class="fullscreen">
    <b-row align-h="center" align-v="center" class="h-100">
      <b-col lg="4">
        <b-overlay :show="fetching">
          <change-password v-if="token !== undefined" :token="token" :password_compliant="password_compliant"
            :password_compliant_regex="password_compliant_regex" :app_version="app_version"></change-password>
          <reset-password v-else-if="show_reset_password" :app_version="app_version"></reset-password>
          <login-form v-else :app_version="app_version"></login-form>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import ChangePassword from "./components/ChangePassword.vue";
import ResetPassword from "./components/ResetPassword.vue";
import LoginForm from "./components/LoginForm.vue";

export default {
  name: "Home",
  props: [
    "token",
    "password_compliant",
    "password_compliant_regex",
    "show_reset_password",
  ],
  data: function () {
    return {
      app_version: "",
      fetching: false,
      login: false,
      error: false,
      error_message: "",
      email: "",
      password: "",
      password_type: "password",
      oidc_login: undefined,
      email_sent: false,
    };
  },
  methods: {
    async getAppVersion() {
      this.fetching = true
      this.app_version = (await axios.get("/api/v1/infos/version")).data
      try {
        await axios
          .get("/api/v1/user/info")
      } catch (error) {
        console.warn("The user is not authenticated.")
      }
      this.fetching = false
    },
  },
  created: function () {
    this.getAppVersion();
  },
  components: {
    ChangePassword,
    ResetPassword,
    LoginForm,
  },
};
</script>
<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: radial-gradient(#e94b53, #722281);
}
</style>