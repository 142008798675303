<template>
    <div>
        <b-button-group class="mt-2 mb-2 full-width">
            <b-button @click="downloadInterpretations()" title="Download interpretations in a CSV file"><b-icon
                    icon="download" class="mr-1"></b-icon>Export</b-button>
        </b-button-group>
        <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="interpretation of interpretations" :key="interpretation.run_infos.id">
                <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle :href="'#interpretation_accordion-' + interpretation.run_infos.id"
                        :variant="interpretation.selected ? 'info' : ''">{{
                            interpretation.run_infos.machine_name + " - " + interpretation.run_infos.ananke_id +
                            (interpretation.selected ? " (Selected)" : " (Unselected)")
                        }}</b-button>
                </b-card-header>
                <b-collapse :id="'interpretation_accordion-' + interpretation.run_infos.id"
                    :visible="interpretation.run_infos.id == interpretations[0].run_infos.id"
                    accordion="interpretation-accordion" role="tabpanel">
                    <b-card-body>
                        <b-list-group v-if="interpretation.results">
                            <b-list-group-item v-for="result of interpretation.results" :key="result.name"><strong>{{
                                result.name }}: </strong>{{ result.values.join(", ") }}</b-list-group-item>
                        </b-list-group>
                        <b-list-group v-else>
                            <b-list-group-item
                                v-if="interpretation.valid_internal_control !== undefined"><strong>Internal Control
                                    Validity: </strong>{{
                                        interpretation.valid_internal_control ? 'Valid' : 'Invalid' }}</b-list-group-item>
                            <b-list-group-item v-for="result_index in interpretation.compute_results.length"
                                :key="result_index">
                                <strong>{{ translate(interpretation.compute_results[result_index - 1].label)
                                    }}: </strong>
                                <span v-if="interpretation.compute_results[result_index - 1].value">
                                    <span v-if="interpretation.compute_results[result_index - 1].format == 'float'">{{
                                        interpretation.compute_results[result_index - 1].value.toExponential(2)
                                    }}</span>
                                    <span
                                        v-else-if="interpretation.compute_results[result_index - 1].format == 'int'">{{
                                            Math.round(interpretation.compute_results[result_index - 1].value * 100) / 100
                                        }}</span>
                                    <span v-else>{{
                                        interpretation.compute_results[result_index - 1].value }}</span>
                                </span>
                                <span v-else>N/A</span>
                                <span>{{ translate(interpretation.compute_results[result_index - 1].unit) }}</span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "InterpretationTable",
    props: [
        "interpretations",
        "analysis_version"
    ],
    data: function () {
        return {
            translations: {
                "concentration_label_concentration": "Concentration",
                "concentration_unit_ug_ml": " GU/mL",
                "concentration_unit_ufc_ml": " CFU/mL eq.",
                "concentration_label_growth_index": "Growth Index",
                "concentration_unit_growth_index_arn": "/5 (RNA)",
                "concentration_unit_growth_index_adn": "/5 (DNA)",
                "concentration_label_growth_index_1": "Growth Index (RNA)",
                "concentration_label_growth_index_2": "Growth Index (DNA)",
                "concentration_label_viability": "Viability Index",
                "interpretation_concentration_viability_index_scale_min": "Minimum Viability",
                "interpretation_concentration_viability_index_scale_max": "Maximum Viability",
                "concentration_label_concentration_e_coli": "Concentration (E. Coli)",
                "concentration_label_concentration_enterococcus": "Concentration (Enterococcus)",
                "concentration_unit_ufc_100_ml": " CFU/100 mL"
            }
        }
    },
    methods: {
        translate(key) {
            if (Object.keys(this.translations).includes(key)) {
                return this.translations[key]
            }
            return key
        },
        downloadInterpretations() {
            var column_names = [
                "Machine Name",
                "Chronos ID",
                "Description",
                "Samples",
                "Chip barcode",
                "Batch ID",
                "Analysis version",
                "Analysis date (UTC)"
            ];
            for (let interpretation of this.interpretations) {
                if (interpretation.results) {
                    for (let result of interpretation.results) {
                        if (!column_names.includes(result.name)) {
                            column_names.push(result.name)
                        }
                    }
                }
                else {
                    var column_name = "Internal Control Validity"
                    if (!column_names.includes(column_name)) {
                        column_names.push(column_name)
                    }
                    for (let result_index = 0; result_index < interpretation.compute_results.length; result_index++) {
                        column_name = this.translate(interpretation.compute_results[result_index].label) +
                            " (" + this.translate(interpretation.compute_results[result_index].unit).trim() + ")"
                        if (!column_names.includes(column_name)) {
                            column_names.push(column_name)
                        }
                    }
                }
            }
            var rows = []
            for (let interpretation of this.interpretations) {
                let row = [
                    interpretation.run_infos.machine_name,
                    interpretation.run_infos.ananke_id,
                    interpretation.run_infos.description,
                    interpretation.run_infos.samples,
                    interpretation.run_infos.chip_barcode,
                    interpretation.run_infos.batch_range,
                    this.analysis_version,
                    interpretation.date
                ]
                if (column_names.length > 7) {
                    for (let column_index = 8; column_index < column_names.length; column_index++) {
                        var result = undefined
                        if (interpretation.results) {
                            result = interpretation.results.find(ele => ele.name == column_names[column_index])?.values
                            if (result) {
                                result = result.join(",")
                            }
                        }
                        else {
                            result = interpretation.compute_results.find(ele => this.translate(ele.label) +
                                " (" + this.translate(ele.unit).trim() + ")" == column_names[column_index])?.value
                        }
                        if (result) {
                            row.push(result)
                        } else {
                            row.push(null)
                        }
                    }
                }
                rows.push(row)
            }
            const array_data = [column_names].concat(rows);
            const csv_content =
                '"' + array_data.map((ele) => ele.join('","')).join('"\n"') + '"';
            const encoded_uri =
                encodeURI("data:text/csv;charset=utf-8,") +
                encodeURIComponent(csv_content);
            const link = document.createElement("a");
            link.setAttribute("href", encoded_uri);
            const now = new Date();
            const fileName =
                "gaia_data_interpretation_" + this.analysis_version + "_" +
                now.getFullYear() +
                (now.getMonth() + 1) +
                now.getDate() +
                "T" +
                now.getHours() +
                now.getMinutes() +
                now.getSeconds() +
                ".csv";
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        }
    }
}
</script>
<style scoped>
.full-width {
    width: 100%
}
</style>