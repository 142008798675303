<template>
    <div>
        <h2>
            Data upload<small class="p-1"><help-view>This page allows to upload run data to GAIA after being
                    exported from the Chronos software, in case of non-connectivity.</help-view></small>
        </h2>
        <hr />
        <b-container fluid>
            <h3>Chronos run file</h3>
            <b-alert show>The zip file must contain only JSON files (the file extension doesn't matter).
                Multiple JSON files can be uploaded in the same zip file.</b-alert>
            <b-overlay :show="uploading">
                <b-alert :show="error" class="mb-2" variant="danger" dismissible>There was an error during the upload.
                    Please check your file or try again.</b-alert>
                <b-form-group label="Upload run file" label-for="run_file" label-cols-xl="3">
                    <b-form-file v-model="run_file" :state="Boolean(run_file)" id="run_file"
                        placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
                        accept="application/zip"></b-form-file>
                </b-form-group>
                <b-button block variant="primary" :disabled="run_file === null" @click="uploadRunFile">Upload</b-button>
                <div v-if="run_datas.length > 0" class="mt-2">
                    <p><strong>You have uploaded these PCR runs:</strong>
                    <ul>
                        <li v-for="run_data of run_datas" :key="run_data.gaia_id"><a target="_blank"
                                :href="'/run/' + run_data.machine_name + '/' + run_data.ananke_id">{{
                run_data.machine_name }}, {{
                run_data.ananke_id }}</a></li>
                    </ul>
                    </p>
                </div>
            </b-overlay>
        </b-container>
    </div>
</template>

<script>
import axios from "axios";
import HelpView from "./components/HelpView.vue";

export default {
    name: "DataUpload",
    data: function () {
        return {
            run_file: null,
            uploading: false,
            run_datas: [],
            error: false
        }
    },
    methods: {
        async uploadRunFile() {
            this.error = false
            this.uploading = true
            this.run_datas = []
            var formData = new FormData()
            formData.append("file", this.run_file)
            try {
                const full_hashes = (await axios.post("/api/v1/chunks/upload", formData)).data
                for (const full_hash of full_hashes) {
                    const ingest_hash = (await axios.post("/api/v1/chunks/enrich", null, {
                        params: { full_hash: full_hash }
                    })).data
                    const run_data = (await axios.post("/api/v1/chunks/ingest", null, {
                        params: { full_hash: ingest_hash }
                    })).data
                    this.run_datas.push(run_data)
                }
            }
            catch (error) {
                this.error = true
            }
            this.uploading = false
        }
    },
    components: {
        HelpView
    }
}
</script>