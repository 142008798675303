<template>
  <b-overlay :show="login">
    <b-card>
      <p class="text-center d-none d-xl-block">
        <b-img src="/logo.png" style="max-height: 150px" fluid></b-img>
      </p>
      <p class="text-center d-xl-none">
        <b-img src="/logo_small.png" style="max-height: 150px" fluid></b-img>
      </p>
      <h2 class="text-center">
        Welcome to GAIA Browser
      </h2>
      <p class="text-center">Version {{ app_version }}</p>
      <div v-if="oidc_login !== undefined">
        <b-alert :show="error_oidc !== undefined" class="mb-2" variant="danger" dismissible>You are not authorized to
          login.</b-alert>
        <b-button variant="dark" :href="oidc_login" block>Login with enterprise account</b-button>
        <p></p>
      </div>
      <h3 class="text-center">Login form</h3>
      <b-alert :show="error" variant="danger" dismissible>{{
        error_message
      }}</b-alert>
      <b-form @submit="Login">
        <b-form-group label="Email" label-for="user-email">
          <b-form-input id="user-email" type="email" v-model="email" required
            placeholder="Please enter your email address"></b-form-input>
        </b-form-group>
        <b-form-group label="Password" label-for="user-password">
          <b-input-group>
            <b-form-input id="user-password" :type="password_type" v-model="password" required
              placeholder="Please enter your password"></b-form-input>
            <b-input-group-append>
              <b-button variant="dark" title="Display password" v-if="password_type == 'password'"
                @click="password_type = 'text'">
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button variant="light" title="Hide password" v-else @click="password_type = 'password'">
                <b-icon icon="eye-slash"></b-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <p class="mt-2 mb-0">
              <router-link to="/user/reset" class="text-dark">Forgot your password?</router-link>
            </p>
          </b-col>
          <b-col cols="6">
            <b-button type="submit" variant="dark" class="float-right">Login</b-button>
          </b-col>
        </b-row>
        <hr />
        <p class="m-0 text-center">
          <a href="https://bforcure.atlassian.net/wiki/x/N4BRBg" target="_blank" class="text-dark">Need more help?</a>
        </p>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import axios from "axios";

export default {
  name: "LoginForm",
  props: ["app_version"],
  computed: {
    error_oidc: function () {
      return this.$route.query.error;
    },
  },
  data: function () {
    return {
      login: false,
      error: false,
      error_message: "",
      email: "",
      password: "",
      password_type: "password",
      oidc_login: undefined,
    };
  },
  methods: {
    async Login(event) {
      event.preventDefault();
      this.error = false;
      this.login = true;
      try {
        await axios
          .post("/api/v1/user/login", { email: this.email, password: this.password })
        if (this.$route.query.dest !== undefined) {
          this.$router.push({ path: this.$route.query.dest });
        } else {
          this.$router.push({ name: "Dashboard" });
        }
      }
      catch (error) {
        this.error = true;
        this.error_message = error.response.data.detail;
      }
      this.login = false;
    },
    async OIDCLogin() {
      try {
        var oidc_login = (await axios
          .post("/api/v1/user/login")).data
        oidc_login += "&state=" + btoa(JSON.stringify(this.$route.query));
        this.oidc_login = oidc_login;
      }
      catch {
        this.oidc_login = undefined
      }
    }
  },
  created: function () {
    this.OIDCLogin();
  },
};
</script>
