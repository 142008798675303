export function omit(source, keys) {
  const keysAsStrings = keys.map(String);

  return Object.keys(source).reduce(
    (updated, key) =>
      !keysAsStrings.includes(key)
        ? Object.assign(updated, {
          [key]: source[key],
        })
        : updated,
    {}
  );
}
