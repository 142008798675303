<template>
  <b-form>
    <b-form-group label="Plot type" label-for="plot_type" label-cols-xl="3">
      <div class="plot-type-options">
        <b-form-select :options="plotTypes" :value="value.plotType" @change="notifyTypeInput"></b-form-select>
        <template v-if="isBoxPlot">
          <b-form-checkbox switch :checked="value.boxPoints" @change="notifyBoxPointsInput"><span
              class="plot-type-option-label">view points</span></b-form-checkbox>
          <b-form-checkbox switch :checked="value.boxNotches" @change="notifyBoxNotchesInput"><span
              class="plot-type-option-label">view notches</span></b-form-checkbox>
        </template>
      </div>
    </b-form-group>
    <b-form-group label="X axis" label-for="x_axis" label-cols-xl="3">
      <b-form-select :options="xAxisOptions.filter(ele => !ele.startsWith('HEALTH_MESSAGE_'))" :value="value.xAxis"
        @change="notifyXAxisInput"></b-form-select>
    </b-form-group>
    <b-form-group :label="yAxisLabel" label-for="y_axis" label-cols-xl="3">
      <b-form-select :options="yAxisLimitedOptions.filter(ele => !ele.startsWith('HEALTH_MESSAGE_'))"
        :value="value.yAxis" :select-size="yAxisSelectSize" :multiple="multipleYAxis"
        @change="notifyYAxisInput"></b-form-select>
    </b-form-group>
  </b-form>
</template>

<style scoped>
.plot-type-options {
  /* making sure that children z-index are scoped to this container (e.g. switch checkboxe element) */
  z-index: 0;

  position: relative;
  display: flex;
  align-items: center;
}

.plot-type-options>*:not(:first-child) {
  margin-left: 1em;
}

.plot-type-option-label {
  white-space: nowrap;
  font-size: 0.8em;
  display: inline-block;
}

.plot-type-option-label::first-letter {
  text-transform: uppercase;
}
</style>

<script>
import { plotTypes, BOX_PLOT_TYPE } from "../../constants/plot";

const boxPlotParams = ["boxPoints", "boxNotches"];

export default {
  name: "PlotConfig",

  props: {
    xAxisOptions: {
      type: Array,
      required: true,
    },

    yAxisOptions: {
      type: Array,
      required: true,
    },

    multipleYAxis: Boolean,

    yAxisLimit: {
      type: Number,
      default: 1,
    },

    value: {
      type: Object,
      required: true,

      validator(value) {
        const areMainParamsSetWithRightType =
          typeof value?.plotType === "string" &&
          typeof value?.xAxis === "string" &&
          (typeof value?.yAxis === "string" || Array.isArray(value?.yAxis))

        const isntBoxPlotType = value?.plotType !== BOX_PLOT_TYPE;

        const areSetBoxPlotParamsOfRightType = boxPlotParams.every(
          (param) =>
            !(param in (value ?? {})) || typeof value[param] === "boolean"
        );

        return (
          areMainParamsSetWithRightType &&
          (isntBoxPlotType || areSetBoxPlotParamsOfRightType)
        );
      },
    },
  },

  computed: {
    isBoxPlot() {
      return this.value.plotType === BOX_PLOT_TYPE;
    },

    yAxisSelectSize() {
      return this.multipleYAxis ? Math.max(5, this.value.yAxis.length + 2) : 1;
    },

    yAxisLimitedOptions() {
      if (this.isYAxisLimitReached) {
        return this.yAxisOptions.map(
          option => this.value.yAxis.includes(option)
            ? option
            : {
              value: option,
              text: option,
              disabled: true
            }
        )
      }

      return this.yAxisOptions;
    },

    isYAxisLimitReached() {
      return this.multipleYAxis && this.value.yAxis.length === this.yAxisLimit
    },

    yAxisLabel() {
      let label = "Y axis"

      if (this.isYAxisLimitReached) {
        label += ` (maximum of ${this.yAxisLimit} reached)`
      }

      return label
    }
  },

  methods: {
    notifyTypeInput(type) {
      this.notifyInput("plotType", type);
    },

    notifyXAxisInput(xAxis) {
      this.notifyInput("xAxis", xAxis);
    },

    notifyYAxisInput(yAxis) {
      this.notifyInput(
        "yAxis",
        Array.isArray(yAxis) ? yAxis.slice(0, this.yAxisLimit) : yAxis
      );
    },

    notifyBoxPointsInput(arePointsNeeded) {
      this.notifyInput("boxPoints", arePointsNeeded);
    },

    notifyBoxNotchesInput(areNotchesNeeded) {
      this.notifyInput("boxNotches", areNotchesNeeded);
    },

    notifyInput(param, value) {
      this.$emit("input", { ...this.value, [param]: value });
    },
  },

  created() {
    this.plotTypes = plotTypes;
  },
};
</script>
