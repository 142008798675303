<template>
  <div>
    <b-container fluid>
      <h3>
        Machines accesses
        <span v-if="selected_group !== null">for <strong>{{ selected_group.name }}</strong></span>
      </h3>
      <div v-if="locked_machines.length > 0">
        <hr />
        <b-alert :show="locked_machines.length > 0" variant="danger">
          <span v-if="locked_machines.length > 1">Accesses for {{ locked_machines.length }} machines are still
            locked.</span>
          <span v-else>Access for 1 machine is still locked.</span>
        </b-alert>
        <b-alert :show="error_unlock !== undefined" class="mb-2" variant="danger" dismissible>{{ error_unlock
          }}</b-alert>
        <b-button v-for="locked_machine of locked_machines" :key="locked_machine" variant="primary"
          @click="showUnlock(locked_machine)" block class="mb-2"><b-icon icon="unlock" class="mr-1"></b-icon>Unlock
          machine
          <strong>{{ locked_machine }}</strong></b-button>
      </div>
      <b-button-toolbar v-if="selected_group !== null">
        <b-button-group class="mx-1">
          <b-pagination v-model="current_page" :total-rows="infos.length" :per-page="per_page"
            :disabled="fetching"></b-pagination>
        </b-button-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-select :options="filter_machines" v-model="search" @input="filterInfos">
            <template #first>
              <option value="">Search by machine...</option>
            </template>
          </b-form-select>
        </b-input-group>
        <b-button-group class="mx-1">
          <b-button @click="fetchInfos()" class="border"><b-icon icon="arrow-clockwise"
              class="mr-1"></b-icon>Reload</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="success" @click="showEdit(true)" class="border"><b-icon icon="plus"
              class="mr-1"></b-icon>Add</b-button>
          <b-button variant="success" class="border" @click="showEdit(true, true)" v-if="selected != null"><b-icon
              icon="files" class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-button-group class="mx-1" v-if="selected != null">
          <b-button variant="primary" @click="showEdit(false)" class="border"><b-icon icon="pen"
              class="mr-1"></b-icon>Edit</b-button>
          <b-button variant="danger" @click="show_delete = true" class="border"><b-icon icon="trash"
              class="mr-1"></b-icon>Delete</b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-table :items="infos" striped hover bordered :busy="fetching" :fields="fields.filter((ele) => !ele.notdisplay)"
        class="text-nowrap" show-empty sort-by="machine_name" sticky-header="500px" :per-page="per_page"
        :current-page="current_page" selectable select-mode="single" @row-selected="onRowSelected">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell()="data">
          <span v-if="isEverything(data)"><i>Everything</i></span>
          <span v-else-if="data.field.bool">{{
            data.value ? "Yes" : "No"
            }}</span>
          <span v-else>{{ data.value }}</span>
        </template>
      </b-table>
    </b-container>
    <b-modal v-model="show_edit" title="Access editor" hide-footer size="lg">
      <b-overlay :show="saving || fetching_machines">
        <div v-if="edit !== null">
          <b-form-group v-for="field in fields.filter((field) => !field.notedit)" :key="field.key" :label="field.label"
            :label-for="field.key" label-cols-xl="3">
            <span v-if="field.date">
              <b-form-datepicker :id="field.key" v-model="edit[field.key]" :max="edit['to_date']"
                v-if="field.key == 'from_date' && !edit[field.key + '_select_all']" today-button
                :state="notEmpty(field)"></b-form-datepicker>
              <b-form-datepicker :id="field.key" v-model="edit[field.key]" :min="edit['from_date']" v-else-if="
                field.key == 'to_date' && !edit[field.key + '_select_all']
              " today-button :state="notEmpty(field)"></b-form-datepicker>
            </span>
            <span v-else-if="
              field.key == 'machine_id' && !edit[field.key + '_select_all']
            ">
              <b-form-select :options="machine_names" v-model="edit[field.key]"
                v-show="!edit[field.key + '_select_all']" :state="notEmpty(field)" :disabled="edit_form">
                <template #first>
                  <option value="">{{ field.desc }}</option>
                </template>
              </b-form-select>
            </span>
            <b-form-input :id="field.key" v-else-if="!edit[field.key + '_select_all']" type="text"
              :placeholder="field.desc" v-model="edit[field.key]" v-show="!edit[field.key + '_select_all']"
              :state="notEmpty(field)"></b-form-input>
            <b-form-checkbox v-model="edit[field.key + '_select_all']" @input="setFieldValue(field.key)"
              :id="field.key + '_select_all'" :disabled="field.key == 'machine_id' && edit_form" switch>Select
              all</b-form-checkbox>
          </b-form-group>
          <hr />
          <b-row class="text-center text-danger" v-if="error != null"><b-col cols="12"><strong>{{ error
                }}</strong></b-col></b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <b-button @click="show_edit = false" variant="danger" class="mx-1"><b-icon icon="x"
                  class="mr-1"></b-icon>Cancel</b-button>
              <b-button variant="primary" class="mx-1" :disabled="checkState()" @click="saveItem"><b-icon icon="check"
                  class="mr-1"></b-icon>Save</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal v-model="show_delete" title="Delete access" hide-footer>
      <b-overlay :show="saving">
        <p class="text-center">
          Are you sure to delete this access? This action is irreversible.
        </p>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="show_delete = false" variant="danger" class="mx-1"><b-icon icon="x"
                class="mr-1"></b-icon>Cancel</b-button>
            <b-button variant="primary" class="mx-1" @click="deleteItem"><b-icon icon="check"
                class="mr-1"></b-icon>OK</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
    <b-modal v-model="show_unlock" title="Unlock machine access" hide-footer>
      <b-overlay :show="saving">
        <p class="text-center">
          Are you sure to unlock this machine access and remove the MFA
          procedure?
        </p>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="show_unlock = false" variant="danger" class="mx-1"><b-icon icon="x"
                class="mr-1"></b-icon>Cancel</b-button>
            <b-button variant="primary" class="mx-1" @click="unlockMachine"><b-icon icon="check"
                class="mr-1"></b-icon>OK</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { runAllInBatches } from "../../utils/async";
const CancelToken = axios.CancelToken;

export default {
  name: "AccessesTable",
  props: ["selected_group"],
  data: function () {
    return {
      infos_base: [],
      infos: [],
      fetching: false,
      selected: null,
      fields: [
        {
          key: "machine_name",
          label: "Machine name",
          notedit: true,
        },
        {
          key: "machine_id",
          label: "Machine name",
          notnull: true,
          desc: "Enter the machine name",
          notdisplay: true,
        },
        {
          key: "from_date",
          label: "From",
          date: true,
          notnull: true,
        },
        {
          key: "to_date",
          label: "To",
          date: true,
          notnull: true,
        },
        {
          key: "locked",
          label: "Locked",
          bool: true,
          notedit: true,
        },
        {
          key: "token",
          label: "Token",
          notedit: true,
        },
      ],
      source: CancelToken.source(),
      per_page: 50,
      current_page: 1,
      show_edit: false,
      edit_form: false,
      edit: null,
      error: null,
      saving: false,
      show_delete: false,
      fetching_machines: false,
      machine_names: [],
      filter_machines: [],
      search: "",
      locked_machines: [],
      show_unlock: false,
      error_unlock: undefined,
      locked_machine: undefined,
    };
  },
  created: function () {
    this.fetchMachines();
  },
  watch: {
    selected_group: function () {
      if (this.selected_group !== null) {
        this.fetchInfos();
      } else {
        this.infos = [];
      }
    },
  },
  methods: {
    async fetchMachine(machine_id) {
      return (await axios.get("/api/v1/machine/info", {
        params: { machine_id: machine_id }
      })).data
    },
    async fetchMachines() {
      this.fetching_machines = true
      const machine_ids = (await axios
        .get("/api/v1/machine/ids")).data
      var fetches = []
      for (let machine_id of machine_ids) {
        fetches.push(() => this.fetchMachine(machine_id))
      }
      const machine_names = (await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })).map(
        (ele) => {
          return {
            value: ele.id,
            text: ele.name,
          };
        }
      )
      machine_names.sort((ele1, ele2) => {
        if (ele1.text < ele2.text) {
          return -1;
        } else {
          return 1;
        }
      });
      this.machine_names = machine_names
      this.fetching_machines = false
    },
    async fetchInfo(id) {
      try {
        return (await axios.get("/api/v1/access/info", {
          params: {
            access_id: id
          }
        })).data
      }
      catch (error) {
        console.error("Could not get item info")
      }
    },
    async fetchInfos() {
      this.locked_machines = [];
      this.fetching = true;
      this.infos_base = this.infos = []
      const item_ids = (await axios
        .get("/api/v1/group/accesses", {
          params: { group_name: this.selected_group.name },
        })).data
      var fetches = []
      for (let id of item_ids) {
        fetches.push(() => this.fetchInfo(id))
      }
      this.infos_base = this.infos = await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })
      this.filter_machines = this.infos_base.filter(
        ele => ele.machine_name !== null).map((ele) => ele.machine_name);
      this.locked_machines = [
        ...new Set(
          this.infos_base
            .filter((ele) => ele.locked)
            .map((ele) => ele.machine_name)
        ),
      ];
      this.fetching = false;
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.selected = items[0];
      } else {
        this.selected = null;
      }
    },
    showEdit(newItem, copy = false) {
      this.edit_form = false;
      var edit = {};
      this.fields.forEach((field) => (field.disabled = false));
      var fields = this.fields.filter((field) => !field.notedit);
      if (newItem) {
        for (let field of fields) {
          if (copy) {
            if (this.selected[field.key] !== null) {
              edit[field.key] = this.selected[field.key];
            } else {
              edit[field.key] = null;
              edit[field.key + "_select_all"] = true;
            }
          } else {
            edit[field.key] = "";
          }
        }
      } else {
        for (let field of fields.filter((field) => !field.notedit)) {
          if (this.selected[field.key] !== null) {
            edit[field.key] = this.selected[field.key];
          } else {
            edit[field.key] = null;
            edit[field.key + "_select_all"] = true;
          }
          edit.id = this.selected.id;
        }
        this.edit_form = true;
      }
      this.edit = edit;
      this.error = null;
      this.show_edit = true;
    },
    notEmpty(field) {
      var valid = true;
      if (field.notnull) {
        valid = this.edit[field.key].toString().length > 0;
      }
      return valid;
    },
    checkState() {
      var valid = true;
      for (let field of this.fields) {
        if (field.notnull) {
          if (this.edit[field.key] !== null) {
            if (this.edit[field.key].toString().length == 0) {
              valid = false;
            }
          }
        }
      }
      return !valid;
    },
    async addItem() {
      var edit = {};
      Object.keys(this.edit)
        .filter((ele) => this.fields.map((ele2) => ele2.key).includes(ele))
        .forEach((key) => {
          edit[key] = this.edit[key];
        });
      try {
        await axios
          .post("/api/v1/access/add", {
            group_name: this.selected_group.name,
            machine_id: edit.machine_id,
            from_date: edit.from_date,
            to_date: edit.to_date,
          })
        this.show_edit = false;
        this.fetchInfos();
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail.replace(/:.+/, "");
        } else {
          this.error = "Internal server error.";
        }
      }
      this.saving = false
    },
    async editItem() {
      try {
        await axios
          .post("/api/v1/access/edit", {
            id: this.edit.id,
            from_date: this.edit.from_date,
            to_date: this.edit.to_date,
          })
        this.show_edit = false;
        this.fetchInfos();
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail.replace(/:.+/, "");
        } else {
          this.error = "Internal server error.";
        }
      }
      this.saving = false
    },
    async saveItem() {
      this.saving = true;
      this.error = null;
      if (this.edit_form) {
        await this.editItem();
      } else {
        await this.addItem();
      }
    },
    async deleteItem() {
      this.saving = true;
      await axios
        .delete("/api/v1/access/delete", {
          params: { access_id: this.selected.id },
        })
      this.saving = false;
      this.show_delete = false;
      this.fetchInfos();
    },
    setFieldValue(field_key) {
      if (this.edit[field_key + "_select_all"]) {
        this.edit[field_key] = null;
      } else {
        this.edit[field_key] = "";
      }
    },
    filterInfos() {
      if (
        this.search.length !== 0 &&
        this.infos_base.filter((ele) => ele.machine_name === null).length == 0
      ) {
        this.infos = this.infos_base.filter(
          (ele) => ele.machine_name == this.search
        );
      } else {
        this.infos = this.infos_base;
      }
    },
    showUnlock(locked_machine) {
      this.locked_machine = locked_machine;
      this.show_unlock = true;
    },
    async unlockMachine() {
      this.error_unlock = undefined;
      this.saving = true;
      var token = this.infos_base.find(
        (ele) => ele.machine_name == this.locked_machine
      ).token;
      try {
        const response_data = (await axios
          .post("/api/v1/access/unlock", {
            token: token,
            group_name: this.selected_group.name,
          })).data
        if (response_data.length == 0) {
          this.error_unlock =
            "Error for machine " + this.locked_machine + ": wrong token";
        } else {
          this.show_unlock = false;
          this.fetchInfos();
        }
      }
      catch (error) {
        var error_unlock = "Error for machine " + this.locked_machine + ": ";
        if (error.response.data.detail != undefined) {
          error_unlock += error.response.data.detail;
        } else {
          error_unlock += "Internal server error";
        }
        this.error_unlock = error_unlock;
      }
      this.saving = false
    },
    isEverything(data) {
      var is_everything = false;
      if (data.field.key !== "token") {
        is_everything = data.value.length == 0;
      }
      return is_everything;
    },
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>