<template>
  <div class="d-inline-block">
    <b-button @click="showInfos()" size="sm" class="p-0" variant="primary" title="Show run information"><b-icon
        icon="info"></b-icon></b-button>
    <b-modal v-model="show_infos" hide-footer size="lg" title="Run informations">
      <b-button-group size="sm" class="mb-1 mr-1" v-if="run_datas.fluos != {}">
        <b-button @click="downloadFluorescence()" title="Download raw fluorescence data"><b-icon icon="download"
            class="mr-1"></b-icon>Raw data</b-button>
      </b-button-group>
      <b-button-group size="sm" class="mb-1 mr-1" v-if="run_datas.analysis !== undefined &&
        JSON.stringify(run_datas.analysis.data) !== JSON.stringify({})
      ">
        <b-button @click="downloadProcessed()" title="Download processed curves"><b-icon icon="download"
            class="mr-1"></b-icon>Processed
          data</b-button>
      </b-button-group>
      <b-button-group size="sm" class="mb-1 mr-1" v-if="run_datas.analysis !== undefined &&
        JSON.stringify(run_datas.analysis.data) !== JSON.stringify({})">
        <b-button @click="downloadSigmoid()" title="Download sigmoid fitting curves"><b-icon icon="download"
            class="mr-1"></b-icon>Sigmoid
          fitting</b-button>
      </b-button-group>
      <b-button-group size="sm" class="mb-1 mr-1" v-if="isArchive">
        <b-button :href="runDataZipUrl" :download="runDataZipFilename" :disabled="run_data_disabled"
          title="Download run data in a zip file" @click="downloadRunData()"><b-icon icon="download"
            class="mr-1"></b-icon>{{ run_data_downloadBtnLabel }}</b-button>
      </b-button-group>
      <b-tabs>
        <b-tab title="Main information">
          <b-card>
            <b-table :items="[run_datas.run_infos]" :fields="fields" small responsive bordered stacked></b-table>
            <b-container v-if="run_datas.run_infos.note.length > 0" class="mt-2 p-1 border"
              style="border-width: 2px !important">
              <b-row><b-col><strong>Notes:</strong></b-col></b-row>
              <b-row>
                <b-col>
                  <pre class="m-2 bg-light p-2 border rounded">{{
                    run_datas.run_infos.note
                  }}</pre>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-tab>
        <b-tab title="Setup" v-if="run_datas.run_infos.setup !== null">
          <b-button-group size="sm" class="mt-2 mb-2 full-width">
            <b-button @click="downloadSetup()" title="Download setup in a JSON file"><b-icon icon="download"
                class="mr-1"></b-icon>Download JSON file</b-button>
          </b-button-group>
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle href="#setup_accordion-main" variant="info">Main information
                </b-button>
              </b-card-header>
              <b-collapse id="setup_accordion-main" visible accordion="setup-accordion" role="tabpanel">
                <b-card-body>
                  <b-table :items="[getSetupInfos()]" small responsive bordered stacked></b-table>
                </b-card-body>
              </b-collapse>
            </b-card>
            <b-card no-body class="mb-1" v-for="(setup_title, setup_index) in Object.keys(
              getSetupInfos(true)
            )" :key="setup_title">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle :href="'#setup_accordion-' + setup_index" variant="info">{{
                  setup_title
                    .replace("_", " ")
                    .replace(/^\w/, (c) => c.toUpperCase())
                }}</b-button>
              </b-card-header>
              <b-collapse :id="'setup_accordion-' + setup_index" accordion="setup-accordion" role="tabpanel">
                <b-card-body>
                  <b-table :items="[
                    run_datas.run_infos.setup[setup_title]]" small responsive bordered stacked v-if="JSON.stringify(
                      run_datas.run_infos.setup[setup_title]) !==
                      JSON.stringify({})
                    ">
                    <template #cell()="data">
                      <template v-if="isNotObject(data.value)">
                        {{ data.value }}
                      </template>
                      <template v-else>
                        <ul>
                          <li v-for="(value1, key1) in data.value" :key="key1">
                            <strong>{{ key1 }}: </strong>
                            <span v-if="isNotObject(value1)">{{ value1 }}</span>
                            <ul v-else>
                              <li v-for="(value2, key2) in value1" :key="key2">
                                <strong>{{ key2 }}: </strong>
                                <span v-if="isNotObject(value2)">{{
                                  value2
                                  }}</span>
                                <ul v-else>
                                  <li v-for="(value3, key3) in value2" :key="key3">
                                    <strong>{{ key3 }}: </strong>
                                    <span v-if="isNotObject(value3)">{{
                                      value3
                                      }}</span>
                                    <ul v-else>
                                      <li v-for="(value4, key4) in value3" :key="key4">
                                        <strong>{{ key4 }}: </strong>
                                        {{ value4 }}
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </template>
                    </template>
                  </b-table>
                  <p class="text-secondary text-center m-0" v-else>
                    No information to show
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-tab>
        <b-tab title="Environment" v-if="run_datas.run_infos.environment !== null">
          <div class="accordion" role="tablist">
            <b-card no-body class="mb-1" v-for="(env_title, env_index) in Object.keys(run_datas.run_infos.environment)"
              :key="env_title">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle :href="'#env_accordion-' + env_index" variant="info">{{
                  env_title
                    .replace("_", " ")
                    .replace(/^\w/, (c) => c.toUpperCase())
                }}</b-button>
              </b-card-header>
              <b-collapse :id="'env_accordion-' + env_index" :visible="env_index == 0" accordion="env-accordion"
                role="tabpanel">
                <b-card-body>
                  <b-table :items="[
                    run_datas.run_infos.environment[env_title]]" small responsive bordered stacked v-if="JSON.stringify(
                      run_datas.run_infos.environment[env_title]) !== JSON.stringify({})
                    ">
                  </b-table>
                  <p class="text-secondary text-center m-0" v-else>
                    No information to show
                  </p>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </b-tab>
        <b-tab title="Batch information" v-if="batch_infos !== null">
          <b-card>
            <b-table :items="[batch_infos]" :fields="batch_fields" small responsive bordered stacked>
              <template #cell()="data">
                <span v-if="data.field.bool">{{
                  data.value ? "Yes" : "No"
                  }}</span>
                <span v-else>{{ data.value }}</span>
              </template>
            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "RunInfos",
  props: [
    "run_datas",
    "infos_fields",
    "batch_fields",
    "batches_infos",
    "isArchive",
  ],
  computed: {
    fields: function () {
      return this.infos_fields.filter((ele) => ele.key !== "selected");
    },
  },
  data: function () {
    return {
      show_infos: false,
      batch_infos: null,
      run_data_downloadBtnLabel: "Run data",
      run_data_disabled: false,
      runDataZipUrl: "",
      runDataZipFilename: ""
    };
  },
  watch: {
    run_datas: function () {
      this.getRunZipUrl();
      this.getBatchInfos();
    }
  },
  methods: {
    async getRunZipUrl() {
      if (this.isArchive) {
        const machine_name = (await axios.get("/api/v1/machine/info", {
          params: {
            machine_id: this.run_datas.run_infos.machine_id
          }
        })).data.orig_name
        this.runDataZipUrl = (
          "/api/v1/archives/run?machine_name=" +
          encodeURIComponent(machine_name) + "&ananke_id=" +
          encodeURIComponent(this.run_datas.run_infos.ananke_id)
        );
        this.runDataZipFilename = `run_data_${machine_name}_${this.run_datas.run_infos.ananke_id}.zip`
      }
    },
    showInfos() {
      this.show_infos = true;
      this.run_data_disabled = false;
      this.run_data_downloadBtnLabel = "Run data";
    },
    getBatchInfos() {
      var vm = this;
      vm.batch_infos = vm.batches_infos
        ? vm.batches_infos.filter(
          (elem) => elem.id == vm.run_datas.run_infos.batch_id
        )[0]
        : [];
      if (!vm.batch_infos) vm.batch_infos = null;
    },
    getSetupInfos(exclude = false) {
      var setup_infos = {};
      for (let setup_key in this.run_datas.run_infos.setup) {
        if (this.isNotObject(this.run_datas.run_infos.setup[setup_key])) {
          if (!exclude) {
            setup_infos[setup_key] = this.run_datas.run_infos.setup[setup_key];
          }
        } else {
          if (exclude) {
            setup_infos[setup_key] = this.run_datas.run_infos.setup[setup_key];
          }
        }
      }
      return setup_infos;
    },
    isNotObject(value) {
      return typeof value !== "object" || Array.isArray(value);
    },
    downloadCSV(array_data, prefix) {
      var csv_content =
        'data:text/csv;charset=utf-8,"' +
        array_data.map((ele) => ele.join('","')).join('"\n"') +
        '"';
      var encoded_uri = encodeURI(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        prefix +
        this.run_datas.run_infos.machine_name +
        "_" +
        this.run_datas.run_infos.ananke_id +
        "_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
    downloadFluorescence() {
      var fluo_lengths = Object.entries(this.run_datas.fluos).map(
        (data_entry) => data_entry[1].length
      );
      var max_length = Math.max(...fluo_lengths);
      var leds = Object.entries(this.run_datas.fluos).map(
        (data_entry) => data_entry[0]
      );
      var chambers = Object.entries(this.run_datas.fluos[leds[0]][0].fluo).map(
        (led_entry) => led_entry[0]
      );
      var column_names = [];
      leds.forEach((led) => {
        column_names.push(
          "timestamp_" + led,
          "abs_timestamp_" + led,
          "side_" + led,
          "cycle_" + led
        );
        chambers.forEach((chamber) => {
          column_names.push("fluo_" + led + "_" + chamber);
        });
      });
      var rows = [];
      for (let row_index = 0; row_index < max_length; row_index++) {
        var new_row = [];
        leds.forEach((led) => {
          var led_datas = this.run_datas.fluos[led];
          if (row_index < led_datas.length) {
            let led_data = led_datas[row_index];
            new_row.push(
              led_data.timestamp,
              led_data.abs_timestamp,
              led_data.side,
              led_data.cycle
            );
            chambers.forEach((chamber) => {
              new_row.push(led_data.fluo[chamber]);
            });
          } else {
            new_row.push(...Array(4 + chambers.length).fill(""));
          }
        });
        rows.push(new_row);
      }
      this.downloadCSV([column_names].concat(rows), "gaia_run_fluo_");
    },
    downloadProcessed() {
      var cycles = [
        ...new Set(
          Object.entries(this.run_datas.analysis.data).flatMap((data_entry) => {
            return Object.entries(data_entry[1]).flatMap(
              (led_entry) => led_entry[1].cycles
            );
          })
        ),
      ];
      cycles.sort((a, b) => a - b);
      var leds = Object.entries(this.run_datas.analysis.data).map(
        (data_entry) => data_entry[0]
      );
      var chambers = Object.entries(this.run_datas.analysis.data[leds[0]]).map(
        (led_entry) => led_entry[0]
      );
      var column_names = ["cycle"];
      leds.forEach((led) => {
        chambers.forEach((chamber) => {
          column_names.push("processed_curve_" + led + "_" + chamber);
        });
      });
      var rows = [];
      cycles.forEach((cycle) => {
        var new_row = [cycle];
        leds.forEach((led) => {
          chambers.forEach((chamber) => {
            var chamber_data = this.run_datas.analysis.data[led][chamber];
            var cycle_index = chamber_data.cycles.indexOf(cycle);
            if (cycle_index >= 0) {
              new_row.push(chamber_data.processed[cycle_index]);
            } else {
              new_row.push("");
            }
          });
        });
        rows.push(new_row);
      });
      this.downloadCSV([column_names].concat(rows), "gaia_run_processed_");
    },
    downloadSigmoid() {
      var linspace = [
        ...new Set(
          Object.entries(this.run_datas.analysis.data).flatMap((data_entry) => {
            return Object.entries(data_entry[1]).flatMap(
              (led_entry) => led_entry[1].linspace
            );
          })
        ),
      ];
      linspace.sort((a, b) => a - b);
      var leds = Object.entries(this.run_datas.analysis.data).map(
        (data_entry) => data_entry[0]
      );
      var chambers = Object.entries(this.run_datas.analysis.data[leds[0]]).map(
        (led_entry) => led_entry[0]
      );
      var column_names = ["linspace"];
      leds.forEach((led) => {
        chambers.forEach((chamber) => {
          column_names.push(
            "fit_" + led + "_" + chamber
          );
        });
      });
      var rows = [];
      linspace.forEach((val) => {
        var new_row = [val];
        leds.forEach((led) => {
          chambers.forEach((chamber) => {
            var chamber_data = this.run_datas.analysis.data[led][chamber];
            var x2_index = chamber_data.linspace.indexOf(val);
            if (x2_index >= 0) {
              new_row.push(
                chamber_data.fit[x2_index],
              );
            } else {
              new_row.push(...Array(2).fill(""));
            }
          });
        });
        rows.push(new_row);
      });
      this.downloadCSV([column_names].concat(rows), "gaia_run_sigmoid_");
    },
    downloadRunData() {
      this.run_data_downloadBtnLabel = "Download will start shortly... ";
      this.run_data_disabled = true;
    },
    downloadSetup() {
      const json = JSON.stringify(this.run_datas.run_infos.setup, null, 4);
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      const now = new Date();
      a.href = url;
      a.download = "protocol_" +
        this.run_datas.run_infos.machine_name + "_" +
        this.run_datas.run_infos.ananke_id + "_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
  },
  created: function () {
    this.getRunZipUrl();
    this.getBatchInfos();
  },
};
</script>

<style scoped>
.table-responsive {
  margin: 0 !important;
}

table {
  margin: 0 !important;
}

.no-data {
  margin-top: 40%;
}

.full-width {
  width: 100%
}
</style>