<template>
  <small>
    <b-button-group size="sm" class="ml-1">
      <b-button @click="downloadCSVData" class="border"
        ><b-icon icon="download" class="mr-1"></b-icon>Download</b-button
      >
      <b-button @click="copyCSVData" class="border"
        ><b-icon icon="files" class="mr-1"></b-icon>Copy</b-button
      >
    </b-button-group>
  </small>
</template>

<script>
export default {
  name: "DataExporter",

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  methods: {
    downloadCSVData() {
      const csv_content = this.exportDataToCSV();
      const encoded_uri =
        encodeURI("data:text/csv;charset=utf-8,") +
        encodeURIComponent(csv_content);
      const link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      const now = new Date();
      const fileName =
        "gaia_advanced_data_viz_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    copyCSVData() {
      const total_secs = 5;
      const vm = this;
      const csv_content = vm.exportDataToCSV();

      navigator.clipboard
        .writeText(csv_content)
        .finally(() => (vm.dismiss_countdown = total_secs));
    },

    exportDataToCSV() {
      var column_names = Object.keys(this.data[0]);
      var rows = this.data.map((row) => {
        var row_data = [];
        column_names.forEach((column_name) => {
          if (Object.keys(row).includes(column_name)) {
            row_data.push(row[column_name]);
          } else {
            row_data.push("");
          }
        });
        return row_data;
      });
      var array_data = [column_names].concat(rows);
      var csv_content =
        '"' + array_data.map((ele) => ele.join('","')).join('"\n"') + '"';
      return csv_content;
    },
  },
};
</script>

