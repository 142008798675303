export function formatDate(date, withTime = false) {
  const formatedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-')
  const formatedTime = withTime ? ' ' + [date.getHours(), date.getMinutes(), date.getSeconds()].join(':') : ''
  return formatedDate + formatedTime
}

export function formatTimestamp(timestamp, withTime = false) {
  return formatDate(new Date(timestamp), withTime);
}

export function formatTimeDiff(timestamp) {
  const SECOND = 1000
  const MINUTE = 60 * SECOND
  const HOUR = 60 * MINUTE
  const DAY = HOUR * 24

  const days = Math.floor(timestamp / DAY)
  const hours = Math.floor((timestamp % DAY) / HOUR)
  const minutes = Math.floor(((timestamp % DAY) % HOUR) / MINUTE)
  const seconds = Math.floor((((timestamp % DAY) % HOUR) % MINUTE) / SECOND)

  return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

const datePattern = /^\d{4}(-\d{1,2}){2}( +\d{1,2}(:\d{1,2}){2}(\.\d+)?)?$/

export function isDateString(dateString) {
  return dateString?.match?.(datePattern)
}
