<template>
  <div>
    <h4>
      Search parameters
      <small><help-view>Enter here your GAIA search options.<br />
          The results list is updated each time you change the
          options.</help-view></small>
    </h4>
    <b-overlay :show="fetching_filters">
      <div class="search_runs">
        <b-form-group v-if="group_names" label="Groups" label-for="select-group" label-cols-xl="3" class="mt-1 mb-0">
          <b-form-select id="select-group" size="sm" :disabled="!isAdmin || fetching" :options="group_names"
            v-model="selected_group">
            <template #first>
              <option :value="undefined">All</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group label="Experiment name" label-for="text-name" label-cols-xl="3" class="mt-1 mb-0">
          <b-form-input id="text-name" type="text" v-model="experimentname" size="sm" :placeholder="search_placeholder"
            :disabled="fetching" @change="startRunsFetches()"></b-form-input>
          <b-popover target="text-name" variant="primary" triggers="hover" placement="top">
            Hit enter (or leave field) to apply
          </b-popover>
        </b-form-group>
        <b-form-group label="" label-for="select-regexp_search" label-cols-xl="3">
          <b-form-checkbox v-model="regexp_search" id="select-regexp_search" @change="startRunsFetches()" size="sm" switch
            :disabled="fetching">Use regular expression</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Chronos user" label-for="text-user" label-cols-xl="3" class="mt-1 mb-0">
          <b-form-input id="text-user" type="text" v-model="anankeuser" size="sm"
            placeholder="Chronos user name (use * for wildcards)" @change="startRunsFetches()"
            :disabled="fetching"></b-form-input>
          <b-popover target="text-user" variant="primary" triggers="hover" placement="top">
            Hit enter (or leave field) to apply
          </b-popover>
        </b-form-group>
        <b-form-group label="Machine versions" label-for="select-versions" label-cols-xl="3">
          <b-form-tags v-model="versions" size="md" class="mb-2" add-on-change no-outer-focus :disabled="fetching">
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-0 border rounded w-100 p-1">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled">{{ tag }}</b-form-tag>
                </li>
              </ul>
              <b-form-select id="select-versions" v-bind="inputAttrs" v-on="inputHandlers" size="sm" :disabled="disabled ||
                machine_infos
                  .map((ele) => ele.version)
                  .filter((opt) => versions.indexOf(opt) === -1).length === 0
                " :options="machine_infos
    .map((ele) => ele.version)
    .filter((ele, index, self) => self.indexOf(ele) === index)
    .sort()
    .filter((opt) => versions.indexOf(opt) === -1)
    ">
                <template #first>
                  <option disabled value="">Choose a version...</option>
                </template>
              </b-form-select>
              <b-button size="sm" block class="mt-1" variant="danger" @click="versions = []" v-if="versions.length > 0"
                :disabled="fetching"><b-icon icon="trash" class="mr-1"></b-icon>Remove all</b-button>
              <b-button size="sm" block class="mt-1" @click="
                versions = machine_infos
                  .map((ele) => ele.version)
                  .filter((ele, index, self) => self.indexOf(ele) === index)
                " :disabled="fetching" v-else><b-icon icon="plus-circle" class="mr-1"></b-icon>Add
                all</b-button>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Machines" label-for="select-machines" label-cols-xl="3">
          <b-form-tags v-model="machines" size="md" class="mb-2" add-on-change no-outer-focus :disabled="fetching">
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-0 border rounded w-100 p-1">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled">{{ tag }}</b-form-tag>
                </li>
              </ul>
              <b-form-select id="select-machines" v-bind="inputAttrs" v-on="inputHandlers" size="sm" :disabled="disabled ||
                machine_infos
                  .filter((ele) => versions.includes(ele.version))
                  .map((ele) => ele.name)
                  .filter((opt) => machines.indexOf(opt.text) === -1)
                  .length === 0
                " :options="machine_infos
    .filter((ele) => versions.includes(ele.version))
    .map((ele) => ele.name)
    .sort()
    .filter((opt) => machines.indexOf(opt) === -1)
    ">
                <template #first>
                  <option disabled value="">Choose a machine...</option>
                </template>
              </b-form-select>
              <b-button size="sm" block class="mt-1" variant="danger" @click="machines = []" v-if="machines.length > 0"
                :disabled="fetching"><b-icon icon="trash" class="mr-1"></b-icon>Remove all</b-button>
              <b-button size="sm" block class="mt-1" @click="
                machines = machine_infos
                  .filter((ele) => versions.includes(ele.version))
                  .map((ele) => ele.name)
                " :disabled="fetching" v-else><b-icon icon="plus-circle" class="mr-1"></b-icon>Add
                all</b-button>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Batch IDs" label-for="select-batches" label-cols-xl="3">
          <b-form-tags v-model="batch_ids" size="md" class="mb-2" add-on-change no-outer-focus :disabled="fetching">
            <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
              <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-0 border rounded w-100 p-1">
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag @remove="removeTag(tag)" :title="tag" :disabled="disabled">{{ tag }}</b-form-tag>
                </li>
              </ul>
              <b-form-select id="select-batches" v-bind="inputAttrs" v-on="inputHandlers" size="sm" :disabled="disabled ||
                batches.filter((opt) => batch_ids.indexOf(opt.text) === -1)
                  .length === 0
                " :options="batches
    .filter((opt) => batch_ids.indexOf(opt.text) === -1)
    .map((opt) => opt.text)
    ">
                <template #first>
                  <option disabled value="">Choose a batch ID...</option>
                </template>
              </b-form-select>
              <b-button size="sm" block class="mt-1" variant="danger" @click="batch_ids = []" :disabled="fetching"
                v-if="batch_ids.length > 0"><b-icon icon="trash" class="mr-1"></b-icon>Remove all</b-button>
              <b-button size="sm" block class="mt-1" @click="batch_ids = batches.map((ele) => ele.text)"
                :disabled="fetching" v-else><b-icon icon="plus-circle" class="mr-1"></b-icon>Add
                all</b-button>
            </template>
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Chip numbers" label-for="select-chips" label-cols-xl="3">
          <b-form-tags v-model="chip_numbers" input-id="select-chips" size="md" class="mb-2" separator=" ,;"
            placeholder="Enter the chip numbers (use * for wildcards)" @input="startRunsFetches()" remove-on-delete
            :disabled="fetching">
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Sample IDs" label-for="select-samples" label-cols-xl="3">
          <b-form-tags v-model="samples" input-id="select-samples" size="md" class="mb-2" separator=" ,;"
            placeholder="Enter the sample IDs (use * for wildcards)" @input="startRunsFetches()" remove-on-delete
            :disabled="fetching">
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Reagent IDs" label-for="select-reagent" label-cols-xl="3">
          <b-form-tags v-model="reagent" input-id="select-reagent" size="md" class="mb-2" separator=" ,;"
            placeholder="Enter the reagent IDs (use * for wildcards)" @input="startRunsFetches()" remove-on-delete
            :disabled="fetching">
          </b-form-tags>
        </b-form-group>
        <b-form-group label="Minimal date" label-for="picker-mindate" label-cols-xl="3">
          <b-form-datepicker id="picker-mindate" v-model="mindate" :min="oldest_date" :max="maxdate" size="sm"
            @input="startRunsFetches()" today-button :disabled="fetching"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Maximal date" label-for="picker-maxdate" label-cols-xl="3">
          <b-form-datepicker id="picker-maxdate" v-model="maxdate" :min="mindate" :max="latest_date" size="sm"
            @input="startRunsFetches()" today-button :disabled="fetching"></b-form-datepicker>
        </b-form-group>
        <b-form-group label="Minimal run duration" label-for="range-min_duration" label-cols-xl="3">
          <b-form-input id="range-min_duration" v-model="min_duration" min="0" max="1800" type="range" size="sm"
            @change="startRunsFetches()" :disabled="fetching"></b-form-input>
          <div>
            {{ min_duration }} second<span v-if="min_duration > 1">s</span>
          </div>
        </b-form-group>
        <b-form-group label="" label-for="select-filter_max_duration" label-cols-xl="3">
          <b-form-checkbox v-model="filter_max_duration" id="select-filter_max_duration" @change="startRunsFetches()"
            size="sm" switch :disabled="fetching">Set maximal run duration</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Maximal run duration" label-for="range-max_duration" label-cols-xl="3"
          v-show="filter_max_duration">
          <b-form-input id="range-max_duration" v-model="max_duration" :min="min_duration" max="2000" type="range"
            size="sm" @change="startRunsFetches()" :disabled="fetching"></b-form-input>
          <div>
            {{ max_duration }} second<span v-if="max_duration > 1">s</span>
          </div>
        </b-form-group>
        <b-row class="mx-0">
          <b-col xl="3" class="px-0 form-row">
            <label class="col-form-label">Meta datas</label>
          </b-col>
          <b-col xl="9" class="pl-0 pr-1">
            <b-row class="mx-0 border-bottom" v-if="meta_datas.length > 0">
              <b-table :items="meta_datas" :fields="meta_fields" small responsive bordered striped
                class="text-nowrap mb-1">
                <template #cell(delete)="data">
                  <b-iconstack class="clickable" title="Remove" @click="removeMeta(data)">
                    <b-icon stacked icon="square-fill" variant="danger"></b-icon>
                    <b-icon stacked icon="trash" scale="0.75" variant="white"></b-icon>
                  </b-iconstack>
                </template>
                <template #head(delete)>
                  <b-iconstack class="clickable" title="Remove all" @click="meta_datas = []">
                    <b-icon stacked icon="circle-fill" variant="danger"></b-icon>
                    <b-icon stacked icon="slash-circle" scale="0.75" variant="white"></b-icon>
                  </b-iconstack>
                </template>
              </b-table>
            </b-row>
            <b-row class="mx-0">
              <b-col xl="4" class="p-1">
                <b-form-input type="text" v-model="meta_key" list="filter_avail_keys" autocomplete="off"
                  :formatter="keyFormatter" @input="fetchMetaValues()" @focus="fetchMetaKeys()" size="sm"
                  placeholder="Meta key" :disabled="fetching"></b-form-input>
                <datalist type="text" id="filter_avail_keys">
                  <option v-for="key of avail_keys" :key="key">
                    {{ key }}
                  </option>
                </datalist>
              </b-col>
              <b-col xl="5" class="p-1">
                <b-form-input type="text" v-model="meta_value" list="filter_avail_values" autocomplete="off" size="sm"
                  placeholder="Meta value" :disabled="meta_key.length == 0 || fetching"></b-form-input>
                <datalist type="text" id="filter_avail_values">
                  <option v-for="key of avail_values" :key="key">
                    {{ key }}
                  </option>
                </datalist>
              </b-col>
              <b-col xl="3" class="p-1">
                <b-button block variant="success" size="sm" :disabled="meta_key.length == 0 || meta_value.length == 0 || fetching
                  " @click="addMeta()"><b-icon icon="plus" class="mr-1"></b-icon>Add</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./HelpView.vue";
import { runAllInBatches } from "../utils/async";

const CancelToken = axios.CancelToken;
const now = new Date();
const nowstring = formatDate(now);
const aweekago = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
const aweekagostring = formatDate(aweekago);

function formatDate(date) {
  return [date.getFullYear(), ('0' + (date.getMonth() + 1)).slice(-2), ('0' + date.getDate()).slice(-2)].join("-");
}

export default {
  name: "RunsFilter",

  props: {
    filtered_runs: Array,
    reset_filters: Boolean,
    fetching_filter: Boolean,
    batches_infos: Array,

    isAdmin: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    minTime() {
      return new Date(this.mindate).getTime();
    },

    maxTime() {
      return new Date(this.maxdate).getTime();
    },

    metaDataKeys() {
      return this.meta_datas.map((ele) => ele.key);
    },
  },

  data: function () {
    return {
      versions: [],
      machines: [],
      machine_infos: [],
      batches: [],
      batch_ids: [],
      chip_numbers: [],
      samples: [],
      reagent: [],
      batch_infos: this.batches_infos,
      mindate: aweekagostring,
      oldest_date: null,
      latest_date: nowstring,
      maxdate: nowstring,
      experimentname: null,
      anankeuser: null,
      regexp_search: false,
      runs: this.filtered_runs,
      temp_runs: [],
      fetching: this.fetching_filter,
      source: CancelToken.source(),
      select_size: 4,
      min_duration: 600,
      filter_max_duration: false,
      max_duration: 2000,
      search_placeholder: "Experiment name (use * for wildcards)",
      meta_datas: [],
      avail_keys: [],
      avail_values: [],
      meta_key: "",
      meta_value: "",
      meta_fields: [
        {
          key: "delete",
          label: "",
          tdClass: "align-middle text-center",
          thClass: "align-middle text-center",
          thStyle: { width: "2rem" },
        },
        { key: "key" },
        { key: "value" },
      ],
      last_leave: null,
      group_names: undefined,
      selected_group: undefined,
      fetching_filters: false,
      first_filter: false,
    };
  },

  watch: {
    reset_filters() {
      this.onReset();
    },

    runs() {
      this.$emit("update:filtered_runs", this.runs);
    },

    batch_infos() {
      this.$emit("update:batches_infos", this.batch_infos);
    },

    fetching() {
      this.$emit("update:fetching_filter", this.fetching);
    },

    meta_datas() {
      if (!this.areFiltersBeingReset) {
        this.startRunsFetches();
        this.fetchMetaKeys();
      }
    },

    machines() {
      if (!this.areFiltersBeingReset) {
        this.startRunsFetches();
      }
    },

    versions() {
      if (this.machines.length > 0) {
        this.machines = this.machines.filter((ele) =>
          this.machine_infos
            .filter((ele) => this.versions.includes(ele.version))
            .map((ele) => ele.name)
            .includes(ele)
        );
      }
    },

    batch_ids() {
      if (!this.areFiltersBeingReset) {
        this.startRunsFetches();
      }
    },

    async selected_group() {
      this.areFiltersBeingReset = true;
      await this.fetchFiltersData();
      this.areFiltersBeingReset = false;
      await this.startRunsFetches();
    },

    isAdmin: {
      handler(isAdmin) {
        if (isAdmin) {
          this.fetchGroupsNames();
        }
      },

      immediate: true,
    },
  },
  methods: {
    async fetchFiltersData() {
      this.fetching_filters = true;
      await Promise.all([
        this.fetchMetaKeys(),
        this.fetchOldestDate(),
        this.fetchAllMachineInfos(),
        this.fetchBatchIDs()
      ]);
      this.fetching_filters = false;
    },

    async fetchMachineInfo(machine_id) {
      return (await axios.get("/api/v1/machine/info", {
        params: { machine_id: machine_id }
      })).data
    },

    async fetchAllMachineInfos() {
      this.machine_infos = []
      const machine_ids = (await axios
        .get("/api/v1/machine/ids", {
          params: { group_name: this.selected_group, only_with_runs: true },
        })).data
      var fetches = []
      for (let machine_id of machine_ids) {
        fetches.push(() => this.fetchMachineInfo(machine_id))
      }
      this.machine_infos = await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })
      this.versions = [
        ...new Set(this.machine_infos.map((ele) => ele.version)),
      ];
      this.machines = this.machine_infos.map((ele) => ele.name);
    },

    async fetchBatchInfo(batch_id) {
      return (await axios.get("/api/v1/batch/info", {
        params: { batch_id: batch_id }
      })).data
    },

    async fetchBatchIDs() {
      this.batch_infos = []
      const batch_ids = (await axios
        .get("/api/v1/batch/ids", {
          params: { group_name: this.selected_group, only_with_runs: true },
        })).data
      var fetches = []
      for (let batch_id of batch_ids) {
        fetches.push(() => this.fetchBatchInfo(batch_id))
      }
      this.batch_infos = await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })
      this.batches = this.batch_infos.map((ele) => ({
        value: ele.id,
        text: ele.range,
      }));
      this.machines = this.machine_infos.map((ele) => ele.name);
      this.batch_ids = [];
    },

    async fetchOldestDate() {
      try {
        this.oldest_date = (await axios
          .get("/api/v1/run/oldest-date", {
            params: {
              group_name: this.selected_group,
            },
          })).data
      }
      catch {
        this.oldest_date = aweekago
      }
    },

    async fetchMetaValues() {
      this.meta_value = ""
      if (this.meta_key.length > 0) {
        this.avail_values = (await axios.get("/api/v1/meta/values", {
          params: { key: this.meta_key, group_name: this.selected_group }
        })).data.filter(ele => !this.meta_datas.filter(
          ele2 => ele2.key == this.meta_key).map(ele3 => ele3.value).includes(ele))
      }
    },

    async fetchMetaKeys() {
      this.meta_key = ""
      this.meta_value = ""
      this.avail_keys = (await axios
        .get("/api/v1/meta/keys", {
          params: { group_name: this.selected_group },
        })).data.filter(ele => !this.metaDataKeys.includes(ele))
    },

    async startRunsFetches() {
      var care_about_machines = true;
      if (this.first_filter) {
        this.first_filter = false;
        care_about_machines = false;
      }
      if (this.first_filter || !this.fetching_filters) {
        if (parseFloat(this.min_duration) > parseFloat(this.max_duration)) {
          this.max_duration = this.min_duration;
        }

        if (this.regexp_search) {
          this.search_placeholder = "Experiment name (regular expression)";
        } else {
          this.search_placeholder = "Experiment name (use * for wildcards)";
        }

        if (this.fetching) {
          this.source.cancel();
          this.fetching = false;
        }

        this.source = CancelToken.source();
        this.fetching = true;
        this.runs = [];

        const paramsBase = {
          min_duration: this.min_duration,
          max_duration: this.filter_max_duration
            ? this.max_duration
            : undefined,
          name: this.experimentname ? this.experimentname : undefined,
          regexp_search: this.experimentname
            ? this.experimentname && this.regexp_search
            : false,
          user: this.anankeuser ? this.anankeuser : undefined,
          meta_datas: JSON.stringify(this.meta_datas),

          batch_id: this.batches
            .filter((opt) => this.batch_ids.includes(opt.text))
            .map((ele) => ele.value),

          chip_number:
            this.chip_numbers.length > 0 ? this.chip_numbers : null,

          samples:
            this.samples.length > 0 ? this.samples : null,

          reagent:
            this.reagent.length > 0 ? this.reagent : null,
        };
        const selectedMachinesIds = this.machine_infos
          .filter((opt) => this.machines.includes(opt.name))
          .map((ele) => ele.id);

        if (care_about_machines) {
          if (selectedMachinesIds.length > 0) {
            try {
              await this.fetchRunsBatches([
                {
                  ...paramsBase,
                  machines: selectedMachinesIds,
                  mindate: this.mindate,
                  maxdate: this.maxdate,
                },
              ]);
            } catch (error) {
              if (!axios.isCancel(error)) {
                console.error("run ids fetch failed due to error:\n", error);
              }
            }
          }
        } else {
          try {
            await this.fetchRunsBatches([
              {
                ...paramsBase,
                mindate: this.mindate,
                maxdate: this.maxdate,
              },
            ]);
          } catch (error) {
            if (!axios.isCancel(error)) {
              console.error("run ids fetch failed due to error:\n", error);
            }
          }
        }

        this.fetching = false;
      }
    },

    async fetchRunsBatches(paramsBatches) {
      await Promise.all(paramsBatches.map(this.fetchRunsBatch));
    },

    async fetchRunInfo(run_id) {
      return (await axios.get("/api/v1/run/info", {
        params: { run_id: run_id },
        cancelToken: this.source.token
      })).data
    },

    async fetchRunsBatch(params) {
      const PAGE_SIZE = 50;
      const MAX_LENGTH = 10000;
      let run_ids;
      this.runs = [];

      for (
        let offset = 0;
        (!run_ids || (run_ids.length > 0 && run_ids.length % PAGE_SIZE == 0)) &&
        this.runs.length < MAX_LENGTH;
        offset += PAGE_SIZE
      ) {
        try {
          run_ids = (await axios.get("/api/v1/run/ids", {
            params: {
              ...params,
              group_name: this.selected_group,
              offset,
              limit: PAGE_SIZE,
            },
            cancelToken: this.source.token,
          })).data
          var fetches = []
          for (let run_id of run_ids) {
            fetches.push(() => this.fetchRunInfo(run_id))
          }
          this.runs.push(...await runAllInBatches({
            batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
            operations: fetches,
          }))
        } catch (error) {
          if (axios.isCancel(error)) {
            console.warn("Request cancelled due to changed filtering options");
            throw error;
          } else {
            console.error(
              `run ids batch [${offset} – ${offset + PAGE_SIZE
              }] fetch failed due to error:\n`,
              error
            );
          }
        }
      }
    },

    async fetchGroupsNames() {
      this.group_names = await (
        await axios.get("/api/v1/group/names")
      )?.data;
    },

    keyFormatter(value) {
      return value
        .toUpperCase()
        .replace(" ", "_")
        .replace(/[^A-Z0-9_]/, "");
    },

    async onReset() {
      this.areFiltersBeingReset = true;
      this.selected_group = undefined;
      this.versions = [];
      this.machines = [];
      this.mindate = this.oldest_date;
      this.maxdate = nowstring;
      this.experimentname = null;
      this.anankeuser = null;
      await this.fetchFiltersData();
      this.min_duration = 0;
      this.filter_max_duration = false;
      this.max_duration = 2000;
      this.regexp_search = false;
      this.meta_datas = [];
      this.meta_key = "";
      this.meta_value = "";
      await this.startRunsFetches();
      this.areFiltersBeingReset = false;
    },

    addMeta() {
      this.meta_datas.push({ key: this.meta_key, value: this.meta_value });
    },

    removeMeta(data) {
      this.meta_datas = this.meta_datas.filter(
        (ele) => ele.key !== data.item.key
      );
    },

    gotFocus() {
      const now_epoch = new Date().getTime();
      if (now_epoch - this.last_leave > 900000) {
        this.startRunsFetches();
      }
    },

    lostFocus() {
      this.last_leave = new Date().getTime();
    },

    cancelRunLoad() {
      this.source.cancel();
    },

    reloadRuns() {
      this.startRunsFetches();
    },
  },

  async created() {
    this.first_filter = true;
    this.startRunsFetches();
    this.areFiltersBeingReset = true;
    await this.fetchFiltersData();
    this.areFiltersBeingReset = false;
  },

  components: {
    HelpView,
  },

  mounted() {
    window.addEventListener("focus", this.gotFocus);
    window.addEventListener("blur", this.lostFocus);
  },

  beforeDestroy() {
    window.removeEventListener("focus", this.gotFocus);
    window.removeEventListener("blur", this.lostFocus);
  },
};
</script>

<style scoped>
.search_runs {
  max-height: 370px;
  overflow: auto;
}

.search_runs .form-row {
  margin-left: 0;
  margin-right: 0;
}

.search_runs>>>.b-form-datepicker .dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

.clickable {
  cursor: pointer;
}

.b-form-tags>>>ul {
  max-height: 150px;
  overflow: auto;
}
</style>