<template>
  <div>
    <h2>
      Machines table
      <small class="text-secondary" v-if="machine_infos.length > 0 && !fetching">
        ({{ machine_infos.length }} machines, {{ num_runs }} runs)
      </small>
      <small><help-view>This page allows to see the machines table for which you have an
          access.</help-view></small>
    </h2>
    <hr />
    <b-container fluid>
      <b-button-toolbar>
        <b-button-group class="mx-1">
          <b-pagination v-model="current_page" :total-rows="machine_infos.length" :per-page="per_page"
            :disabled="fetching"></b-pagination>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button @click="fetchMachinesInfos()" class="border" :disabled="fetching"><b-icon icon="arrow-clockwise"
              class="mr-1"></b-icon>Reload</b-button>
        </b-button-group>
        <b-button-group class="mr-1" v-if="machine_infos.length > 0">
          <b-button @click="downloadAsCSV()" title="Download as CSV" class="border"><b-icon icon="download"
              class="mr-1"></b-icon>Download</b-button>
        </b-button-group>
        <b-button-group class="mr-1" v-if="machine_infos.length > 0">
          <b-button @click="copyAsCSV()" title="Copy as CSV to the clipboard" class="border"><b-icon icon="files"
              class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-form-select :options="machine_versions" v-model="version" @input="filterMachines()">
            <template #first>
              <option value="">Search by version...</option>
            </template>
          </b-form-select>
        </b-input-group>
        <b-button-group class="mx-1" v-if="is_admin">
          <b-button variant="success" @click="openMachineForm" class="border"><b-icon icon="plus"
              class="mr-1"></b-icon>Add</b-button>
        </b-button-group>
        <b-button-group class="mx-1" v-if="selected_machine !== null && is_admin">
          <b-button variant="primary" @click="showEdit" class="border"><b-icon icon="pen"
              class="mr-1"></b-icon>Edit</b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-overlay :show="is_admin == undefined && fetching">
        <b-table :items="machine_infos" :fields="fields" striped bordered :busy="fetching" class="text-nowrap" show-empty
          sticky-header="750px" :per-page="per_page" :current-page="current_page" sortable sort-by="name"
          :hover="is_admin" :selectable="is_admin" select-mode="single" @row-selected="onRowSelected"
          :tbody-tr-class="rowClass" v-if="is_admin !== undefined">
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading...</strong>
            </div>
            <b-progress :value="current_machines" :max="total_machines" animated></b-progress>
          </template>
        </b-table>
      </b-overlay>
    </b-container>
    <router-view @close="closeMachineForm" :saving="saving" :error="error" @save="saveNewMachine"></router-view>
    <b-modal v-model="show_edit" title="Edit a machine" hide-footer size="lg">
      <b-overlay :show="saving">
        <div v-if="edit_machine !== null">
          <b-form-group v-for="field in fields.filter((field) => !field.notedit)" :key="field.key" :label="field.label"
            :label-for="field.key" label-cols-xl="3">
            <b-form-input :id="field.key" :type="field.type" :min="field.min" :max="field.max"
              v-model="edit_machine[field.key]" :state="notEmpty(field)"></b-form-input>
          </b-form-group>
          <b-form-group label="Public key" label-for="edited_machine_public_key" label-cols-xl="3">
            <b-form-input id="edited_machine_public_key" type="text" v-model="edit_machine.public_key"></b-form-input>
          </b-form-group>
          <hr />
          <b-row class="text-center text-danger" v-if="error !== null"><b-col cols="12"><strong>{{ error
          }}</strong></b-col></b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <b-button @click="show_edit = false" variant="danger" class="mx-1"><b-icon icon="x"
                  class="mr-1"></b-icon>Cancel</b-button>
              <b-button variant="primary" class="mx-1" :disabled="checkState()" @click="saveMachine"><b-icon icon="check"
                  class="mr-1"></b-icon>Save</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./components/HelpView.vue";
import { runAllInBatches } from "./utils/async";
const CancelToken = axios.CancelToken;

export default {
  name: "MachinesTable",

  data: function () {
    return {
      is_admin: undefined,
      machine_versions: [],
      version: "",
      machine_infos_base: [],
      machine_infos: [],
      total_machines: 0,
      current_machines: 0,
      num_runs: 0,
      source: CancelToken.source(),
      fetching: false,
      per_page: 50,
      current_page: 1,
      selected_machine: null,
      edit_machine: null,
      show_edit: false,
      saving: false,
      error: null,
      fields: [
        {
          key: "name",
          label: "Machine name",
          notedit: true,
          sortable: true,
        },
        {
          key: "orig_name",
          label: "Chronos machine name",
          notedit: true,
          sortable: true,
        },
        {
          key: "product",
          label: "Product",
          notnull: true,
          type: "text",
          sortable: true,
        },
        {
          key: "version",
          label: "Version",
          notnull: true,
          type: "text",
          sortable: true,
        },
        {
          key: "number",
          label: "Number",
          notnull: true,
          type: "number",
          min: 0,
          max: 9999,
          sortable: true,
        },
        {
          key: "subversion",
          label: "Sub-version",
          notnull: true,
          type: "text",
          sortable: true,
        },
        {
          key: "num_runs",
          label: "Number of runs",
          notedit: true,
          sortable: true,
        },
        {
          key: "last_run",
          label: "Last run number",
          notedit: true,
          sortable: true,
        },
        {
          key: "activity",
          label: "Last activity delay",
          notedit: true,
          sortable: true,
          formatter: this.delayFormatter,
        },
      ],
    };
  },
  methods: {
    async isAdmin() {
      this.fetching = true;
      try {
        const user_role = (await axios
          .get("/api/v1/user/info")).data.profile.role
        this.is_admin = ["production", "admin"].includes(user_role)
      } catch (error) {
        console.warn("The user is not authenticated");
      }
    },
    filterMachines: function () {
      if (this.version !== "") {
        this.machine_infos = this.machine_infos_base.filter(
          (ele) => ele.version == this.version
        );
      } else {
        this.machine_infos = this.machine_infos_base;
      }
    },
    async fetchMachineInfo(machine_id) {
      const machine_info = (await axios.get("/api/v1/machine/info", {
        params: { machine_id: machine_id }
      })).data
      this.current_machines++
      return machine_info
    },
    async fetchMachinesInfos() {
      this.num_runs = 0;
      this.machine_infos = [];
      this.current_machines = 0;
      this.total_machines = 0;
      this.fetching = true;
      const machine_ids = (await axios
        .get("/api/v1/machine/ids", {
          params: { group_name: this.selected_group },
        })).data
      this.total_machines = machine_ids.length
      var fetches = []
      for (let machine_id of machine_ids) {
        fetches.push(() => this.fetchMachineInfo(machine_id))
      }
      this.machine_infos_base = await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })
      this.machine_versions = [
        ...new Set(this.machine_infos_base.map((ele) => ele.version)),
      ];
      this.filterMachines();
      var num_runs = 0;
      this.machine_infos_base.forEach((ele) => {
        num_runs += ele.num_runs;
      });
      this.num_runs = num_runs;
      this.fetching = false;
    },
    onRowSelected: function (items) {
      if (items.length > 0) {
        this.selected_machine = items[0];
      } else {
        this.selected_machine = null;
      }
    },
    async saveNewMachine({ name, publicKey }) {
      this.saving = true;
      this.error = null;
      if (publicKey === undefined || publicKey == "") {
        publicKey = null
      }
      try {
        await axios
          .post("/api/v1/machine/add", { name, public_key: publicKey })
        this.closeMachineForm();
        this.saving = false;
        this.fetchMachinesInfos()
      }
      catch (error) {
        this.saving = false;
        this.error =
          error.response?.data?.detail ||
          error.response?.data?.error ||
          "Internal server error.";
      }
    },
    showEdit: function () {
      const edit_machine = {};

      this.fields
        .filter((field) => !field.notedit)
        .forEach(
          (field) =>
            (edit_machine[field.key] = this.selected_machine[field.key])
        );

      edit_machine.public_key = this.selected_machine.public_key;
      if (edit_machine.public_key === undefined) {
        edit_machine.public_key = null
      }
      edit_machine.id = this.selected_machine.id;

      this.edit_machine = edit_machine;
      this.saving = false;
      this.error = null;
      this.show_edit = true;
    },
    notEmpty: function (field) {
      var valid = true;
      if (field.notnull) {
        valid = this.edit_machine[field.key] != null;
        if (this.edit_machine[field.key] != null) {
          if (this.edit_machine[field.key].length == 0) {
            valid = false;
          }
          if (field.type == "number") {
            if (
              this.edit_machine[field.key] < field.min ||
              this.edit_machine[field.key] > field.max
            ) {
              valid = false;
            }
          }
        }
      }
      return valid;
    },
    checkState: function () {
      var valid = true;
      for (let field of this.fields.filter((field) => !field.notedit)) {
        if (field.notnull) {
          if (this.edit_machine[field.key] == null) {
            valid = false;
          }
          if (this.edit_machine[field.key] != null) {
            if (this.edit_machine[field.key].length == 0) {
              valid = false;
            }
            if (field.type == "number") {
              if (
                this.edit_machine[field.key] < field.min ||
                this.edit_machine[field.key] > field.max
              ) {
                valid = false;
              }
            }
          }
        }
      }
      return !valid;
    },
    async saveMachine() {
      this.saving = true;
      this.error = null;
      if (this.edit_machine.public_key?.length == 0) {
        this.edit_machine.public_key = null
      }
      try {
        await axios
          .post("/api/v1/machine/edit", this.edit_machine)
        this.saving = false
        this.show_edit = false
        this.fetchMachinesInfos()
      }
      catch (error) {
        this.saving = false;
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail;
        } else {
          this.error = "Internal server error.";
        }
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.activity > 3600 && item.activity !== null) {
        return "row-error font-weight-bold";
      }
      if (item.activity > 600 && item.activity !== null) {
        return "row-warning font-weight-bold";
      }
      if (item.activity <= 600 && item.activity !== null) {
        return "row-valid font-weight-bold";
      }
    },
    delayFormatter: function (value) {
      if (value !== null) {
        var days = Math.floor(value / (3600 * 24)).toString();
        var hours = Math.floor((value % (3600 * 24)) / 3600).toString();
        var minutes = Math.floor((value % 3600) / 60).toString();
        var seconds = Math.floor(value % 60).toString();
        var dDisplay = days > 0 ? days + "d" : "";
        var hDisplay = hours > 0 ? hours + "h" : "";
        var mDisplay = minutes > 0 ? minutes + "m" : "";
        var sDisplay = seconds > 0 ? seconds + "s" : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
      }
      return "";
    },

    openMachineForm() {
      this.saving = false;
      this.error = undefined;
      this.$router.push({ name: "MachineForm" });
    },

    closeMachineForm() {
      this.$router.push({ name: this.$options.name });
    },

    exportAsCSV() {
      var column_names = this.fields.map((ele) => ele.key);
      var rows = this.machine_infos.map((machine_info) => {
        var row_data = [];
        for (let column_name of column_names) {
          row_data.push(machine_info[column_name]);
        }
        return row_data;
      });
      return [column_names].concat(rows);
    },

    downloadAsCSV() {
      var array_data = this.exportAsCSV();
      var csv_content =
        'data:text/csv;charset=utf-8,"' +
        array_data.map((ele) => ele.join('","')).join('"\n"') +
        '"';
      var encoded_uri = encodeURI(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        "gaia_machine_infos_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    copyAsCSV() {
      var vm = this;
      var csv_content =
        '"' +
        this.exportAsCSV()
          .map((ele) => ele.join('","'))
          .join('"\n"') +
        '"';
      navigator.clipboard.writeText(csv_content).then(
        () => (vm.dismiss_countdown = vm.total_secs),
        () => (vm.dismiss_countdown = vm.total_secs)
      );
    },
  },
  created: function () {
    this.isAdmin();
    this.fetchMachinesInfos();
  },
  components: {
    HelpView,
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>