<template>
  <b-container>
    <iframe
      width="100%"
      height="1000px"
      :src="embbeded_src"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </b-container>
</template>
<script lang="js">
export default {
    name: "InteractiveMap",
    data: function () {
      return {
        embbeded_src: process.env.VUE_APP_INTERACTIVE_MAP
      }
    }
}
</script>