<template>
  <div>
    <b-overlay :show="fetching" opacity="1">
      <data-viz :selected_runs.sync="run_infos" :infos_fields="infos_fields" :batch_fields="batch_fields"
        :batches_infos="batches_infos" :single_view="true" :is-archive="isArchive" :machine_name="machine_name"
        :ananke_id="ananke_id"></data-viz>
    </b-overlay>
  </div>
</template>
<script>
import axios from "axios";
import DataViz from "./components/DataViz.vue";

export default {
  name: "SingleRun",
  props: [
    "machine_name",
    "ananke_id",
    "infos_fields",
    "batch_fields",
    "isArchive"
  ],
  data: function () {
    return {
      machine_id: null,
      run_infos: [],
      fetching: false,
      batches_infos: []
    };
  },
  methods: {
    async getRunInfos(run_id) {
      this.fetching = true;
      this.run_infos = [(await axios
        .get("/api/v1/run/info", {
          params: { run_id: run_id },
        })).data]
      this.fetching = false
    },
    async getBatchInfo() {
      if (this.run_infos[0]?.batch_id !== undefined && this.run_infos[0]?.batch_id !== null)
        this.batches_infos = [(await axios
          .get("/api/v1/batch/info", {
            params: { batch_id: this.run_infos[0]?.batch_id }
          })).data]
    },
    async getRunID() {
      this.fetching = true;
      try {
        const run_id = (await axios
          .get("/api/v1/run/convert-id", {
            params: {
              machine_id: (await axios.get("/api/v1/machine/convert-id", {
                params: { machine_name: this.machine_name }
              })).data,
              ananke_id: this.ananke_id,
            },
          })).data
        await this.getRunInfos(run_id);
        await this.getBatchInfo();
      }
      catch (error) {
        console.warn("Run not found");
      }
      this.fetching = false
    },
  },
  created: function () {
    this.getRunID();
  },
  components: {
    DataViz,
  },
};
</script>