<template>
  <div style="display: inline">
    <span
      @mouseover="overed = true"
      @mouseleave="overed = false"
      style="cursor: pointer"
      ref="popover_target"
    >
      <b-icon icon="question-circle" variant="secondary" v-if="overed"></b-icon>
      <b-icon
        icon="question-circle-fill"
        variant="secondary"
        v-if="!overed"
      ></b-icon>
    </span>
    <b-popover :target="getTargetRef" :triggers="triggers">
      <template #title>{{ popover_title }}</template>
      <slot></slot>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: "HelpView",
  props: ["title"],
  data: function () {
    return {
      popover_title: this.title,
      triggers: ["hover"],
      overed: false,
    };
  },
  methods: {
    getTargetRef() {
      return this.$refs.popover_target;
    },
  },
};
</script>