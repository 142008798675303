<template>
  <b-overlay :show="resetting">
    <b-card>
      <p class="text-center d-none d-xl-block">
        <b-img src="/logo.png" style="max-height: 150px" fluid></b-img>
      </p>
      <p class="text-center d-xl-none">
        <b-img src="/logo_small.png" style="max-height: 150px" fluid></b-img>
      </p>
      <h2 class="text-center">Welcome to GAIA Browser</h2>
      <p class="text-center">Version {{ app_version }}</p>
      <h3 class="text-center">Password reset</h3>
      <p class="text-center text-secondary">
        Enter below your adress email to receive a link allowing to set a new
        password.
      </p>
      <b-alert :show="error" variant="danger" dismissible>{{
        error_message
      }}</b-alert>
      <b-alert :show="email_sent" variant="success" dismissible>An email has been sent with a link to reset the
        password.</b-alert>
      <b-form @submit="ResetPassword">
        <b-form-group label="Email" label-for="user-email">
          <b-form-input id="user-email" type="email" v-model="email" required
            placeholder="Please enter your email address"></b-form-input>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <p class="mt-2 mb-0">
              <router-link to="/" class="text-dark">Go back to home page</router-link>
            </p>
          </b-col>
          <b-col cols="6">
            <b-button type="submit" variant="dark" class="float-right">Reset password</b-button>
          </b-col>
        </b-row>
        <hr />
        <p class="m-0 text-center">
          <a href="https://bforcure.atlassian.net/wiki/x/N4BRBg" target="_blank" class="text-dark">Need more help?</a>
        </p>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import axios from "axios";

export default {
  name: "ResetPassword",
  props: ["app_version"],
  data: function () {
    return {
      resetting: false,
      error: false,
      error_message: "",
      email: "",
      email_sent: false,
    };
  },
  methods: {
    async ResetPassword(event) {
      event.preventDefault();
      this.error = false;
      this.resetting = true;
      this.email_sent = false;
      try {
        await axios
          .post("/api/v1/user/reset_password", null, {
            params: { email: this.email },
          })
        this.email_sent = true
      }
      catch (error) {
        this.error = true;
        if (error.response.status == 404) {
          this.error_message = "Email not found";
        }
        else if (error.response.data.detail != undefined) {
          this.error_message = error.response.data.detail;
        } else {
          this.error_message = "Internal server error";
        }
      }
      this.resetting = false
    },
  },
};
</script>