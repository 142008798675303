<template>
  <b-modal title="Add a machine" hide-footer size="lg" visible @close="defaultClose" @hide="close">
    <b-overlay :show="saving">
      <div>
        <b-form-group
          label="Chronos machine name"
          label-for="new_machine_name"
          label-cols-xl="3"
        >
          <b-form-input
            id="new_machine_name"
            type="text"
            v-model="name"
            :state="isNameValid"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Public key"
          label-for="new_machine_public_key"
          label-cols-xl="3"
        >
          <b-form-input
            id="new_machine_public_key"
            type="text"
            v-model="publicKey"
          ></b-form-input>
        </b-form-group>
        <hr />
        <b-row class="text-center text-danger" v-if="error"
          ><b-col cols="12"
            ><strong>{{ error }}</strong></b-col
          ></b-row
        >
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="close" variant="danger" class="mx-1"
              ><b-icon icon="x" class="mr-1"></b-icon>Cancel</b-button
            >
            <b-button
              variant="primary"
              class="mx-1"
              :disabled="!isNameValid"
              @click="save"
              ><b-icon icon="check" class="mr-1"></b-icon>Save</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  name: 'MachineForm',

  props: {
    prefilledName: String,
    prefilledPublicKey: String,
    error: String,

    saving: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      name: this.prefilledName,
      publicKey: this.prefilledPublicKey
    }
  },

  computed: {
    isNameValid() {
      return this.name?.length > 0
    }
  },

  methods: {
    defaultClose() {
      event.preventDefault()
    },

    close(event) {
      event.preventDefault()

      if (!this.saving) {
        this.$emit('close')
      }
    },

    save() {
      this.$emit('save', {
        name: this.name,
        publicKey: this.publicKey
      })
    }
  }
}
</script>