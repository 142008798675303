<template>
  <div>
    <b-navbar type="dark" variant="dark" toggleable="lg" v-if="is_authenticated">
      <b-navbar-brand to="/">
        <b-img src="/logo_small_white.png" style="height: 2rem"></b-img>&nbsp;GAIA Browser
        <small> ({{ app_version }})</small>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">

          <b-nav-item to="/dashboard" active-class="active" v-if="!is_email_sender">Dashboard</b-nav-item>
          <b-nav-item to="/machines" active-class="active" v-if="!is_email_sender">Machines</b-nav-item>
          <b-nav-item-dropdown text="Advanced views" right v-if="user_group == 'BForCure'">
            <b-dropdown-item to="/data/visualization" active-class="active"><b-icon
                icon="file-bar-graph"></b-icon>&nbsp;Advanced data
              visualization</b-dropdown-item>
            <b-dropdown-item to="/data/interactive-map" active-class="active"><b-icon
                icon="map"></b-icon>&nbsp;Interactive
              map</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown text="Administration" right v-if="is_admin || is_chip_admin">
            <b-dropdown-item to="/admin/batch" active-class="active"><b-icon icon="box-seam"></b-icon>&nbsp;Chip
              batches</b-dropdown-item>
            <b-dropdown-item to="/admin/users" active-class="active" v-if="is_admin"><b-icon
                icon="person-bounding-box"></b-icon>&nbsp;Users</b-dropdown-item>
                <b-dropdown-item to="/admin/data" active-class="active" v-if="is_admin"><b-icon
                icon="cloud-upload"></b-icon>&nbsp;Data upload</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown right v-if="is_authenticated" class="text-nowrap">
            <template #button-content>User<span v-if="locked_machines.length > 0">&nbsp;<b-badge variant="danger">{{
              locked_machines.length
            }}</b-badge></span></template>
            <b-dropdown-text><b-icon icon="person-circle"></b-icon>&nbsp;
              <i>{{ user_email }}</i></b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/user/profile" active-class="active" v-if="!is_email_sender">
              <b-icon icon="info-circle"></b-icon>&nbsp;Profile<span v-if="locked_machines.length > 0">&nbsp;<b-badge
                  variant="danger">{{
                    locked_machines.length
                  }}</b-badge></span></b-dropdown-item>
            <b-dropdown-item href="#" @click="Logout">
              <b-icon icon="box-arrow-right"></b-icon>&nbsp;Log
              out</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item :to="{ name: 'MetadataUtility' }" active-class="active" v-if="!is_email_sender">Metadata
            utility</b-nav-item>
          <b-nav-item href="https://bforcure.atlassian.net/wiki/x/N4BRBg" target="_blank"
            active-class="active">Help</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid>
      <router-view :is-admin="is_admin" :is-archive="is_archive_viewer"></router-view>
    </b-container>
    <b-modal v-model="show_message" :title="modal_title" ok-only @ok="reloadPage">{{ modal_message }}</b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Home from "./Home.vue";
import Dashboard from "./Dashboard.vue";
import DataVisualization from "./DataVisualization.vue";
import MachinesTable from "./MachinesTable.vue";
import BatchAdmin from "./BatchAdmin.vue";
import UserAdmin from "./UserAdmin.vue";
import DataUpload from "./DataUpload.vue";
import EmailAdmin from "./EmailAdmin.vue";
import UserProfile from "./UserProfile.vue";
import SingleRun from "./SingleRun.vue";
import MachineForm from "./components/MachineForm.vue";
import MetadataUtility from "./MetadataUtility.vue";
import InteractiveMap from "./InteractiveMap.vue";

import Vue from "vue";
import Router from "vue-router";
const password_compliant = [
  "Be at least 8 characters long",
  "Have at least 1 upper-case letter",
  "Have at least 1 lower-case letter",
  "Have at least 1 digit",
  "Have at least 1 special character",
];
const password_compliant_regex =
  /(?=^.{8,}$)(?=.*\d)(?=.*[$-/:-?{-~!"^_`[\]]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
const batch_fields = [
  {
    key: "first",
    label: "First chip ID",
    notnull: true,
  },
  {
    key: "last",
    label: "Last chip ID",
  },
  {
    key: "quantity",
    label: "Quantity",
  },
  {
    key: "product_reference",
    label: "Product reference",
    notnull: true,
    autocomplete: true
  },
  {
    key: "date",
    label: "Date",
    sortable: true,
  },
  {
    key: "producer",
    label: "Producer",
  },
  {
    key: "intended_use",
    label: "Intended Use",
  },
  {
    key: "chip_type",
    label: "Chip type",
    options: ["IVD", "RUO", "COVID-19", "Control"],
  },
  {
    key: "status",
    label: "Status",
    options: ["Ready to be used", "Quarantine", "To be liberated", "Scrap"],
  },
  {
    key: "glue_batch_number",
    label: "Glue batch number",
  },
  {
    key: "alu_batch_number",
    label: "Aluminium batch number",
  },
  {
    key: "pp_film_batch_number",
    label: "PP film batch number",
  },
  {
    key: "holder_batch_number",
    label: "Holder batch number",
  },
  {
    key: "window_batch_number",
    label: "Window batch number",
  },
  {
    key: "film_batch_number",
    label: "PCR film batch number",
  },
  {
    key: "electrostatic_film_batch_number",
    label: "Electrostatic film batch number",
  },
  {
    key: "datamatrix_label_batch_number",
    label: "Datamatrix label batch number",
  },
  {
    key: "process",
    label: "Process",
  },
  {
    key: "comments",
    label: "Comments",
  },
];
const infos_fields = [
  {
    key: "selected",
    label: "Selected",
    tdClass: "align-middle text-center",
  },
  {
    key: "machine_name",
    label: "Machine name",
    sortable: true,
  },
  {
    key: "ananke_id",
    label: "Chronos ID",
    sortable: true,
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "start",
    label: "Date",
    sortable: true,
    formatter: (value) => {
      return value.split(".")[0];
    },
  },
  {
    key: "experiment_name",
    label: "Name",
  },
  {
    key: "duration",
    label: "Duration",
  },
  {
    key: "configuration_name",
    label: "Configuration name",
  },
  {
    key: "configuration_version",
    label: "Configuration version",
  },
  {
    key: "user",
    label: "Chronos user",
  },
  {
    key: "chronos_version",
    label: "Chronos version",
  },
  {
    key: "chip_barcode",
    label: "Chip barcode",
  },
  {
    key: "batch_range",
    label: "Batch ID",
  },
  {
    key: "samples",
    label: "Samples",
  },
  {
    key: "reagent",
    label: "Reagent",
  },
];
Vue.use(Router);
const routes = [
  {
    name: "Home",
    path: "/",
    component: Home,
    meta: { title: "Home" },
    props: { show_reset_password: false },
  },
  {
    name: "ResetPassword",
    path: "/user/reset",
    component: Home,
    meta: { title: "Password reset" },
    props: { show_reset_password: true },
  },
  {
    name: "ChangePassword",
    path: "/user/password",
    component: Home,
    meta: { title: "Password change" },
    props: (route) => ({
      token: route.query.token,
      password_compliant: password_compliant,
      password_compliant_regex: password_compliant_regex,
    }),
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    meta: { title: "Dashboard" },
    props: {
      batch_fields: batch_fields,
      infos_fields: infos_fields,
    },
  },
  {
    name: "InteractiveMap",
    path: "/data/interactive-map",
    component: InteractiveMap,
    meta: { title: "Interactive Map" },
  },
  {
    name: "DataVisualization",
    path: "/data/visualization",
    component: DataVisualization,
    meta: { title: "Advanced data viz" },
  },
  {
    name: "MachinesTable",
    path: "/machines",
    component: MachinesTable,
    meta: { title: "Machines table" },
    children: [
      {
        name: "MachineForm",
        path: "add/:prefilledName?/:prefilledPublicKey?",
        component: MachineForm,
        props: true,
      },
    ],
  },
  {
    name: "BatchAdmin",
    path: "/admin/batch",
    component: BatchAdmin,
    meta: { title: "Batches administration" },
    props: { fields: batch_fields },
  },
  {
    name: "UserAdmin",
    path: "/admin/users",
    component: UserAdmin,
    meta: { title: "Users administration" },
    props: {
      password_compliant: password_compliant,
      password_compliant_regex: password_compliant_regex,
    },
  },
  {
    name: "DataUpload",
    path: "/admin/data",
    component: DataUpload,
    meta: { title: "Data upload" },
  },
  {
    name: "EmailAdmin",
    path: "/admin/email",
    component: EmailAdmin,
    meta: { title: "Email configuration" },
  },
  {
    name: "UserProfile",
    path: "/user/profile",
    component: UserProfile,
    meta: { title: "User profile" },
    props: {
      password_compliant: password_compliant,
      password_compliant_regex: password_compliant_regex,
    },
  },
  {
    name: "SingleRun",
    path: "/run/:machine_name/:ananke_id",
    component: SingleRun,
    props: (route) => ({
      machine_name: route.params.machine_name,
      ananke_id: route.params.ananke_id,
      infos_fields: infos_fields,
      batch_fields: batch_fields,
    }),
  },
  {
    name: "MetadataUtility",
    path: "/metadata-utility",
    component: MetadataUtility,
    meta: { title: "Metadata utility" },
  },
  {
    path: "*",
  },
];
const router = new Router({
  routes,
  mode: "history",
});
router.beforeEach((to, from, next) => {
  if (to.name === undefined) {
    document.title = "GAIA Browser - Not found";
    next({ name: "Home" });
  } else {
    if (to.name == "SingleRun") {
      document.title =
        "GAIA Browser - " +
        to.params.machine_name +
        " - " +
        to.params.ananke_id;
    } else {
      document.title = "GAIA Browser - " + to.meta.title;
    }
    next();
  }
});

export default {
  name: "App",
  router: router,
  data: function () {
    return {
      app_version: "",
      is_authenticated: false,
      is_admin: false,
      is_chip_admin: false,
      is_archive_viewer: false,
      is_email_sender: false,
      user_email: undefined,
      user_group: undefined,
      last_connection: null,
      active: true,
      active_timeout: null,
      show_message: false,
      modal_title: "",
      modal_message: "",
      locked_machines: [],
      alert_message: "",
    };
  },
  watch: {
    $route() {
      this.getUserInfo();
    },
    is_authenticated: function () {
      if (this.is_authenticated) {
        this.getAppVersion();
      }
    },
  },
  methods: {
    async getAppVersion() {
      clearTimeout(this.active_timeout);
      const app_version = (await axios
        .get("/api/v1/infos/version")).data
      this.alert_message = "";
      if (this.app_version !== "" && this.app_version !== app_version) {
        this.show_message = true;
        this.modal_title = "New version";
        this.modal_message =
          "GAIA has been updated to a new version (" +
          app_version +
          "). Please reload this page.";
      } else {
        this.app_version = app_version;
        this.getUserInfo();
      }
    },
    async getUserInfo() {
      try {
        const user_info = (await axios.get("/api/v1/user/info")).data
        this.is_authenticated = true;
        this.is_admin = user_info.profile.role == "admin";
        this.is_archive_viewer = ["admin", "archive"].includes(user_info.profile.role)
        this.is_chip_admin = ["admin", "production"].includes(
          user_info.profile.role
        );
        this.is_email_sender = user_info.profile.role == "email_sender";
        this.user_email = user_info.email;
        this.user_group = user_info.group;
        this.last_connection = user_info.last_connection;
        if (this.is_email_sender && this.$route.name !== "EmailAdmin") {
          this.$router.push({ name: "EmailAdmin" });
        }
        if (this.$route.name == "EmailAdmin" && !this.is_email_sender) {
          this.$router.push({ name: "Home" });
        }
        if (this.$route.name == "Home") {
          if (this.$route.query.dest !== undefined) {
            this.$router.push({ path: this.$route.query.dest });
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        } else {
          if (!this.is_chip_admin) {
            if (this.$route.name == "BatchAdmin") {
              this.$router.push({ name: "Dashboard" });
            }
          }
          if (!this.is_admin) {
            if (this.$route.name == "UserAdmin") {
              this.$router.push({ name: "Dashboard" });
            }
            if (this.$route.name == "DataUpload") {
              this.$router.push({ name: "Dashboard" });
            }
          }
        }
        if (this.active) {
          this.active_timeout = setTimeout(this.getAppVersion, 5000);
        }
        this.locked_machines = [
          ...new Set(
            user_info.accesses
              .filter((access) => access.locked)
              .map((access) => access.machine_name)
          ),
        ];
      } catch (error) {
        if (!this.is_authenticated) {
          this.is_authenticated = false;
          if (
            this.$route.name !== "Home" &&
            this.$route.name !== "ChangePassword" &&
            this.$route.name !== "ResetPassword"
          ) {
            this.$router.push({
              name: "Home",
              query: { dest: this.$route.path },
            });
          }
        } else {
          this.show_message = true;
          this.modal_title = "Expired session";
          this.modal_message = "Your session has expired. Please login.";
        }
      }
    },
    async Logout(event) {
      event.preventDefault();
      window.stop()
      try {
        await axios
          .put("/api/v1/user/logout")
      } catch (error) {
        console.warn("Could not logout")
      }
      location.reload()
    },
    gotFocus: function () {
      this.active = true;
      this.getAppVersion();
    },
    lostFocus: function () {
      this.active = false;
    },
    reloadPage: function () {
      document.location.reload();
    },
  },
  created: function () {
    this.getAppVersion();
  },
  mounted() {
    window.addEventListener("focus", this.gotFocus);
    window.addEventListener("blur", this.lostFocus);
  },
  beforeDestroy() {
    window.removeEventListener("focus", this.gotFocus);
    window.removeEventListener("blur", this.lostFocus);
  },
};
</script>

<style>
.router_link_unstyle {
  color: inherit;
  text-decoration: inherit;
}

h4,
h2 {
  margin-top: 0.8rem !important;
}

tr.table-active {
  background-color: rgba(114, 34, 129, 1) !important;
}

tr.table-active:hover {
  background-color: rgba(114, 34, 129, 0.75) !important;
}

tr.table-active td:not(.b-table-sticky-column) {
  color: white !important;
}

tr.row-error:not(.table-active) {
  background-color: rgba(220, 53, 69, 1) !important;
}

table.table-hover>tbody>tr.row-error:hover:not(.table-active) {
  background-color: rgba(220, 53, 69, 0.75) !important;
}

tr.row-error:not(.table-active) td {
  color: white !important;
}

tr.row-warning:not(.table-active) {
  background-color: rgba(255, 193, 7, 1) !important;
}

table.table-hover>tbody>tr.row-warning:hover:not(.table-active) {
  background-color: rgba(255, 193, 7, 0.75) !important;
}

tr.row-valid:not(.table-active) {
  background-color: rgba(40, 167, 69, 1) !important;
}

table.table-hover>tbody>tr.row-valid:hover:not(.table-active) {
  background-color: rgba(40, 167, 69, 0.75) !important;
}

tr.row-valid:not(.table-active) td {
  color: white !important;
}
</style>
