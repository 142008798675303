export async function* runOneBatchAtATime({
  batchSize,
  operations,
  onSilentError,
  failSilently = false,
}) {
  let cancelled = false;

  for (
    let index = 0;
    index <= operations.length && !cancelled;
    index += batchSize
  ) {
    let results;

    const rawResults = await Promise[failSilently ? 'allSettled' : 'all'](
      operations.slice(index, index + batchSize)
        .map(operation => operation())
    )

    if (failSilently) {
      results = [];

      for (const { status, value, reason: error } of rawResults) {
        if (status === 'fulfilled') {
          results.push(value);
        } else {
          onSilentError?.(error);
        }
      }
    } else {
      results = rawResults;
    }

    cancelled = yield results;
  }
}

export async function runAllInBatches(...args) {
  const allResults = []

  for await (const results of runOneBatchAtATime(...args)) {
    allResults.push(...results);
  }

  return allResults;
}
