<template>
  <div>
    <h3>
      Runs filtering
      <small><help-view>Filter the runs with the meta-datas.</help-view></small>
    </h3>
    <b-form-group label="Meta key" label-for="filter_meta_key" label-cols-xl="3">
      <b-form-input id="filter_meta_key" type="text" v-model="meta_key" list="filter_avail_keys" autocomplete="off"
        :formatter="keyFormatter" @input="fetchMetaValues" placeholder="Meta key" :disabled="disabled"></b-form-input>
      <datalist type="text" id="filter_avail_keys">
        <option v-for="key in avail_keys" :key="key">{{ key }}</option>
      </datalist>
    </b-form-group>
    <b-form-group label="Meta value" label-for="filter_meta_value" label-cols-xl="3">
      <b-form-input id="filter_meta_value" type="text" v-model="meta_value" list="filter_avail_values"
        autocomplete="off" placeholder="Meta value" :disabled="isValueInputDisabled"></b-form-input>
      <datalist type="text" id="filter_avail_values">
        <option v-for="key in avail_values" :key="key">
          {{ key }}
        </option>
      </datalist>
    </b-form-group>
    <b-button block variant="success" class="mb-2" :disabled="isMetaAddDisabled" @click="addMeta"><b-icon icon="plus"
        class="mr-1"></b-icon>Add</b-button>
    <b-table :items="meta_datas" bordered striped small class="text-nowrap mb-1"></b-table>
    <b-button block variant="danger" class="mb-2" v-if="meta_datas.length > 0" @click="clearMetaData"
      :disabled="disabled"><b-icon icon="trash" class="mr-1"></b-icon>Reset</b-button>
    <b-overlay :show="filtering_run_data">
      <p class="text-center font-weight-bold">
        {{ run_data.length }} found run<span v-if="run_data.length > 1">s</span>
      </p>
    </b-overlay>
    <b-button block variant="primary" class="mb-2" v-if="isDataFetchable" @click="fetchRunsData"
      :disabled="disabled"><b-icon icon="arrow-clockwise" class="mr-1"></b-icon>Fetch data</b-button>
    <b-progress :value="fetchedRunIdsCount" :max="fetchedRunIdsMax" v-if="fetching_run_data" class="mb-2"
      animated></b-progress>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "../HelpView.vue";
import { runAllInBatches } from "../../utils/async";

const { CancelToken } = axios;

export default {
  name: "RunsFilterer",
  components: { HelpView },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      avail_keys: [],
      meta_key: "",
      meta_datas: [],
      avail_values: [],
      meta_value: "",
      fetching_run_data: false,
      filtering_run_data: false,
      fetchedRunIdsCount: 0,
      fetchedRunIdsMax: 0,
      run_data: [],
      run_data_list: [],
      run_data_fetch: [],
      analysis_versions: [],
      analysis_version: ""
    };
  },

  watch: {
    meta_datas: async function () {
      this.source.cancel();
      this.source = CancelToken.source();
      this.fetching_run_data = false;
      this.filterRuns();
      await this.fetchMetaKeys();
      this.meta_key = "";
      this.meta_value = "";
    },
    fetching_run_data: function () {
      this.$emit("fetching", this.fetching_run_data);
    },
  },

  methods: {
    async fetchMetaValues() {
      this.meta_value = ""
      if (this.meta_key.length > 0) {
        this.avail_values = (await axios.get("/api/v1/meta/values", {
          params: { key: this.meta_key, group_name: this.selected_group }
        })).data.filter(ele => !this.meta_datas.filter(
          ele2 => ele2.key == this.meta_key).map(ele3 => ele3.value).includes(ele))
      }
    },

    async fetchMetaKeys() {
      this.meta_key = ""
      this.meta_value = ""
      this.avail_keys = (await axios
        .get("/api/v1/meta/keys", {
          params: { group_name: this.selected_group },
        })).data.filter(ele => !this.meta_datas.map(ele => ele.key).includes(ele))
    },

    keyFormatter(value) {
      return value
        .toUpperCase()
        .replace(" ", "_")
        .replace(/[^A-Z0-9_]/, "");
    },

    addMeta() {
      this.meta_datas.push({ key: this.meta_key, value: this.meta_value });
      this.fetchMetaKeys();
    },

    async fetchRunInfo(run_id) {
      return (await axios.get("/api/v1/run/info", {
        params: { run_id: run_id },
        cancelToken: this.source.token
      })).data
    },

    async filterRuns(offset = 0, limit = 100) {
      if (offset == 0) {
        if (this.filtering_run_data) {
          this.source.cancel();
          this.source = CancelToken.source();
          this.filtering_run_data = false;
        }
        this.filtering_run_data = true;
        this.run_data = [];
        this.temp_run_data = [];
      }
      if (this.meta_datas.length > 0) {
        const params = {
          meta_datas: JSON.stringify(this.meta_datas),
          offset: offset,
          limit: limit,
        };
        try {
          const run_ids = (await axios
            .get("/api/v1/run/ids", {
              params: params,
              cancelToken: this.source.token,
            })).data
          var fetches = []
          for (let run_id of run_ids) {
            fetches.push(() => this.fetchRunInfo(run_id))
          }
          const run_infos = await runAllInBatches({
            batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
            operations: fetches,
          })
          this.temp_run_data.push(...run_infos)
          if (run_infos.length >= limit) {
            this.filterRuns((offset = offset + limit));
          } else {
            this.filtering_run_data = false;
            this.run_data = this.temp_run_data.map((elem) => {
              return {
                run_id: elem.id,
                info: elem,
              };
            });
            this.temp_run_data = [];
          }
        }
        catch (error) {
          if (axios.isCancel(error)) {
            console.warn(
              "Request cancelled due to changed filtering options"
            );
          }
        }
      }
      else {
        this.filtering_run_data = false
      }
    },

    async fetchRunsData() {
      this.$emit("clear-data");
      this.source?.cancel();
      this.source = CancelToken.source();

      this.run_data_fetch = {};
      this.fetchedRunIdsCount = 0;
      this.fetchedRunIdsMax = this.run_data.length * 2;
      this.run_data_list = [];
      this.fetching_run_data = true;
      const run_ids = this.run_data.map(ele => ele.run_id)

      var fetches = [];
      for (let run_id of run_ids) {
        fetches.push(() => this.fetchRunData(run_id, "meta"))
      }
      await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      });

      this.run_data.forEach((elem) => {
        this.run_data_list.push({
          run_id: elem.run_id,
          data: {
            info: elem.info,
            meta: this.run_data_fetch[elem.run_id].meta ? this.run_data_fetch[elem.run_id].meta : [],
          },
        });
      });
      this.$emit("data", this.run_data_list);

      fetches = [];
      for (let run_id of run_ids) {
        fetches.push(() => this.fetchRunData(run_id, "analysis"))
      }
      await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      });

      fetches = [];
      for (let run_id of run_ids) {
        fetches.push(() => this.fetchRunData(run_id, "analysis-versions"))
      }
      await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      });
      this.fetching_run_data = false;
      this.fetchedRunIdsCount = this.fetchedRunIdsMax;

      this.analysis_versions = []
      for (let run_data of this.run_data_list) {
        if (run_data.data.analysis !== undefined) {
          const versions = Object.keys(run_data.data.analysis)
          for (let version of versions) {
            if (!this.analysis_versions.includes(version)) {
              this.analysis_versions.push(version)
            }
          }
        }
      }
      this.analysis_version = this.analysis_versions[0]
      this.$emit("versions", { "versions": this.analysis_versions, "selected": this.analysis_version })
    },

    async fetchRunData(run_id, data_type) {
      const uri =
        data_type == "meta" ? "/api/v1/meta/get" : "/api/v1/run/" + data_type;
      try {
        const response = await axios
          .get(uri, {
            params: { run_id: run_id },
            cancelToken: this.source.token,
          })
        let run_index
        switch (data_type) {
          case "meta":
            response.data.forEach((data) => {
              this.run_data_fetch[run_id] ??= {};
              this.run_data_fetch[run_id][data_type] ??= [];
              this.run_data_fetch[run_id][data_type].push(data);
            });
            this.fetchedRunIdsCount++
            break;
          case "analysis":
            break;
          case "analysis-versions":
            run_index = this.run_data_list.findIndex(
              (run) => run.run_id == run_id
            );
            this.run_data_list[run_index].data.analysis = {};
            for (let version of response.data) {
              this.run_data_list[run_index].data.analysis[version.version] = (await axios
                .get("/api/v2/run/analysis", {
                  params: { run_id: run_id, version: version.version, signature: version.signature },
                  cancelToken: this.source.token,
                })).data
              if (this.run_data_list[run_index].data.analysis[version.version] !== undefined) {
                this.run_data_list[run_index].data.analysis[version.version]["date"] = version.date
                if (Object.keys(this.run_data_list[run_index].data.analysis[version.version]?.data).includes("interpretation")) {
                  this.run_data_list[run_index].data.analysis[version.version].interpretation = this.run_data_list[
                    run_index].data.analysis[version.version].data.interpretation
                  delete this.run_data_list[run_index].data.analysis[version.version].data.interpretation
                }
              }
            }
            this.$emit("data", this.run_data_list);
            this.fetchedRunIdsCount++;
        }
      }
      catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled due to a refresh fetching");
        }
      }
    },

    clearMetaData() {
      this.meta_datas = [];
    },
  },

  computed: {
    isValueInputDisabled() {
      return this.meta_key.length === 0 || this.disabled;
    },

    isMetaAddDisabled() {
      return (
        this.meta_key.length === 0 ||
        this.meta_value.length === 0 ||
        this.disabled
      );
    },

    isDataFetchable() {
      return this.run_data.length > 0 && !this.fetching_run_data;
    },
  },

  created: async function () {
    this.source = CancelToken.source();
    this.run_data_fetch = {};
    this.run_data_list = [];
    await this.fetchMetaKeys();
  },
};
</script>
