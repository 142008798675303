<template>
  <div>
    <h4>
      Runs list
      <small class="text-secondary" v-if="runs_infos.length > 0">
        ({{ runs_infos.length
        }}<b-icon class="ml-1" v-if="fetching_filt" icon="cloud-download" animation="cylon-vertical"></b-icon>)
      </small>
      <small><help-view>You can see here the runs list and select which ones you want to
          visualize. The list is refreshed every 5 minutes.</help-view></small>
    </h4>
    <b-container fluid>
      <b-button-toolbar>
        <b-button-group size="sm" class="mx-1">
          <b-pagination v-model="current_page" :total-rows="runs_infos.length" :per-page="per_page"
            :disabled="runs_infos.length == 0" @input="runs_infos = filtered_runs.map((ele) => ele)"
            size="sm"></b-pagination>
        </b-button-group>
        <b-button-group size="sm" class="mx-1">
          <b-dropdown size="sm" text="Select" :disabled="runs_infos.length == 0">
            <b-dropdown-form class="text-nowrap">
              <b-form-checkbox v-model="range_mode" switch>Range selection</b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
        </b-button-group>
        <b-button-group size="sm" class="mx-1">
          <b-button v-if="isFetchingFiltersOrRuns" @click="cancelFetch"><b-icon
              icon="x-circle"></b-icon>&nbsp;Cancel</b-button>
          <b-button v-else @click="reloadRuns" variant="primary"><b-icon
              icon="arrow-clockwise"></b-icon>&nbsp;Reload</b-button>
          <b-button variant="danger" @click="resetFilters">Reset&nbsp;<b-icon icon="arrow-repeat"></b-icon></b-button>
        </b-button-group>
        <b-button-group size="sm" class="mx-1" v-if="selected_runs.length > 0">
          <b-button variant="success" @click="addToDataViz"><b-icon icon="plus-circle" class="mx-1"></b-icon>Add to
            bucket
            ({{
        selected_runs.length
      }})</b-button>
        </b-button-group>
        <edit-multiple-tags v-if="hasRuns" :run_ids="taggeableRuns" :label="tagButtonLabel"></edit-multiple-tags>
      </b-button-toolbar>
      <b-table ref="runsListTable" striped hover small bordered class="text-nowrap" sticky-header="320px"
        :items="runs_infos" :fields="fields" :busy="(isFetchingFiltersOrRuns && runs_infos.length == 0) || error"
        :sort-compare="sortRuns" sort-by="start" sort-desc no-sort-reset selectable :select-mode="setSelectMode()"
        show-empty :per-page="per_page" :current-page="current_page" :tbody-tr-class="rowClass" :tbody-tr-attr="rowAttr"
        @sort-changed="runs_infos = filtered_runs.map((ele) => ele)" @row-selected="onRowSelected">
        <template #table-busy>
          <template v-if="isFetchingFiltersOrRuns">
            <div class="text-center">
              <strong>&nbsp;Fetching runs<b-icon class="ml-1" icon="three-dots" animation="cylon"></b-icon></strong>
            </div>
          </template>
          <template v-if="error">
            <div class="text-center text-danger">
              <b-icon icon="x" font-scale="3" class="align-middle"></b-icon>
              <strong>&nbsp;Something went wrong with the server. Please
                retry.</strong>
            </div>
          </template>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected"><b-icon icon="check-square"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="square"></b-icon>
          </template>
        </template>
        <template #head(selected)>
          <template v-if="all_selected"><b-icon icon="check-square" @click="clearSelected"
              style="cursor: pointer"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="square" @click="selectAllRows" style="cursor: pointer"></b-icon>
          </template>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./HelpView.vue";
import { formatHealthMessage } from "../utils/message";
import EditMultipleTags from "./data-viz/data-details/EditMultipleTags.vue";

const CancelToken = axios.CancelToken;

export default {
  name: "RunsList",
  props: [
    "filtered_runs",
    "fetching_filter",
    "select_runs",
    "fields",
    "batch_fields",
    "batches_infos",
  ],
  computed: {
    fetching_filt: function () {
      return this.fetching_filter;
    },

    tagButtonLabel() {
      return "Tag" + (this.hasSelectedRuns ? " selected" : " all");
    },

    hasRuns() {
      return this.runs_infos.length > 0;
    },

    hasSelectedRuns() {
      return this.selected_runs.length > 0;
    },

    taggeableRuns() {
      return this.hasSelectedRuns
        ? this.selected_runs.map((run) => run.id)
        : this.runs_infos;
    },

    isFetchingFiltersOrRuns() {
      return this.fetching_filt;
    },

    paginationOffset() {
      return (this.current_page - 1) * this.per_page;
    },
  },
  data: function () {
    return {
      runs_infos: [],
      loadedRunInfosCount: 0,
      selected_runs: this.select_runs,
      dataviz_runs: [],
      error: false,
      source: CancelToken.source(),
      range_mode: false,
      all_selected: false,
      current_page: 1,
      per_page: 50,
    };
  },
  watch: {
    filtered_runs: function () {
      if (this.filtered_runs.length == 0) {
        this.runs_infos = this.filtered_runs
        this.all_selected = false
      }
    },
    dataviz_runs: function () {
      this.$emit("update:select_runs", this.dataviz_runs);
    },
    select_runs: function () {
      this.dataviz_runs = this.select_runs;
      if (this.dataviz_runs.length == 0) {
        this.$refs.runsListTable.clearSelected();
      }
    },
  },
  methods: {
    setSelectMode() {
      if (this.range_mode) {
        return "range";
      } else {
        return "multi";
      }
    },

    resetPage() {
      this.runs_infos = [];
      this.loadedRunInfosCount = 0;
      this.cancelled = false;
      this.current_page = 1;
      this.error = false;
      this.all_selected = false;
    },

    sortRuns(a, b, key, sortDesc) {
      if (key == "Date") {
        const dateA = Date.parse(a);
        const dateB = Date.parse(b);
        if (sortDesc) {
          return dateA > dateB;
        } else {
          return dateB < dateA;
        }
      } else {
        return false;
      }
    },

    selectAllRows() {
      this.$refs.runsListTable.selectAllRows();
    },

    clearSelected() {
      this.$refs.runsListTable.clearSelected();
    },

    onRowSelected(items) {
      this.selected_runs = items;
      let start = this.paginationOffset;
      let end = start + this.per_page;
      if (items.length == this.runs_infos.slice(start, end).length) {
        this.all_selected = true;
      } else {
        this.all_selected = false;
      }
    },

    addToDataViz() {
      var already_added_run_ids = this.dataviz_runs.map((ele) => ele.id);
      var data_to_add = this.selected_runs.filter(
        (ele) => !already_added_run_ids.includes(ele.id)
      );
      this.dataviz_runs = this.dataviz_runs.concat(data_to_add);
      this.$refs.runsListTable.clearSelected();
    },

    resetFilters: function () {
      this.$emit("reset_filters");
    },

    reloadRuns: function () {
      this.$emit("reload_runs");
    },

    cancelFetch() {
      this.source.cancel();
      this.cancelled = true;
      this.$emit("cancel_runs_load");
    },

    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (
        item.health_status === "CRITICAL" ||
        item.health_status === "BLOCK_MACHINE"
      ) {
        return "row-error font-weight-bold";
      }
      if (item.run_status === "INVALID") {
        return "row-error font-weight-bold";
      }
      if (item.wrong_chip) {
        return "row-error font-weight-bold";
      }
      if (item.chip_id?.length > 0 && (!item.batch_id || item.batch_id.length == 0)) {
        return "row-error font-weight-bold";
      }
      if (item.health_status === "WARNING") {
        return "row-warning font-weight-bold";
      }
    },

    rowAttr(item, type) {
      if (!item || type !== "row") return;
      if (
        (item.health_status === "CRITICAL" ||
          item.health_status === "BLOCK_MACHINE") &&
        item.last_message !== null
      ) {
        return {
          title: "Last message: " + formatHealthMessage(item.last_message),
        };
      }
      if (item.run_status === "INVALID") {
        return {
          title: "Invalid run status",
        };
      }
      if (item.wrong_chip) {
        return {
          title: "Wrong chip",
        };
      }
      if (item.chip_id?.length > 0 && (!item.batch_id || item.batch_id.length == 0)) {
        return {
          title: "Chip ID without batch"
        };
      }
      if (item.health_status === "WARNING" && item.last_message !== null) {
        return {
          title: "Last message: " + formatHealthMessage(item.last_message),
        };
      }
    },
  },

  components: {
    HelpView,
    EditMultipleTags,
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>