<template>
  <div>
    <h2>
      Chip batches administration<small class="p-1"><help-view>This page allows to manage chip batches
          data.</help-view></small>
    </h2>
    <hr />
    <b-container fluid>
      <b-button-toolbar>
        <b-button-group class="mx-1">
          <b-pagination v-model="current_page" :total-rows="filteredBatchInfos.length" :per-page="per_page"
            :disabled="fetching"></b-pagination>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button @click="fetchBatchesInfos()" class="border"><b-icon icon="arrow-clockwise" class="mr-1"
              :disabled="fetching"></b-icon>Reload</b-button>
        </b-button-group>
        <b-button-group class="mr-1" v-if="filteredBatchInfos.length > 0">
          <b-button @click="downloadAsCSV()" title="Download as CSV" class="border"><b-icon icon="download"
              class="mr-1"></b-icon>Download</b-button>
        </b-button-group>
        <b-button-group class="mr-1" v-if="filteredBatchInfos.length > 0">
          <b-button @click="copyAsCSV()" title="Copy as CSV to the clipboard" class="border"><b-icon icon="files"
              class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="success" @click="showEdit(true)" class="border"><b-icon icon="plus"
              class="mr-1"></b-icon>Add</b-button>
          <b-button variant="success" class="border" @click="showEdit(true, true)" v-if="selected_batch != null"><b-icon
              icon="files" class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-button-group class="mx-1" v-if="selected_batch != null">
          <b-button variant="primary" @click="showEdit(false)" class="border"><b-icon icon="pen"
              class="mr-1"></b-icon>Edit</b-button>
          <b-button variant="danger" @click="show_delete = true" class="border"><b-icon icon="trash"
              class="mr-1"></b-icon>Delete</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-form-input type="text" v-model="search" :debounce="500" placeholder="Search a batch"></b-form-input>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-form-input type="text" v-model="searchchip" :debounce="500" placeholder="Search by chip"></b-form-input>
        </b-button-group>
      </b-button-toolbar>
      <b-table :items="filteredBatchInfos" striped hover bordered :busy="fetching" class="text-nowrap" show-empty
        :fields="fields" sticky-header="750px" :per-page="per_page" :current-page="current_page" sort-by="date" sort-desc
        selectable select-mode="single" :tbody-tr-class="rowClass" @row-selected="onRowSelected">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-progress :value="current_batches" :max="total_batches" animated></b-progress>
        </template>
        <template #cell()="data">
          <span v-if="data.field.bool">{{ data.value ? "Yes" : "No" }}</span>
          <span v-else>{{ data.value }}</span>
        </template>
      </b-table>
    </b-container>
    <hr />
    <b-modal v-model="show_edit" title="Batch editor" hide-footer size="lg">
      <b-overlay :show="saving">
        <div v-if="edit_batch !== null">
          <b-form-group v-for="field in fields" :key="field.key" :label="field.label" :label-for="field.key"
            label-cols-xl="3">
            <span v-if="!['date', 'size'].includes(field.key) && !field.bool">
              <b-form-select :id="field.key" v-model="edit_batch[field.key]" v-if="field.options !== undefined"
                :options="field.options" :state="notEmpty(field)"></b-form-select>
              <b-form-input :id="field.key" type="text" v-model="edit_batch[field.key]" v-else :state="notEmpty(field)"
                :list="autoComplete(field)" :autocomplete="field.autocomplete ? 'off' : 'on'">
                ></b-form-input>
              <datalist :id="autoComplete(field)" v-if="field.autocomplete">
                <option v-for="key in new Set(
                  batch_infos.map((ele) => ele[field.key])
                )" :key="key">
                  {{ key }}
                </option>
              </datalist>
            </span>
            <b-form-input v-if="['size'].includes(field.key)" :id="field.key" type="number"
              v-model="edit_batch[field.key]" :state="true"></b-form-input>
            <b-form-datepicker :id="field.key" v-if="field.key == 'date'" v-model="edit_batch[field.key]" today-button
              :state="true"></b-form-datepicker>
            <b-form-checkbox v-model="edit_batch[field.key]" v-if="field.bool" :id="field.key" switch
              class="mt-2"></b-form-checkbox>
          </b-form-group>
          <hr />
          <b-row class="text-center text-danger" v-if="error != null"><b-col cols="12"><strong>{{ error
          }}</strong></b-col></b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <b-button @click="show_edit = false" variant="danger" class="mx-1"><b-icon icon="x"
                  class="mr-1"></b-icon>Cancel</b-button>
              <b-button variant="primary" class="mx-1" :disabled="checkState()" @click="saveBatch"><b-icon icon="check"
                  class="mr-1"></b-icon>Save</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal v-model="show_delete" title="Delete batch" hide-footer>
      <b-overlay :show="saving">
        <p class="text-center">
          Are you sure to delete this batch? This action is irreversible.
        </p>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="show_delete = false" variant="danger" class="mx-1"><b-icon icon="x"
                class="mr-1"></b-icon>Cancel</b-button>
            <b-button variant="primary" class="mx-1" @click="deleteBatch"><b-icon icon="check"
                class="mr-1"></b-icon>OK</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./components/HelpView.vue";
import { runAllInBatches } from "./utils/async";
const CancelToken = axios.CancelToken;
const now = new Date();
const nowstring = [now.getFullYear(), now.getMonth() + 1, now.getDate()].join(
  "-"
);

const booleansLabels = {
  true: "yes",
  false: "no",
};

export default {
  name: "BatchAdmin",
  props: ["fields"],
  data: function () {
    return {
      batch_infos: [],
      total_batches: 0,
      current_batches: 0,
      source: CancelToken.source(),
      fetching: false,
      per_page: 50,
      current_page: 1,
      selected_batch: null,
      show_edit: false,
      show_delete: false,
      edit_batch: null,
      saving: false,
      error: null,
      search: "",
      searchchip: "",
    };
  },
  computed: {
    filteredBatchInfos() {
      let filtered = this.batch_infos;
      if (this.search)
        filtered = filtered.filter((batch_info) =>
          Object.values({ ...batch_info, id: undefined })
            .filter((value) => value)
            .map((value) =>
              typeof value === "boolean"
                ? booleansLabels[value]
                : String(value).toLowerCase()
            )
            .some((value) => value.includes(this.search.toLowerCase()))
        );
      if (this.searchchip)
        filtered = filtered.filter(
          (value) =>
            this.searchchip.toLowerCase().split("|")[0] >=
            value.first.toLowerCase() &&
            this.searchchip.toLowerCase().split("|")[0] <=
            value.last.toLowerCase()
        );
      return filtered;
    },
  },
  created: function () {
    this.fetchBatchesInfos();
  },
  methods: {
    async fetchBatchInfo(batch_id) {
      const batch_info = (await axios.get("/api/v1/batch/info", {
        params: {
          batch_id: batch_id
        }
      })).data
      this.current_batches++
      return batch_info
    },
    async fetchBatchesInfos() {
      this.batch_infos = [];
      this.total_batches = 0;
      this.current_batches = 0;
      this.fetching = true;
      try {
        const batch_ids = (await axios.get("/api/v1/batch/ids")).data
        this.total_batches = batch_ids.length
        var fetches = []
        for (let batch_id of batch_ids) {
          fetches.push(() => this.fetchBatchInfo(batch_id))
        }
        this.batch_infos = await runAllInBatches({
          batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
          operations: fetches,
        })
        this.fetching = false
      }
      catch (error) {
        console.error("Could not get batch infos")
      }
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.selected_batch = items[0];
      } else {
        this.selected_batch = null;
      }
    },
    showEdit: function (newBatch, copy = false) {
      var edit_batch = {};
      if (newBatch) {
        for (let index in this.fields) {
          let field = this.fields[index];
          if (copy) {
            edit_batch[field.key] = this.selected_batch[field.key];
          } else {
            if (field.key == "date") {
              edit_batch[field.key] = nowstring;
            } else {
              edit_batch[field.key] = null;
            }
            if (field.bool) {
              edit_batch[field.key] = false;
            }
          }
        }
      } else {
        edit_batch.id = this.selected_batch.id;
        for (let index in this.fields) {
          let field = this.fields[index];
          edit_batch[field.key] = this.selected_batch[field.key];
        }
      }
      this.edit_batch = edit_batch;
      this.error = null;
      this.show_edit = true;
    },
    notEmpty: function (field) {
      var valid = true;
      if (field.notnull) {
        valid = this.edit_batch[field.key] != null;
        if (this.edit_batch[field.key] != null) {
          valid = this.edit_batch[field.key].length > 0;
        }
      }
      return valid;
    },
    autoComplete: function (field) {
      if (field?.autocomplete) {
        return "avail_values_" + field.key;
      }
      return undefined;
    },
    checkState: function () {
      var valid = true;
      for (let index in this.fields) {
        let field = this.fields[index];
        if (field.notnull) {
          if (this.edit_batch[field.key] == null) {
            valid = false;
          }
          if (this.edit_batch[field.key] != null) {
            if (this.edit_batch[field.key].length == 0) {
              valid = false;
            }
          }
        }
      }
      return !valid;
    },
    async saveBatch() {
      this.saving = true;
      this.error = null;
      if (this.edit_batch.id === undefined) {
        this.edit_batch.id = null
      }
      try {
        await axios
          .post("/api/v1/batch/add", this.edit_batch)
        this.show_edit = false
        this.fetchBatchesInfos()
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail;
        } else {
          this.error = "Internal server error.";
        }
      }
      this.saving = false
    },
    async deleteBatch() {
      this.saving = true;
      await axios
        .delete("/api/v1/batch/delete", {
          params: { batch_id: this.selected_batch.id },
        })
      this.saving = false;
      this.show_delete = false;
      this.fetchBatchesInfos();
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status !== "Ready to be used") {
        return "row-error font-weight-bold";
      }
    },
    exportAsCSV() {
      var column_names = this.fields.map((ele) => ele.key);
      var rows = this.filteredBatchInfos.map((batch_info) => {
        var row_data = [];
        for (let column_name of column_names) {
          row_data.push(batch_info[column_name]);
        }
        return row_data;
      });
      return [column_names].concat(rows);
    },
    downloadAsCSV() {
      var array_data = this.exportAsCSV();
      var csv_content =
        'data:text/csv;charset=utf-8,"' +
        array_data.map((ele) => ele.join('","')).join('"\n"') +
        '"';
      var encoded_uri = encodeURI(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        "gaia_batch_infos_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },

    copyAsCSV() {
      var vm = this;
      var csv_content =
        '"' +
        this.exportAsCSV()
          .map((ele) => ele.join('","'))
          .join('"\n"') +
        '"';
      navigator.clipboard.writeText(csv_content).then(
        () => (vm.dismiss_countdown = vm.total_secs),
        () => (vm.dismiss_countdown = vm.total_secs)
      );
    },
  },
  components: {
    HelpView,
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>