<template>
  <div>
    <h2>
      Advanced data visualization tool
      <small><help-view>This page allows to have an advanced data visualization.</help-view></small>
    </h2>
    <b-row class="border">
      <b-col md="4" class="border-right">
        <b-row>
          <b-col><runs-filterer @clear-data="clearData" @data="updateRunDataList" @fetching="updateFetchingRuns"
              :disabled="isRunSelectionDisabled" @versions="updateAnalysisVersions" /></b-col>
        </b-row>
        <b-row class="border-top">
          <b-col>
            <runs-list :run-data-list="run_data_list" :disabled="isRunSelectionDisabled"
              :runSelectionLimit="runSelectionLimit" :selectedPlotView="selectedPlotView" :marked-runs="markedRuns"
              :fetching="fetching" @select="updateDisplayRuns" :analysis-versions="analysis_versions"
              :analysis-version="analysis_version" @version="updateSelectedVersion" />
          </b-col>
        </b-row>
      </b-col>
      <b-col md="8">
        <data-plots ref="dataPlots" :display-runs="display_runs" @busy="disableRunSelection" @free="enableRunSelection"
          @plot-view-select="updateSelectedPlotView" @select-graph="setMarkedRuns" :selected-version="selected_version" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import HelpView from "./components/HelpView.vue";
import RunsFilterer from "./components/advanced-data-viz/RunsFilterer.vue";
import RunsList from "./components/advanced-data-viz/RunsList.vue";
import DataPlots from "./components/advanced-data-viz/DataPlots.vue";
import { LOG_VIEW } from "./constants/plot";

export default {
  name: "DataVisualization",

  data: function () {
    return {
      temp_run_ids: [],
      run_data: {},
      run_data_list: [],
      full_run_ids: [],
      display_runs: [],
      isRunSelectionDisabled: false,
      actualPlotView: undefined,
      selectedPlotView: undefined,
      markedRuns: undefined,
      fetching: false,
      analysis_versions: [],
      analysis_version: "",
      selected_version: ""
    };
  },

  watch: {
    display_runs() {
      this.markedRuns = this.markedRuns?.filter((run_id) =>
        this.display_runs_ids.includes(run_id)
      );
    },

    actualPlotView() {
      this.markedRuns = undefined;
    },
  },

  methods: {
    updateDisplayRuns(items) {
      this.display_runs = items;
    },

    clearData() {
      this.run_data_list = [];
      this.$refs.dataPlots.clearCache();
    },

    updateRunDataList(data) {
      this.run_data_list = data;
    },

    updateAnalysisVersions(versions) {
      this.analysis_versions = versions.versions
      this.analysis_version = versions.selected
    },

    updateFetchingRuns(fetching) {
      this.fetching = fetching;
    },

    disableRunSelection() {
      this.isRunSelectionDisabled = true;
    },

    enableRunSelection() {
      this.isRunSelectionDisabled = false;
    },

    updateSelectedPlotView({ actualPlotView, selectedPlotView }) {
      this.actualPlotView = actualPlotView;
      this.selectedPlotView = selectedPlotView;
    },

    updateSelectedVersion(version) {
      this.selected_version = version
    },

    setMarkedRuns({ run_ids }) {
      this.markedRuns = run_ids;
    },
  },

  computed: {
    runSelectionLimit() {
      return this.actualPlotView === LOG_VIEW
        ? this.$root.$options.config.LOG_PLOT_TRACES_LIMIT
        : Infinity;
    },

    display_runs_ids() {
      return this.display_runs.map((display_run) => display_run.run_id);
    },
  },

  components: {
    HelpView,
    RunsFilterer,
    RunsList,
    DataPlots,
  },
};
</script>