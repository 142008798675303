<template>
  <div class="d-inline-block">
    <b-button @click="showLogs()" size="sm" class="p-0 ml-1" variant="primary" title="Show logs"><b-icon
        icon="journal-text"></b-icon></b-button>
    <b-modal v-model="show_logs" size="xl" hide-footer @hide="cancelFetching()">
      <template #modal-title>
        Run logs ({{ run_infos.machine_name }}, {{ run_infos.ananke_id }})
        <small><help-view>You can see here all the machine logs captured during the run, and
            download it in a CSV file.</help-view></small>
      </template>
      <b-overlay :show="fetching_log_types">
        <b-tabs>
          <b-tab v-for="log_type of log_types" :key="log_type" :title="log_type" @click="getRunLogs(log_type)">
            <b-overlay :show="fetching_logs">
              <div class="text-center text-danger" v-if="error_logs">
                <b-icon icon="x" font-scale="4"></b-icon>
                <p>Something went wrong with the server.</p>
              </div>
              <div class="text-center text-secondary" v-if="run_logs.length == 0 && !error_logs && !fetching_logs">
                <b-icon icon="inbox" font-scale="4"></b-icon>
                <p>No log to show.</p>
              </div>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ bordered: true, striped: true }"
                v-if="fetching_logs"></b-skeleton-table>
              <b-container fluid v-if="run_logs.length > 0">
                <b-button-toolbar class="mt-2">
                  <b-button-group size="sm" class="mx-1">
                    <b-pagination v-model="logs_current_page" :total-rows="run_logs.length" :per-page="logs_per_page"
                      size="sm">
                    </b-pagination> </b-button-group><b-button-group size="sm" class="mx-1">
                    <b-button @click="downloadLogs()" title="Download logs"><b-icon icon="download"
                        class="mr-1"></b-icon>Download</b-button>
                  </b-button-group>
                </b-button-toolbar>
                <b-table striped small bordered class="text-nowrap" sticky-header="700px" sort-by="time" :items="run_logs"
                  :fields="log_fields" :per-page="logs_per_page" :current-page="logs_current_page"></b-table>
              </b-container>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "../../HelpView.vue";
const CancelToken = axios.CancelToken;

export default {
  name: "RunLogs",
  props: ["run_infos"],
  data: function () {
    return {
      source: CancelToken.source(),
      fetching_logs: false,
      fetching_log_types: false,
      error_logs: false,
      log_types: [],
      log_type: null,
      run_logs: [],
      log_fields: [],
      show_logs: false,
      logs_per_page: 100,
      logs_current_page: 1,
    };
  },
  methods: {
    cancelFetching() {
      this.source.cancel();
      this.source = CancelToken.source();
    },
    showLogs() {
      this.show_logs = true;
      this.getLogTypes();
    },
    async getRunLogs(log_type) {
      if (this.fetching_logs) {
        this.cancelFetching();
      }
      this.fetching_logs = true;
      this.error_logs = false;
      this.run_logs = [];
      this.log_fields = [];
      try {
        const response = await axios
          .get("/api/v1/run/logs", {
            params: { run_id: this.run_infos.id, log_type: log_type },
            cancelToken: this.source.token,
          })
        this.run_logs = response.data.data.map((ele) => {
          let new_content = {};
          let content = ele;
          if (response.config.params.log_type.toLowerCase() == "debug") {
            content = ele.content;
          } else {
            delete content.type;
          }
          for (let colname in content) {
            let colvalue = content[colname];
            if (Array.isArray(colvalue)) {
              for (let index in colvalue) {
                let suffix = parseInt(index) + 1;
                new_content[colname + "_" + suffix.toString()] =
                  colvalue[index];
              }
            } else {
              new_content[colname] = colvalue;
            }
          }
          return new_content;
        });
        let log_columns = Object.keys(this.run_logs[0]);
        this.log_fields = log_columns
          .filter((ele) => ele == "time")
          .map((ele) => {
            return {
              key: ele,
              stickyColumn: true,
            };
          });
        for (let colname of log_columns.filter((ele) => ele != "time")) {
          this.log_fields.push({ key: colname });
        }
        this.log_type = response.config.params.log_type.toLowerCase();
        this.logs_current_page = 1;
        this.fetching_logs = false;
      }
      catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled to fetch run logs");
        } else {
          this.error_logs = true;
          this.fetching_logs = false;
        }
      }
    },
    async getLogTypes() {
      this.fetching_log_types = true;
      this.log_types = (await axios
        .get("/api/v1/run/log-types", {
          params: { run_id: this.run_infos.id },
        })).data.types
      this.fetching_log_types = false;
      this.getRunLogs(this.log_types[0])
    },
    downloadLogs() {
      var column_names = this.log_fields.map((ele) => ele.key);
      var rows = this.run_logs.map((run_log) => {
        var row_data = [];
        for (let column_name of column_names) {
          row_data.push(run_log[column_name]);
        }
        return row_data;
      });
      var array_data = [column_names].concat(rows);
      var csv_content =
        'data:text/csv;charset=utf-8,"' +
        array_data.map((ele) => ele.join('","')).join('"\n"') +
        '"';
      var encoded_uri = encodeURI(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        "gaia_run_logs_" +
        this.run_infos.machine_name +
        "_" +
        this.run_infos.ananke_id +
        "_" +
        this.log_type +
        "_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
  },
  components: {
    HelpView,
  },
};
</script>

<style scoped>
.btn-toolbar {
  margin-bottom: 8px;
}

.pagination {
  margin-bottom: 0;
}
</style>