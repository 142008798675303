<template>
  <span>
    <b-button-group class="full-width">
      <b-button @click="show_export = true" title="Export analysis data"><b-icon icon="download"
          class="mr-2"></b-icon>Export</b-button>
    </b-button-group>
    <b-modal v-model="show_export" title="Export options" hide-footer>
      <b-alert :show="dismiss_countdown" class="position-fixed fixed-bottom m-0 rounded-0" variant="success" dismissible
        @dismissed="dismiss_countdown = 0" @dismiss-count-down="countDownChanged" fade>Analysis data copied to clipboard
      </b-alert>
      <b-container>
        <b-form-group label="Fields to export" label-for="export-fields">
          <b-form-checkbox-group id="export-fields" v-model="selected_fields" :options="export_fields" stacked
            switches></b-form-checkbox-group>
        </b-form-group>
      </b-container>
      <b-container>
        <b-row class="text-center">
          <b-col cols="12"><b-button-group>
              <b-button @click="downloadAnalysis()" class="border"><b-icon icon="download"
                  class="mr-1"></b-icon>Download</b-button>
              <b-button @click="copyAnalysis()" class="border"><b-icon icon="files"
                  class="mr-1"></b-icon>Copy</b-button>
            </b-button-group></b-col>
        </b-row>
      </b-container>
    </b-modal>
  </span>
</template>

<script>
export default {
  name: "DownloadAnalysis",
  props: ["runs_datas", "analysis_version", "viz_options", "fields"],
  data: function () {
    return {
      show_export: false,
      selected_fields: [],
      dismiss_countdown: 0,
      total_secs: 5,
    };
  },
  computed: {
    export_fields: function () {
      return this.fields
        .filter((ele) => !["chamber", "led", "fluor", "date"].includes(ele.key))
        .map((ele) => {
          return { value: ele.key, text: ele.label };
        });
    },
    analysis_table: function () {
      var analysis_table = [];
      this.runs_datas.forEach((run_data) => {
        var included_fluors = Object.keys(run_data.analysis[this.analysis_version].data).filter(
          (fluor) =>
            this.viz_options.display_leds.includes(
              run_data.fluor_mapping.find((ele) =>
                ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
              ).led
            )
        );
        included_fluors.forEach((fluor) => {
          var fluor_data = run_data.analysis[this.analysis_version].data[fluor];
          var included_chambers = Object.keys(fluor_data).filter((chamber) =>
            this.viz_options.display_chambers.includes(chamber)
          );
          included_chambers.forEach((chamber) => {
            var chamber_data = fluor_data[chamber];
            var led = run_data.fluor_mapping.find((ele) =>
              ele.fluor.replace("Fluorophore ", "").split("_").includes(fluor)
            ).led;
            analysis_table.push({
              run_infos: run_data.run_infos,
              date: run_data.analysis[this.analysis_version].date,
              chamber: chamber,
              led: led,
              fluor: fluor,
              cycle_threshold: chamber_data.cycle_threshold,
              cycle_threshold2: chamber_data.cycle_threshold2,
              positive_estimator: chamber_data.positive_estimator,
              state: chamber_data.state,
              gamma: chamber_data.gamma,
              s1n: chamber_data.s1n,
              delta_rfu: chamber_data.delta_rfu,
            });
          });
        });
      });
      return analysis_table;
    },
  },
  methods: {
    countDownChanged(dismiss_countdown) {
      this.dismiss_countdown = dismiss_countdown;
    },
    exportAnalysis() {
      var distinct_fluors = [
        ...new Set(this.analysis_table.map((ele) => ele.fluor)),
      ];
      var distinct_chambers = [
        ...new Set(this.analysis_table.map((ele) => ele.chamber)),
      ];
      var distinct_run_ids = [
        ...new Set(this.analysis_table.map((ele) => ele.run_infos.id)),
      ];
      var selected_columns = this.export_fields.filter((ele) =>
        this.selected_fields.includes(ele.value)
      );
      var column_names = [
        "Machine Name",
        "Chronos ID",
        "Description",
        "Samples",
        "Chip barcode",
        "Batch ID",
        "Analysis version",
        "Analysis date (UTC)"
      ];
      for (let fluor of distinct_fluors) {
        for (let chamber of distinct_chambers) {
          for (let new_field of selected_columns) {
            column_names.push(
              new_field.text + " (" + fluor + " and " + chamber + ")"
            );
          }
        }
      }
      var rows = distinct_run_ids.map((run_id) => {
        const run_infos = this.analysis_table.find(
          (ele) => ele.run_infos.id == run_id
        ).run_infos;
        const analysis_date = this.analysis_table.find(
          (ele) => ele.run_infos.id == run_id
        ).date
        var row_data = [];
        for (let fluor of distinct_fluors) {
          for (let chamber of distinct_chambers) {
            for (let new_field of selected_columns) {
              let new_value = "NA";
              this.analysis_table
                .filter(
                  (ele) =>
                    ele.fluor == fluor &&
                    ele.chamber == chamber &&
                    ele.run_infos.id == run_id
                )
                .forEach(
                  (filtered_data) =>
                    (new_value = filtered_data[new_field.value])
                );
              row_data.push(new_value);
            }
          }
        }
        return [
          run_infos.machine_name,
          run_infos.ananke_id,
          run_infos.description,
          run_infos.samples,
          run_infos.chip_barcode,
          run_infos.batch_range,
          this.analysis_version,
          analysis_date,
        ].concat(row_data);
      });
      var array_data = [column_names].concat(rows);
      var csv_content =
        '"' + array_data.map((ele) => ele.join('","')).join('"\n"') + '"';
      return csv_content;
    },
    downloadAnalysis() {
      this.show_export = false;
      var csv_content = this.exportAnalysis();
      var encoded_uri =
        encodeURI("data:text/csv;charset=utf-8,") +
        encodeURIComponent(csv_content);
      var link = document.createElement("a");
      link.setAttribute("href", encoded_uri);
      var now = new Date();
      var fileName =
        "gaia_data_analysis_" + this.analysis_version + "_" +
        now.getFullYear() +
        (now.getMonth() + 1) +
        now.getDate() +
        "T" +
        now.getHours() +
        now.getMinutes() +
        now.getSeconds() +
        ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
    copyAnalysis() {
      var vm = this;
      var csv_content = this.exportAnalysis();
      navigator.clipboard.writeText(csv_content).then(
        () => (vm.dismiss_countdown = vm.total_secs),
        () => (vm.dismiss_countdown = vm.total_secs)
      );
    },
  },
  created: function () {
    this.selected_fields = this.export_fields.map((ele) => ele.value);
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%
}
</style>