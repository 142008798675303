<template>
  <div>
    <h2>
      Email configuration<small class="p-1"><help-view>This page allows to manage the email sender
          credentials.</help-view></small>
    </h2>
    <hr />
    <b-container fluid>
      <h3>Authentication</h3>
      <b-alert :show="login_url === undefined" class="mb-2" variant="danger">The credentials file is not
        configured</b-alert>
      <p v-if="login_url !== undefined">
        Click <a target="_blank" :href="login_url">here</a> to launch the
        authentication workflow.
      </p>
    </b-container>
    <hr />
    <b-container fluid>
      <h3>Token file</h3>
      <b-overlay :show="fetching">
        <p>
          You can try to download the token file by clicking
          <a href="#" @click="downloadToken">here</a>.
        </p>
      </b-overlay>
      <b-alert :show="error_token_download" class="mb-2" variant="danger">The token file is not found</b-alert>
      <b-alert :show="error_token !== undefined" class="mb-2" variant="danger" dismissible>{{ error_token }}</b-alert>
      <b-alert :show="token_success" class="mb-2" variant="success">File uploaded</b-alert>
      <b-form-group label="Upload token file" label-for="token_file" label-cols-xl="3">
        <b-form-file v-model="token_file" :state="Boolean(token_file)" id="token_file"
          placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
          accept="application/json"></b-form-file>
      </b-form-group>
      <b-button block variant="primary" :disabled="token_file === null" @click="uploadToken">Upload</b-button>
    </b-container>
    <hr />
    <b-container fluid>
      <h3>Test email sending</h3>
      <b-overlay :show="sending_email">
        <b-alert :show="error_sending !== undefined" class="mb-2" variant="danger" dismissible>{{ error_sending
        }}</b-alert>
        <b-alert :show="send_success" class="mb-2" variant="success">Email sent</b-alert>
        <b-form-group label="Send to" label-for="receiver" label-cols-xl="3">
          <b-form-input id="receiver" type="email" v-model="receiver" placeholder="Receiver email address"
            :state="receiver.length > 0 && valid_email.test(receiver)"></b-form-input>
        </b-form-group>
        <b-button block variant="primary" :disabled="receiver.length == 0 || !valid_email.test(receiver)"
          @click="sendEmail">Send email</b-button>
      </b-overlay>
    </b-container>
    <hr />
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "./components/HelpView.vue";

export default {
  name: "EmailAdmin",
  data: function () {
    return {
      credential_files: null,
      uploading_creds: false,
      error_creds: undefined,
      creds_success: false,
      login_url: undefined,
      error_token_download: false,
      token_file: null,
      uploading_token: false,
      error_token: undefined,
      token_success: false,
      receiver: "",
      sending_email: false,
      error_sending: undefined,
      send_success: false,
      fetching: false,
      valid_email:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  methods: {
    async getLoginUrl() {
      try {
        this.login_url = (await axios
          .get("/api/v1/email/login")).data
      }
      catch (error) {
        console.error("Could not get login URL")
      }
    },
    async downloadToken(event) {
      event.preventDefault();
      this.error_token_download = false;
      this.fetching = true;
      try {
        await axios
          .get("/api/v1/email/token")
        var link = document.createElement("a");
        link.setAttribute("href", "/api/v1/email/token");
        link.setAttribute("download", "token.json");
        document.body.appendChild(link);
        link.click();
      }
      catch (error) {
        this.error_token_download = true;
      }
      this.fetching = false
    },
    async uploadToken() {
      this.uploading_token = true;
      this.error_token_download = false;
      this.error_token = undefined;
      this.token_success = false;
      var formData = new FormData();
      formData.append("file", this.token_file);
      try {
        await axios
          .post("/api/v1/email/upload_token", formData)
        this.token_success = true
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error_token = error.response.data.detail;
        } else {
          this.error_token = "Internal server error";
        }
      }
      this.token_file = null
      this.uploading_token = false
    },
    async sendEmail() {
      this.sending_email = true;
      this.error_sending = undefined;
      this.send_success = false;
      var message =
        "If you received this email, this means the configuration is done for " +
        window.location.hostname +
        ".";
      var send_params = {
        receiver: this.receiver,
        subject: "[GAIA] Email sending test",
        txt_message: message,
        html_message: message,
      };
      try {
        const response = await axios
          .post("/api/v1/email/send", send_params)
        if (response.data === null) {
          this.error_sending = "Could not send an email";
        } else {
          this.send_success = true;
        }
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error_sending = error.response.data.detail;
        } else {
          this.error_sending = "Internal server error";
        }
      }
      this.sending_email = false
    },
  },
  created: function () {
    this.getLoginUrl();
  },
  components: {
    HelpView,
  },
};
</script>
