<template>
  <span>
    <template v-if="small">
      <b-iconstack v-if="small" class="clickable mr-1" title="Edit meta datas of selected runs" @click="show_edit = true">
        <b-icon stacked icon="square-fill" variant="secondary"></b-icon>
        <b-icon stacked icon="tag" scale="0.75" variant="white"></b-icon>
      </b-iconstack>
      {{ label }}
    </template>
    <b-button v-else @click="show_edit = true" size="sm" class="mx-1">
      <b-icon :icon="hasMultipleRuns ? 'tags' : 'tag'" class="mx-1"></b-icon>{{ label }}
    </b-button>
    <b-modal v-model="show_edit" hide-footer size="lg">
      <template #modal-title>
        Edit meta-datas for {{ run_ids.length }} run<span v-if="run_ids.length > 1">s</span>
        <small class="ml-1"><help-view>You can edit or remove meta-datas for multiple runs.</help-view></small>
      </template>
      <b-overlay :show="saving">
        <b-form-group label="I want to" label-for="edit_multi_mode" label-cols-xl="3">
          <b-form-select v-model="edit_mode" :options="edit_modes" size="sm"></b-form-select>
        </b-form-group>
        <b-form-group label="Key" label-for="edit_multi_key" label-cols-xl="3">
          <b-form-input type="text" v-model="meta_key" id="edit_multi_key" list="edit_multi_avail_keys" autocomplete="off"
            :formatter="keyFormatter" @input="fetchMetaValues()" size="sm" placeholder="Meta key"></b-form-input>
          <datalist type="text" id="edit_multi_avail_keys">
            <option v-for="key of avail_keys" :key="key">{{ key }}</option>
          </datalist>
        </b-form-group>
        <b-form-group label="Value" label-for="edit_multi_value" label-cols-xl="3" v-if="edit_mode == 'edit'">
          <b-form-input type="text" v-model="meta_value" id="edit_multi_value" list="edit_multi_avail_values"
            autocomplete="off" placeholder="Meta value" size="sm" :disabled="meta_key.length == 0"></b-form-input>
          <datalist type="text" id="edit_multi_avail_values">
            <option v-for="key of avail_values" :key="key">
              {{ key }}
            </option>
          </datalist>
        </b-form-group>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button variant="danger" class="mx-1" size="sm" :disabled="meta_key.length == 0" @click="deleteMeta()"
              v-if="edit_mode == 'delete'"><b-icon icon="trash" class="mr-1"></b-icon>Remove</b-button>
            <b-button variant="primary" class="mx-1" :disabled="meta_key.length == 0 || meta_value.length == 0" size="sm"
              @click="saveMeta()" v-if="edit_mode == 'edit'"><b-icon icon="check" class="mr-1"></b-icon>Save</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </span>
</template>

<script>
import axios from "axios";
import HelpView from "../../HelpView.vue";
const CancelToken = axios.CancelToken;

export default {
  name: "EditMultipleTags",

  props: {
    run_ids: {
      type: Array,
      required: true,
    },

    small: {
      type: Boolean,
      default: false,
    },

    label: String,
  },

  data: function () {
    return {
      source: CancelToken.source(),
      show_edit: false,
      saving: false,
      avail_keys: [],
      avail_values: [],
      meta_key: "",
      meta_value: "",
      fetching_keys: false,
      fetching_values: false,
      edit_modes: [
        { value: "edit", text: "Edit meta-datas" },
        { value: "delete", text: "Remove meta-datas" },
      ],
      edit_mode: "edit",
    };
  },
  watch: {
    show_edit: function () {
      this.meta_key = "";
      this.meta_value = "";
      this.fetchMetaKeys();
    },
  },
  methods: {
    keyFormatter(value) {
      return value
        .toUpperCase()
        .replace(" ", "_")
        .replace(/[^A-Z0-9_]/, "");
    },
    async fetchMetaKeys() {
      this.meta_key = ""
      this.meta_value = ""
      this.avail_keys = (await axios
        .get("/api/v1/meta/keys")).data
    },
    async fetchMetaValues() {
      this.meta_value = ""
      if (this.meta_key.length > 0) {
        this.avail_values = (await axios.get("/api/v1/meta/values", {
          params: { key: this.meta_key }
        })).data
      }
    },
    async saveMeta() {
      this.saving = true;
      var data_to_save = this.run_ids.map((ele) => {
        return { run_id: typeof ele == "object" ? ele.id : ele, key: this.meta_key, value: this.meta_value };
      });
      try {
        await axios
          .post("/api/v1/meta/add", data_to_save)
        this.saving = false;
        this.show_edit = false;
      }
      catch (error) {
        this.saving = false
      }
    },
    async deleteMeta() {
      this.saving = true;
      var data_to_delete = this.run_ids.map((ele) => {
        return { run_id: typeof ele == "object" ? ele.id : ele, key: this.meta_key };
      });
      try {
        await axios
          .delete("/api/v1/meta/delete", {
            data: data_to_delete,
          })
        this.saving = false
        this.show_edit = false
      }
      catch (error) {
        this.saving = false
      }
    },
  },

  computed: {
    hasMultipleRuns() {
      return this.run_ids.length > 1;
    },
  },

  components: { HelpView },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
</style>