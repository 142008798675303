export const BOX_PLOT_TYPE = 'box';

export const plotTypes = [
  {
    value: "lines",
    text: "Lines",
  },
  {
    value: "markers",
    text: "Dots",
  },
  {
    value: "lines+markers",
    text: "Lines and dots",
  },
  {
    value: "bar",
    text: "Bars",
  },
  {
    value: BOX_PLOT_TYPE,
    text: "Box plots",
  },
];

export const commonPlotTypes = ["bar", "box"];

export const LOG_VIEW = "LOG_VIEW";
export const ANALYSIS_VIEW = "ANALYSIS_VIEW";
export const MARKED_RUNS_OPACITY = 0.125;
