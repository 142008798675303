<template>
  <div>
    <b-container fluid>
      <h3>Groups</h3>
      <b-button-toolbar>
        <b-button-group class="mx-1">
          <b-pagination v-model="current_page" :total-rows="infos.length" :per-page="per_page"
            :disabled="fetching"></b-pagination>
        </b-button-group>
        <b-input-group>
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-input type="text" placeholder="Search by name..." autocomplete="off" v-model="search"
            @input="filterInfos"></b-input>
        </b-input-group>
        <b-input-group class="ml-1">
          <b-input-group-prepend is-text>
            <b-icon icon="search"></b-icon>
          </b-input-group-prepend>
          <b-input type="text" id="group-search-email" placeholder="Search by email..." autocomplete="off" v-model="email"
            @change="fetchIDs"></b-input>
          <b-popover target="group-search-email" variant="primary" triggers="hover" placement="top">
            Hit enter (or leave field) to apply
          </b-popover>
        </b-input-group>
        <b-button-group class="mx-1">
          <b-button @click="fetchIDs()" class="border"><b-icon icon="arrow-clockwise"
              class="mr-1"></b-icon>Reload</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="success" @click="showEdit(true)" class="border"><b-icon icon="plus"
              class="mr-1"></b-icon>Add</b-button>
          <b-button variant="success" class="border" @click="showEdit(true, true)" v-if="selected != null"><b-icon
              icon="files" class="mr-1"></b-icon>Copy</b-button>
        </b-button-group>
        <b-button-group class="mx-1" v-if="selected != null">
          <b-button variant="primary" @click="showEdit(false)" class="border"><b-icon icon="pen"
              class="mr-1"></b-icon>Edit</b-button>
          <b-button variant="danger" @click="show_delete = true" class="border"><b-icon icon="trash"
              class="mr-1"></b-icon>Delete</b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-table :items="infos" striped hover bordered :busy="fetching" :fields="fields" class="text-nowrap" show-empty
        sort-by="name" sticky-header="500px" :per-page="per_page" :current-page="current_page" selectable
        select-mode="single" @row-selected="onRowSelected">
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </b-container>
    <b-modal v-model="show_edit" title="Group editor" hide-footer size="lg">
      <b-overlay :show="saving">
        <div v-if="edit !== null">
          <b-form-group v-for="field in fields" :key="field.key" :label="field.label" :label-for="field.key"
            label-cols-xl="3">
            <b-form-input :id="field.key" type="text" v-model="edit[field.key]" :placeholder="field.desc"
              :state="notEmpty(field)"></b-form-input>
          </b-form-group>
          <hr />
          <b-row class="text-center text-danger" v-if="error != null"><b-col cols="12"><strong>{{ error
          }}.</strong></b-col></b-row>
          <b-row class="text-center">
            <b-col cols="12">
              <b-button @click="show_edit = false" variant="danger" class="mx-1"><b-icon icon="x"
                  class="mr-1"></b-icon>Cancel</b-button>
              <b-button variant="primary" class="mx-1" :disabled="checkState()" @click="saveItem"><b-icon icon="check"
                  class="mr-1"></b-icon>Save</b-button>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal v-model="show_delete" title="Delete group" hide-footer>
      <b-overlay :show="saving">
        <p class="text-center">
          Are you sure to delete this group? This action is irreversible.
        </p>
        <b-row class="text-center">
          <b-col cols="12">
            <b-button @click="show_delete = false" variant="danger" class="mx-1"><b-icon icon="x"
                class="mr-1"></b-icon>Cancel</b-button>
            <b-button variant="primary" class="mx-1" @click="deleteItem"><b-icon icon="check"
                class="mr-1"></b-icon>OK</b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { runAllInBatches } from "../../utils/async";
const CancelToken = axios.CancelToken;

export default {
  name: "GroupsTable",
  props: ["selected_parent", "group_names"],
  data: function () {
    return {
      item_ids: this.group_names,
      infos_base: [],
      infos: [],
      fetching: false,
      selected: this.selected_parent,
      fields: [
        {
          key: "name",
          label: "Name",
          notnull: true,
          desc: "Enter the group name",
        },
        {
          key: "domain",
          label: "Email domain",
          desc: "The group domain adds automatically users from their email addresses",
        },
        {
          key: "address",
          label: "Group address",
          desc: "Enter the group postal address (must be valid in Google Maps)",
        }
      ],
      source: CancelToken.source(),
      per_page: 50,
      current_page: 1,
      show_edit: false,
      edit: null,
      error: null,
      saving: false,
      show_delete: false,
      search: "",
      email: "",
    };
  },
  watch: {
    selected: function () {
      this.$emit("update:selected_parent", this.selected);
    },
    item_ids: function () {
      this.$emit("update:group_names", this.item_ids);
    },
  },
  methods: {
    async fetchIDs() {
      this.item_ids = [];
      const email = this.email.length > 0 ? this.email : null;
      try {
        this.item_ids = (await axios
          .get("/api/v1/group/names", {
            params: {
              email: email
            }
          })).data
      }
      catch (error) {
        console.error("Could not get group names");
      }
      await this.fetchInfos();
    },
    async fetchInfo(id) {
      try {
        return (await axios.get("/api/v1/group/info", {
          params: {
            name: id
          }
        })).data
      }
      catch (error) {
        console.error("Could not get item info")
      }
    },
    async fetchInfos() {
      this.fetching = true;
      var fetches = []
      for (let id of this.item_ids) {
        fetches.push(() => this.fetchInfo(id))
      }
      this.infos_base = this.infos = await runAllInBatches({
        batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
        operations: fetches,
      })
      this.fetching = false
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.selected = items[0];
      } else {
        this.selected = null;
      }
    },
    showEdit(newItem, copy = false) {
      var edit = {};
      if (newItem) {
        for (let field of this.fields) {
          edit[field.key] = copy ? this.selected[field.key] : "";
        }
      } else {
        edit.previous_name = this.selected.name;
        for (let field of this.fields) {
          edit[field.key] = this.selected[field.key];
        }
      }
      this.edit = edit;
      this.error = null;
      this.show_edit = true;
    },
    notEmpty(field) {
      var valid = true;
      if (field.notnull) {
        valid = this.edit[field.key] != null;
        if (this.edit[field.key] != null) {
          valid = this.edit[field.key].length > 0;
        }
      }
      return valid;
    },
    checkState() {
      var valid = true;
      for (let field of this.fields) {
        if (field.notnull) {
          if (this.edit[field.key] == null) {
            valid = false;
          }
          if (this.edit[field.key] != null) {
            if (this.edit[field.key].length == 0) {
              valid = false;
            }
          }
        }
      }
      return !valid;
    },
    async saveItem() {
      this.saving = true;
      this.error = null;
      try {
        await axios
          .post("/api/v1/group/add", this.edit)
        this.show_edit = false;
        this.fetchIDs();
      }
      catch (error) {
        if (error.response.data.detail != undefined) {
          this.error = error.response.data.detail;
        } else {
          this.error = "Internal server error.";
        }
      }
      this.saving = false
    },
    async deleteItem() {
      this.saving = true;
      await axios
        .delete("/api/v1/group/delete", {
          params: { name: this.selected.name },
        })
      this.saving = false
      this.show_delete = false
      this.fetchIDs()
    },
    filterInfos() {
      if (this.search.length > 0) {
        this.infos = this.infos_base.filter((ele) =>
          ele.name.toUpperCase().includes(this.search.toUpperCase())
        );
      } else {
        this.infos = this.infos_base;
      }
    },
  },
  created: async function () {
    await this.fetchIDs();
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>