<template>
  <div>
    <h4>
      Display options
      <small>
        <b-iconstack
          v-if="all_leds.length > 0"
          class="clickable mr-2"
          @click="resetOptions()"
          title="Reset options"
        >
          <b-icon stacked icon="square-fill" variant="danger"></b-icon>
          <b-icon
            stacked
            icon="arrow-repeat"
            scale="0.75"
            variant="white"
          ></b-icon>
        </b-iconstack>
        <help-view
          >Enter here your data visualization options.</help-view
        ></small
      >
    </h4>
    <div v-if="all_leds.length > 0">
      <b-form-group
        label="Display curve:"
        label-for="select-display"
        label-cols-xl="4"
      >
        <b-form-select
          id="select-display"
          size="sm"
          v-model="options.display_curve"
          :options="
            fetching && !error
              ? all_curves.filter((ele) => ele.value == 'raw')
              : all_curves
          "
        ></b-form-select>
      </b-form-group>
      <b-form-group
        label="Display channels:"
        label-cols-xl="4"
      >
        <b-form-checkbox-group
          v-model="options.display_leds"
          :options="all_leds"
          stacked
          switches
        ></b-form-checkbox-group>
      </b-form-group>
      <b-form-group
        label="Display chambers:"
        label-cols-xl="4"
      >
        <b-form-checkbox-group
          v-model="options.display_chambers"
          :options="all_chambers"
          stacked
          switches
        ></b-form-checkbox-group>
      </b-form-group>
      <b-form-checkbox
        v-model="options.start_from_one"
        :disabled="options.display_curve != 'raw'"
        switch
        >Synchronize on cycle 1
      </b-form-checkbox>
      <b-form-checkbox
        v-model="options.no_scale_y"
        :disabled="options.display_curve != 'raw'"
        switch
        >No scale on Y axis
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import HelpView from "../HelpView.vue";
export default {
  name: "VizOptions",
  props: [
    "data_options",
    "main_color_scale",
    "viz_options",
    "fetching",
    "error",
  ],
  computed: {
    all_leds: function () {
      var all_leds = [];
      this.data_options.leds.forEach((led_option) => {
        let color = this.main_color_scale(led_option.channel);
        let html =
          '<span class="color_legend" style="background:' +
          color +
          '"></span>' +
          "LED " +
          led_option.led +
          " - " +
          led_option.fluor;
        all_leds.push({
          value: led_option.led,
          html: html,
        });
      });
      all_leds.sort((a, b) => a.value - b.value);
      return all_leds;
    },
    all_chambers: function () {
      return this.data_options.chambers;
    },
  },
  data: function () {
    return {
      all_curves: [
        { value: "raw", text: "Raw data" },
        { value: "processed", text: "Processed data" },
        { value: "sigmoid", text: "Sigmoid fitting" },
        { value: "threshold", text: "Threshold detection" },
      ],
      options: {
        display_curve: "raw",
        display_leds: [],
        display_chambers: [],
        start_from_one: true,
        no_scale_y: false,
      },
    };
  },
  watch: {
    all_leds: function () {
      if (this.options.display_leds.length == 0) {
        this.options.display_leds = this.all_leds.map((ele) => ele.value);
      }
    },
    all_chambers: function () {
      if (this.options.display_chambers.length == 0) {
        this.options.display_chambers = this.all_chambers;
      }
    },
    fetching: function () {
      if (this.fetching && !this.error) {
        this.options.display_curve = "raw";
      }
    },
    options: {
      deep: true,
      handler: function () {
        this.$emit("update:viz_options", this.options);
      },
    },
  },
  methods: {
    resetOptions() {
      this.options.display_curve = "raw";
      this.options.display_leds = this.all_leds.map((ele) => ele.value);
      this.options.display_chambers = this.all_chambers;
      this.options.start_from_one = true;
      this.options.no_scale_y = false;
    },
  },
  components: {
    HelpView,
  },
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
div >>> .col-form-label {
  padding-top: 0;
}
div >>> .color_legend {
  background: white;
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  border: solid 1px;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
}
</style>