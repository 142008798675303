<template>
  <div>
    <h2>
      Users administration<small class="p-1"
        ><help-view
          >This page allows to manage the groups with their attached accesses
          users.</help-view
        ></small
      >
    </h2>
    <hr />
    <groups-table
      :selected_parent.sync="selected_group"
      :group_names.sync="group_names"
    ></groups-table>
    <hr />
    <accesses-table
      :selected_group="selected_group"
      v-show="selected_group !== null"
    ></accesses-table>
    <hr v-if="selected_group !== null" />
    <users-table
      :selected_group="selected_group"
      :group_names="group_names"
      :password_compliant="password_compliant"
      :password_compliant_regex="password_compliant_regex"
      v-show="selected_group !== null"
    ></users-table>
    <hr v-if="selected_group !== null" />
  </div>
</template>

<script>
import HelpView from "./components/HelpView.vue";
import GroupsTable from "./components/user-admin/GroupsTable.vue";
import AccessesTable from "./components/user-admin/AccessesTable.vue";
import UsersTable from "./components/user-admin/UsersTable.vue";

export default {
  name: "UserAdmin",
  props: ["password_compliant", "password_compliant_regex"],
  data: function () {
    return {
      selected_group: null,
      group_names: [],
    };
  },
  components: {
    HelpView,
    GroupsTable,
    AccessesTable,
    UsersTable,
  },
};
</script>

<style scoped>
.pagination {
  margin-bottom: 0;
}
.btn-toolbar {
  margin-bottom: 8px;
}
</style>