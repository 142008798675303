<template>
  <div class="d-inline-block">
    <b-button @click="showPictures()" size="sm" class="p-0 ml-1" variant="primary" title="Show pictures"><b-icon
        icon="images"></b-icon></b-button>
    <b-modal v-model="show_pictures" size="xl" hide-footer @hide="cancelFetching()">
      <template #modal-title>
        Fluorescence pictures ({{ run_datas.run_infos.machine_name }},
        {{ run_datas.run_infos.ananke_id }})
        <small><help-view>The pictures brightness is automatically normalized for each led.
            100 pictures per page are displayed.</help-view></small>
      </template>
      <b-container v-if="run_datas != null && Object.keys(run_datas.data).length == 0"
        class="text-center text-secondary">
        <b-icon icon="slash-circle" font-scale="4"></b-icon>
        <p class="mt-2">There is no picture for this run.</p>
      </b-container>
      <b-container class="text-center text-danger" v-if="error_cdf">
        <b-icon icon="x" font-scale="4"></b-icon>
        <p>Something went wrong with the server for images normalization.</p>
      </b-container>
      <b-container v-if="run_datas != null && Object.keys(run_datas.data).length > 0" fluid>
        <b-button-group size="sm" class="mb-1">
          <b-button :disabled="downloading" @click="downloadPictures()"
            title="Download run pictures in a zip file"><b-icon icon="download"
              class="mr-1"></b-icon>Download</b-button>
        </b-button-group>
        <b-progress v-if="downloading" :value="download_progress" class="m-1" :max="totalPictures"
          animated></b-progress>
        <b-overlay :show="fetching_cdf">
          <b-card no-body>
            <b-tabs card>
              <b-tab v-for="(led_data, led) in run_datas.data" :key="led" :title="'LED ' + led" lazy @click="
      current_page = 1;
    slide = 0;
    ">
                <b-row>
                  <b-pagination v-model="current_page" :total-rows="led_data.length" :per-page="per_page"
                    aria-controls="my-table" @input="changedPage" v-show="led_data.length > per_page"></b-pagination>
                </b-row>
                <b-row>
                  <b-form-input id="range-1" v-model="slide" type="range" min="0" :max="led_data.slice(
      (current_page - 1) * per_page,
      current_page * per_page
    ).length - 1
      " v-if="led_data.length > 1"></b-form-input>
                </b-row>
                <b-row>
                  <b-carousel :interval="0" :controls="led_data.length > 1" no-animation v-model="parsed_slide"
                    v-if="!fetching_cdf" :no-wrap="led_data.length < per_page" @sliding-start="
      prev_slide = slide;
    max_slide =
      led_data.slice(
        (current_page - 1) * per_page,
        current_page * per_page
      ).length - 1;" @sliding-end="changePage">
                    <b-carousel-slide v-for="(time_data, index) in led_data.slice(
      (current_page - 1) * per_page,
      current_page * per_page
                    )" :key="index">
                      <template #img>
                        <b-card no-body>
                          <b-card-img-lazy v-if="run_cdf !== undefined &&
      run_cdf[led] !== undefined &&
      run_cdf[led].length > 0
      " :src="'/api/v1/run/picture?data_id=' +
      time_data.data_id +
      '&run_cdf=' +
      JSON.stringify(run_cdf[led])
      "></b-card-img-lazy><b-card-img-lazy v-else :src="'/api/v1/run/picture?data_id=' +
      time_data.data_id
      "></b-card-img-lazy>
                          <b-list-group>
                            <b-list-group-item class="p-1 text-center" v-if="time_data.analysed"><i>This picture was
                                analysed for camera shift
                                detection.</i></b-list-group-item>
                            <b-list-group-item class="p-1"><strong>Time (seconds): </strong>{{
      global_format(time_data.timestamp)
    }}</b-list-group-item>
                            <b-list-group-item class="p-1"><strong>Cycle: </strong>{{ time_data.cycle
                              }}</b-list-group-item>
                            <b-list-group-item class="p-1"><strong>Side: </strong>{{ time_data.side
                              }}</b-list-group-item>
                            <b-list-group-item class="p-1" v-for="(fluo, chamber) in time_data.fluo"
                              :key="chamber"><strong>RFU ({{ chamber }}): </strong>{{ global_format(fluo)
                              }}</b-list-group-item>
                          </b-list-group>
                        </b-card>
                      </template>
                    </b-carousel-slide>
                  </b-carousel>
                </b-row>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import * as d3 from "d3";
import axios from "axios";
import JSZip from "jszip";
import HelpView from "../../HelpView.vue";
import { runAllInBatches } from "../../../utils/async";
const CancelToken = axios.CancelToken;

export default {
  name: "RunPictures",
  props: ["run_datas"],

  data: function () {
    return {
      show_pictures: false,
      run_cdf: undefined,
      fetching_cdf: false,
      error_cdf: false,
      slide: 0,
      parsed_slide: 0,
      prev_slide: 0,
      max_slide: 0,
      changed_by_slide: false,
      global_format: d3.format("~g"),
      source: CancelToken.source(),
      current_page: 1,
      per_page: 100,
      downloading: false,
      download_progress: 0,
      zip_file: undefined
    };
  },

  watch: {
    slide: function () {
      this.parsed_slide = parseInt(this.slide);
    },
    parsed_slide: function () {
      this.slide = this.parsed_slide;
    },
  },

  computed: {
    totalPictures() {
      var length = 0
      for (let led in this.run_datas.data) {
        length += this.run_datas.data[led].length
      }
      return length
    }
  },

  methods: {
    async showPictures() {
      this.downloading = false;
      this.current_page = 1;
      this.slide = 0;
      this.show_pictures = true;
      if (this.fetching_cdf) {
        this.cancelFetching();
      }
      if (this.run_cdf === undefined) {
        this.fetching_cdf = true;
        this.error_cdf = false;
        try {
          this.run_cdf = (await axios
            .get("/api/v1/run/cdf", {
              params: { run_id: this.run_datas.run_infos.id },
              cancelToken: this.source.token,
            })).data
        }
        catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request cancelled to fetch run pictures cdf");
          } else {
            this.error_cdf = true
          }
        }
        this.fetching_cdf = false
      }
    },
    changedPage() {
      if (this.changed_by_slide) {
        this.slide = this.per_page - 1;
        this.prev_slide = this.slide;
        this.changed_by_slide = false;
      } else {
        this.slide = 0;
        this.prev_slide = 0;
      }
    },
    changePage(slide) {
      if (
        slide == 0 &&
        this.prev_slide == this.max_slide &&
        this.max_slide > 1
      ) {
        if (this.max_slide == this.per_page - 1) {
          this.current_page += 1;
        } else {
          this.slide = this.max_slide;
        }
      }
      if (
        slide == this.max_slide &&
        this.prev_slide == 0 &&
        this.max_slide > 1
      ) {
        if (this.current_page > 1) {
          this.changed_by_slide = true;
          this.current_page -= 1;
        } else {
          this.slide = 0;
        }
      }
    },
    async downloadPicture(fluo_content, run_infos) {
      if (this.show_pictures && this.zip_file !== undefined) {
        this.zip_file.file(run_infos.machine_name + "_" + run_infos.ananke_id +
          "/LED" + fluo_content.led + "/LED" + fluo_content.led + "_" + fluo_content.abs_timestamp + ".tiff",
          (await axios.get(
            "/api/v1/run/picture", {
            params: {
              data_id: fluo_content.data_id,
            },
            responseType: 'blob'
          }
          )).data)
        this.download_progress++
      }
    },
    async downloadPictures() {
      this.download_progress = 0
      this.downloading = true
      this.zip_file = new JSZip()
      for (let led in this.run_datas.data) {
        var fetches = []
        for (let fluo_content of this.run_datas.data[led]) {
          fetches.push(() => this.downloadPicture(fluo_content, this.run_datas.run_infos))
        }
        await runAllInBatches({
          batchSize: this.$root.$options.config.FETCH_BATCH_SIZE,
          operations: fetches,
        })
        if (!this.show_pictures) {
          this.downloading = false
          this.zip_file = undefined
          return
        }
      }
      const zip_content = await this.zip_file.generateAsync({ type: "blob" })
      var url = URL.createObjectURL(zip_content);
      var link = document.createElement("a");
      link.href = url;
      link.download = this.run_datas.run_infos.machine_name + "_" + this.run_datas.run_infos.ananke_id + ".zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      this.downloading = false
      this.zip_file = undefined
    },
    cancelFetching() {
      this.source.cancel();
      this.source = CancelToken.source();
    },
  },

  components: {
    HelpView,
  },
};
</script>

<style scoped>
.carousel {
  width: 100%;
}
</style>