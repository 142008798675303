<template>
  <div v-if="is_authenticated">
    <b-row>
      <b-col md="4" class="border-right border-bottom"><runs-filter ref="runsFilter" :filtered_runs.sync="filtered_runs"
          :fetching_filter.sync="fetching_filter" :batches_infos.sync="batches_infos"
          :reset_filters="reset_filters" :is-admin="isAdmin"></runs-filter>
      </b-col>
      <b-col md="8" class="border-bottom"><runs-list :filtered_runs="filtered_runs" :fetching_filter="fetching_filter"
          :select_runs.sync="selected_runs" :fields="infos_fields" :batch_fields="batch_fields"
          :batches_infos.sync="batches_infos" @reset_filters="resetFilters"
          @reload_runs="reloadRuns" @cancel_runs_load="cancelRunsLoad"></runs-list></b-col>
    </b-row>
    <data-viz :selected_runs.sync="selected_runs" :infos_fields="infos_fields" :batch_fields="batch_fields"
      :batches_infos.sync="batches_infos" :single_view="false"
      :is-admin="isAdmin" :is-archive="isArchive"></data-viz>
  </div>
</template>

<script>
import axios from "axios";
import RunsFilter from "./components/RunsFilter.vue";
import RunsList from "./components/RunsList.vue";
import DataViz from "./components/DataViz.vue";

export default {
  name: "Dashboard",

  props: {
    batch_fields: Array,
    infos_fields: Array,

    isAdmin: {
      type: Boolean,
      required: true,
    },
    isArchive: Boolean
  },

  data: function () {
    return {
      filtered_runs: [],
      selected_runs: [],
      batches_infos: [],
      fetching_filter: false,
      reset_filters: false,
      is_authenticated: false,
    };
  },
  components: {
    RunsFilter,
    RunsList,
    DataViz,
  },
  methods: {
    resetFilters: function () {
      this.reset_filters = !this.reset_filters;
    },
    reloadRuns: function () {
      this.$refs.runsFilter.reloadRuns()
    },
    cancelRunsLoad() {
      this.$refs.runsFilter.cancelRunLoad()
    },
    async checkAuthenticated() {
      try {
        await axios
          .get("/api/v1/user/info")
        this.is_authenticated = true
      } catch (error) {
        this.is_authenticated = false
      }
    },
  },
  created: function () {
    this.checkAuthenticated();
  },
};
</script>