<template>
  <div class="d-inline-block">
    <b-button @click="show_metas = true" size="sm" class="p-0 ml-1" variant="primary"
      title="Show/edit meta-datas"><b-icon icon="tags"></b-icon></b-button>
    <b-modal v-model="show_metas" size="lg" hide-footer @hide="cancelFetching()" @show="
      add_item = false;
    edit_item = false;
    fetchMetas();">
      <template #modal-title>
        Run meta-datas ({{ run_infos.machine_name }}, {{ run_infos.ananke_id }})
        <small><help-view>You can see/edit here all the run meta datas.</help-view></small>
      </template>
      <b-overlay :show="fetching">
        <div v-if="!edit_item && !add_item">
          <b-button-toolbar>
            <b-button-group class="mx-1" size="sm">
              <b-button @click="fetchMetas()"><b-icon icon="arrow-clockwise" class="mr-1"></b-icon>Reload</b-button>
            </b-button-group>
            <b-button-group class="mx-1" size="sm">
              <b-button variant="success" @click="add_item = true"><b-icon icon="plus"
                  class="mr-1"></b-icon>Add</b-button>
            </b-button-group>
            <b-button-group class="mx-1" size="sm" v-if="selected_item != null && !['CREATED', 'MODIFIED'].includes(
      selected_item.key) && !selected_item.key.startsWith('HEALTH_MESSAGE_')">
              <b-button variant="primary" @click="edit_item = true"><b-icon icon="pen"
                  class="mr-1"></b-icon>Edit</b-button>
              <b-button variant="danger" @click="deleteMeta()"><b-icon icon="trash"
                  class="mr-1"></b-icon>Delete</b-button>
            </b-button-group>
          </b-button-toolbar>
          <b-table :items="run_metas" small responsive bordered striped hover selectable select-mode="single"
            @row-selected="onRowSelected" show-empty :fields="fields"></b-table>
        </div>
        <div v-else>
          <b-overlay :show="saving">
            <b-form-group label="Key" label-for="edit_single_key" label-cols-xl="3">
              <b-form-input type="text" id="edit_single_key" v-model="meta_key" list="avail_keys" autocomplete="off"
                :formatter="keyFormatter" @input="
      fetchMetaValues();
    meta_value = '';
    " :disabled="edit_item" size="sm" placeholder="Meta key"></b-form-input>
              <datalist type="text" id="avail_keys">
                <option v-for="key in avail_keys" :key="key">{{ key }}</option>
              </datalist>
            </b-form-group>
            <b-form-group label="Value" label-for="edit_single_value" label-cols-xl="3">
              <b-form-input type="text" id="edit_single_value" v-model="meta_value" list="avail_values"
                autocomplete="off" size="sm" placeholder="Meta value" :disabled="meta_key.length == 0"></b-form-input>
              <datalist type="text" id="avail_values">
                <option v-for="key in avail_values" :key="key">
                  {{ key }}
                </option>
              </datalist>
            </b-form-group>
            <b-row class="text-center">
              <b-col cols="12">
                <b-button @click="
      edit_item = false;
    add_item = false;
    " variant="danger" class="mx-1" size="sm"><b-icon icon="x" class="mr-1"></b-icon>Cancel</b-button>
                <b-button variant="primary" class="mx-1" :disabled="meta_key.length == 0 || meta_value.length == 0"
                  size="sm" @click="saveMeta()"><b-icon icon="check" class="mr-1"></b-icon>Save</b-button>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import HelpView from "../../HelpView.vue";
import { formatHealthMessage } from "../../../utils/message";
const CancelToken = axios.CancelToken;

export default {
  name: "RunTags",
  props: ["run_infos"],
  data: function () {
    return {
      source: CancelToken.source(),
      show_metas: false,
      error_fetching: false,
      fetching: false,
      run_metas: [],
      selected_item: null,
      edit_item: false,
      add_item: false,
      meta_key: "",
      meta_value: "",
      fetching_keys: false,
      avail_values: [],
      avail_keys: [],
      fetching_values: false,
      saving: false,
      fields: [{
        key: "key",
        label: "Key"
      }, {
        key: "value",
        label: "Value",
        formatter: value => { return formatHealthMessage(value) }
      }]
    };
  },
  watch: {
    add_item: function () {
      this.meta_key = "";
      this.meta_value = "";
      this.fetchMetaKeys();
      if (!this.add_item && !this.edit_item) {
        this.fetchMetas();
      }
    },
    edit_item: function () {
      this.meta_key = this.selected_item.key;
      this.meta_value = this.selected_item.value;
      this.fetchMetaValues();
      if (!this.add_item && !this.edit_item) {
        this.fetchMetas();
      }
    },
  },
  methods: {
    keyFormatter(value) {
      return value
        .toUpperCase()
        .replace(" ", "_")
        .replace(/[^A-Z0-9_]/, "");
    },
    cancelFetching() {
      this.source.cancel();
      this.source = CancelToken.source();
    },
    async fetchMetas() {
      this.selected_item = null;
      if (this.fetching) {
        this.cancelFetching();
      }
      this.fetching = true;
      this.error_fetching = false;
      try {
        this.run_metas = (await axios
          .get("/api/v1/meta/get", {
            params: { run_id: this.run_infos.id },
            cancelToken: this.source.token,
          })).data.map((ele) => {
            return { key: ele.key, value: ele.value };
          });
      }
      catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled to fetch run meta-datas");
        } else {
          this.error_fetching = true;
        }
      }
      this.fetching = false
    },
    async fetchMetaValues() {
      if (this.meta_key.length > 0) {
        this.avail_values = (await axios.get("/api/v1/meta/values", {
          params: { key: this.meta_key }
        })).data
      }
    },
    async fetchMetaKeys() {
      this.avail_keys = (await axios
        .get("/api/v1/meta/keys")).data.filter(
          ele => !this.run_metas.map(ele2 => ele2.key).includes(ele))
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.selected_item = items[0];
      } else {
        this.selected_item = null;
      }
    },
    async saveMeta() {
      this.saving = true;
      try {
        await axios
          .post("/api/v1/meta/add", [
            {
              run_id: this.run_infos.id,
              key: this.meta_key,
              value: this.meta_value,
            },
          ])
        this.saving = false
        this.edit_item = false
        this.add_item = false
      }
      catch (error) {
        this.saving = false
      }
    },
    async deleteMeta() {
      this.fetching = true;
      try {
        await axios
          .delete("/api/v1/meta/delete", {
            data: [{ run_id: this.run_infos.id, key: this.selected_item.key }],
          })
        this.fetchMetas()
        this.fetching = false
      }
      catch (error) {
        this.fetching = false
      }
    },
  },
  components: {
    HelpView,
  },
};
</script>

<style scoped>
.table-responsive {
  margin: 0 !important;
}

table {
  margin: 0 !important;
}

.btn-toolbar {
  margin-bottom: 8px;
}
</style>