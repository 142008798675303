<template>
  <div class="analysis-plot">
    <plot-config
      :xAxisOptions="multiDataProps"
      :yAxisOptions="multiDataProps"
      v-model="plotConfig"
    />
    <div class="plot-options">
      <transition name="fade">
        <b-form-checkbox
          class="stats-switch"
          v-if="stats"
          v-model="areStatsShown"
          switch
          >Show stats</b-form-checkbox
        >
      </transition>
      <transition name="fade">
        <b-button
          v-if="hasDeletedDots"
          size="sm"
          variant="primary"
          @click="restoreDots"
          >Restore dots</b-button
        >
      </transition>
    </div>
    <div class="border">
      <div ref="plot" style="width: 100%; height: 750px"></div>
    </div>
  </div>
</template>

<style scoped>
@import url("../../style/animation.css");

.stats-switch {
  font-weight: bold;
}

.plot-options {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-bottom: 0.5em;
}

.plot-options > :not(:first-child) {
  margin-left: 1em;
}
</style>

<script>
import Plotly from "plotly.js-dist";

import {
  commonPlotTypes,
  BOX_PLOT_TYPE,
  MARKED_RUNS_OPACITY,
} from "../../constants/plot";

import PlotConfig from "./PlotConfig";
import { computeStatsPlotData, removeSelection } from "../../utils/plot";
import { lastIndex, dedup, push } from "../../utils/array";
import { isDateString } from "../../utils/date";

function arePointsMatch(pointIdA, pointIdB) {
  return Object.keys(pointIdA).reduce(
    (match, key) => match && pointIdA[key] === pointIdB[key],
    true
  );
}

function stackedPointToPointId({
  pointIndex,
  data: {
    meta: { fluor, chamber, run_ids },
  },
}) {
  return { fluor, chamber, pointIndex, run_id: run_ids[pointIndex] };
}

function areStakedPoint(hitPoint, testPoint) {
  return (
    !arePointsMatch(
      stackedPointToPointId(testPoint),
      stackedPointToPointId(hitPoint)
    ) &&
    areNearlyEqual(testPoint.x, hitPoint.x) &&
    areNearlyEqual(testPoint.y, hitPoint.y)
  );
}

function areNearlyEqual(a, b) {
  const PRECISION = 0.05;

  const parsedValues = [a, b].map((value) => {
    if (isDateString(value)) {
      return new Date(value).getTime();
    }

    const floatValue = parseFloat(value);

    if (!Number.isNaN(floatValue)) {
      return floatValue;
    }

    return value;
  });

  const [typeOfA, typeOfB] = parsedValues.map((value) => typeof value);

  let Δ;

  if (typeOfA === typeOfB) {
    const [ƒa, ƒb] = parsedValues;

    if (typeOfA === "number") {
      Δ = ƒa - ƒb;
    } else {
      Δ = ƒa > ƒb || ƒa < ƒb ? PRECISION + 1 : 0;
    }
  } else {
    console.warn(
      `type mismatch between "${a}"(${parsedValues[0]}):${typeOfA} and "${b}"(${parsedValues[1]}):${typeOfB}`
    );
  }

  return Math.abs(Δ) <= PRECISION;
}

export default {
  name: "AnalysisPlot",

  props: {
    multi_data: {
      type: Array,
      required: true,
    },
  },

  components: { PlotConfig },

  data() {
    return {
      plotConfig: {
        plotType: "markers",
        xAxis: "start",
        yAxis: "cycle_threshold",
      },
      stats: undefined,
      areStatsShown: false,
      deletedDots: undefined,
    };
  },

  computed: {
    multiDataProps() {
      return Object.keys(this.multi_data[0]);
    },

    hasDeletedDots() {
      return this.deletedDots?.length > 0;
    },

    multiDataRunIds() {
      return this.multi_data.map((data) => data.run_id);
    },
  },

  watch: {
    multi_data() {
      this.markedPoints = this.markedPoints?.filter((markedPoint) =>
        this.multiDataRunIds.includes(markedPoint.run_id)
      );
      this.deletedDots = undefined;
      this.plot();
    },

    plotConfig() {
      this.deletedDots = undefined;
      this.plot();
    },

    areStatsShown(areStatsShown) {
      if (areStatsShown) {
        this.showStats();
      } else {
        this.hideStats();
      }
    },

    stats(stats) {
      if (stats && this.areStatsShown) {
        this.showStats();
      }
    },
  },

  methods: {
    async plot(data = this.preparePlotData()) {
      const config = { responsive: true, displaylogo: false };

      const layout = {
        yaxis: { automargin: true },
        dragmode: this.hasDeletedDots && "lasso",
      };

      const markedData = this.markedPoints
        ? data.map((datum) => ({
            ...datum,

            marker: {
              opacity: datum.meta.run_ids.map((run_id, pointIndex) => {
                const { fluor, chamber } = datum.meta;
                const isPointMarked = this.isPointMarked({
                  fluor,
                  chamber,
                  pointIndex,
                  run_id,
                });
                return isPointMarked ? MARKED_RUNS_OPACITY : 1;
              }),
            },
          }))
        : data;

      await Plotly.react(this.$refs.plot, markedData, layout, config);
      this.stats = computeStatsPlotData(data);
    },

    preparePlotData() {
      const vm = this;
      const distinct_fluors = dedup(vm.multi_data.map((ele) => ele.fluor));
      const distinct_chambers = dedup(vm.multi_data.map((ele) => ele.chamber));

      const type = commonPlotTypes.includes(vm.plotConfig.plotType)
        ? vm.plotConfig.plotType
        : "scatter";

      const mode = commonPlotTypes.includes(vm.plotConfig.plotType)
        ? ""
        : vm.plotConfig.plotType;
      const data = [];

      distinct_fluors.forEach((fluor) => {
        distinct_chambers.forEach((chamber) => {
          const filtered_data = vm.multi_data.filter(
            (ele) => ele.fluor === fluor && ele.chamber === chamber
          );

          data.push({
            x: filtered_data.map((ele) => ele[vm.plotConfig.xAxis]),
            y: filtered_data.map((ele) => ele[vm.plotConfig.yAxis]),

            text: filtered_data.map(
              (ele) => ele.machine_name + ", " + ele.ananke_id
            ),

            name: "Fluorophore " + fluor + ", " + chamber,
            mode: mode,
            type: type,

            ...(vm.plotConfig.plotType === BOX_PLOT_TYPE && {
              boxpoints: vm.plotConfig.boxPoints && "all",
              notched: vm.plotConfig.boxNotches,
            }),

            meta: {
              run_ids: filtered_data.map((datum) => datum.run_id),
              fluor,
              chamber,
            },
          });
        });
      });

      return data;
    },

    async showStats() {
      const {
        $refs: { plot },
      } = this;

      await Plotly.addTraces(plot, this.stats);

      Plotly.relayout(plot, {
        title: {
          text: `%{data[${lastIndex(plot.data)}].meta.extraStats}`,
          yanchor: "top",
          font: { size: 12 },
        },
      });
    },

    async hideStats() {
      const {
        $refs: { plot },
      } = this;

      await Plotly.deleteTraces(plot, lastIndex(plot.data));
      Plotly.relayout(plot, { title: null });
    },

    async removeSelection({ key }) {
      const {
        $refs: { plot },
      } = this;

      const hasSelection = plot.data?.some(
        (datum) => datum.selectedpoints?.length > 0
      );

      if (["Delete", "Backspace"].includes(key) && hasSelection) {
        const { filteredOutDots, filteredData } = removeSelection(plot.data);
        this.deletedDots = filteredOutDots;
        this.plot(filteredData);
      }
    },

    restoreDots() {
      this.deletedDots = undefined;
      this.plot();
    },

    async notifyGraphSelection({ points: [hitPoint] }) {
      const stakedPoints = [hitPoint, ...this.getStackedPoints(hitPoint)];
      const stackedPointsIds = stakedPoints.map(stackedPointToPointId);
      const [hitPointId] = stackedPointsIds;
      const isPointMarked = this.isPointMarked(hitPointId);

      if (isPointMarked) {
        this.markedPoints = this.markedPoints.filter((markedPoint) =>
          stackedPointsIds.every(
            (pointId) => !arePointsMatch(markedPoint, pointId)
          )
        );
      } else {
        this.markedPoints = this.markedPoints
          ? push(this.markedPoints, ...stackedPointsIds)
          : stackedPointsIds;
      }

      const stylesByCurve = stakedPoints.reduce(
        (
          styles,
          {
            curveNumber,
            pointIndex,
            data: {
              meta: { run_ids },
            },
          }
        ) => {
          const style = (styles[curveNumber] ??= { opacity: [] });

          run_ids.forEach((run_id, run_index) => {
            if (run_index === pointIndex) {
              style.opacity[run_index] = isPointMarked
                ? 1
                : MARKED_RUNS_OPACITY;
            } else {
              style.opacity[run_index] =
                this.$refs.plot.data[curveNumber].marker?.opacity?.[
                  run_index
                ] || 1;
            }
          });

          return styles;
        },
        {}
      );

      const curves = Object.keys(stylesByCurve);

      await Plotly.restyle(
        this.$refs.plot,
        { marker: curves.map((curve) => stylesByCurve[curve]) },
        curves
      );

      this.$emit("select-graph", {
        run_ids: this.markedPoints.map((markedPoint) => markedPoint.run_id),
      });
    },

    isPointMarked(pointId) {
      return this.markedPoints?.some((markedPoint) =>
        arePointsMatch(markedPoint, pointId)
      );
    },

    getStackedPoints(hitPoint) {
      return this.$refs.plot.data.reduce(
        (points, { x: xValues, y: yValues, meta }, curveNumber) =>
          push(
            points,

            ...xValues.reduce((stakedPoints, x, pointIndex) => {
              const testPoint = {
                x,
                y: yValues[pointIndex],
                data: { meta },
                pointIndex,
                curveNumber,
              };
              return areStakedPoint(hitPoint, testPoint)
                ? push(stakedPoints, testPoint)
                : stakedPoints;
            }, [])
          ),
        []
      );
    },
  },

  async mounted() {
    await this.plot();
    this.$refs.plot.on("plotly_click", this.notifyGraphSelection);
    document.addEventListener("keyup", this.removeSelection);
  },

  destroyed() {
    document.removeEventListener("keyup", this.removeSelection);
  },
};
</script>
